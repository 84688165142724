import { Form, FormListFieldData } from 'antd';

import MultipleDatePicker from 'app/components/pages/Collections/CollectionDetail/CollectionSections/MultipleDatePicker/MultipleDatePicker';
import { HeaderWrapper } from 'app/components/pages/Edito/commons/Detail/HeaderWrapper/HeaderWrapper';
import { requiredRule } from 'app/utils/rules';

type Props = {
  field: FormListFieldData;
};

export const DatesForm = ({ field }: Props) => {
  const { name } = field;

  return (
    <HeaderWrapper isBold={false} title="Dates">
      <Form.Item name={[name, 'searchFilters', 'dates']} rules={[requiredRule]}>
        <MultipleDatePicker />
      </Form.Item>
    </HeaderWrapper>
  );
};
