import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { apiRequestWithResponse } from 'app/utils/request/api';

export type PdfFile = {
  pdf: Blob;
  filename: string;
};

const path = 'admin/bills/:billId/pdf' as const;

export function useDowloadBill(billId?: number) {
  return useQuery({
    queryKey: [path, billId],
    queryFn: !!billId
      ? async () => {
          const response = await apiRequestWithResponse({
            path,
            params: { billId: billId.toString() },
          }).catch((err) => message.error('Error fetching bill pdf' + err));
          const headers = response.headers
            .get('Content-Disposition')
            .split('; ');
          const filename =
            headers.length === 3
              ? headers[2].split("filename*=utf-8''")[1]
              : headers[1].split('filename=')[1].replaceAll('"', '');

          const blobResponse = await response.blob();

          return {
            pdf: blobResponse,
            filename: decodeURIComponent(filename),
          };
        }
      : () => ({} as PdfFile),
  });
}
