import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { HotelServiceType } from 'app/redux/models/HotelServiceType/HotelServiceType';
import { apiRequest } from 'app/utils/request/api';

const path = '/hotels/services/types' as const;

export function useHotelServicesTypes() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<HotelServiceType[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
