import {
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
  StarFilled,
} from '@ant-design/icons';
import { Button, DatePicker, Input, Layout, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useAmplitudeViewPage } from 'app/hooks/useAmplitude/useAmplitude';
import { tryFetchFeedbacks, tryFetchMarks } from 'app/redux/actions/hotelAdmin';

import 'styles/pages/Extranet/extranet-table.scss';
import 'styles/pages/Extranet/hotel-marks.scss';

import { ExtranetHeader } from '../commons/ExtranetHeader/ExtranetHeader';

const { Content } = Layout;
const { Column } = Table;

declare type SortOrder = 'descend' | 'ascend' | null;

const { RangePicker } = DatePicker;

const PAGE = 'Reviews';

const ExtranetFeedbacks = ({
  hotelId,
  feedbacks,
  fetchFeedbacks,
  hotelMarks,
  fetchMarks,
  isLoading,
}: any) => {
  const { setPage } = useContext(TaskCenterContext);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [dateSorting, setDateSorting] = useState<SortOrder>();
  const [globalSorting, setGlobalSorting] = useState<SortOrder>();

  setPage(PAGE);

  const formatSort = useCallback((sort: any) => {
    if (sort === 'ascend') {
      return 'ASC';
    }

    if (sort === 'descend') {
      return 'DESC';
    }

    return '';
  }, []);
  const formatSorting = useCallback(() => {
    if (dateSorting) {
      return `dateSorting-${formatSort(dateSorting)}`;
    }

    if (globalSorting) {
      return `globalScoreSorting-${formatSort(globalSorting)}`;
    }

    return null;
  }, [formatSort, dateSorting, globalSorting]);
  const makePayload = useCallback(
    () => ({
      hotelId,
      offset: (pageNumber - 1) * 50,
      search: search || undefined,
      sorting: formatSorting() || undefined,
      beginning: startDate,
      ending: endDate,
    }),
    [hotelId, pageNumber, search, formatSorting, startDate, endDate]
  );

  useEffect(() => {
    if (hotelId !== undefined) {
      fetchFeedbacks(makePayload());
    }
  }, [fetchFeedbacks, makePayload, hotelId, pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [
    setPageNumber,
    hotelId,
    search,
    dateSorting,
    globalSorting,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (hotelId) {
      fetchMarks(hotelId);
    }
  }, [fetchMarks, hotelId]);

  useAmplitudeViewPage({ page: 'reviews' });

  const handleFilterDate = (interval: any) => {
    if (!interval || !interval[0] || !interval[1]) {
      setStartDate(undefined);
      setEndDate(undefined);
    } else {
      setStartDate(interval[0].format('YYYY-MM-DD'));
      setEndDate(interval[1].format('YYYY-MM-DD'));
    }
  };

  const dateSortingHandler = (sort: any) => {
    setDateSorting(sort);
    setGlobalSorting(undefined);
  };

  const globalSortingHandler = (sort: any) => {
    setDateSorting(undefined);
    setGlobalSorting(sort);
  };

  const pagination = (className: any) => (
    <div className={className}>
      <Button
        disabled={pageNumber === 1}
        onClick={() => setPageNumber(pageNumber - 1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{pageNumber}</Button>
      <Button
        disabled={feedbacks.length < 50}
        onClick={() => setPageNumber(pageNumber + 1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
        <RangePicker
          onChange={handleFilterDate}
          format="ddd DD MMM"
          ranges={{
            Today: [moment(), moment()],
            'This Week': [
              moment().startOf('isoWeek'),
              moment().endOf('isoWeek'),
            ],
          }}
          className="range-picker"
          size="large"
        />
      </div>
      {pagination('button-wrapper')}
    </div>
  );

  const formatDate = (date: any) => moment(date).format('DD MMM YYYY');

  const grayCell = (title: any) => (
    <div style={{ color: '#8C8C8C', textAlign: 'center' }}>{title}</div>
  );

  const renderHotelMarks = ([averageRating, reviewsNumber]: any) =>
    averageRating && reviewsNumber ? (
      <span className="hotel-marks">
        <span className="star">
          <StarFilled />
        </span>
        <span className="average">{averageRating.toFixed(1)}</span>
        <span className="counter">
          ({reviewsNumber > 200 ? '200+' : reviewsNumber})
        </span>
      </span>
    ) : null;

  return (
    <Layout className="extranet-table">
      <ExtranetHeader
        page={PAGE}
        subTitle={hotelMarks && renderHotelMarks(hotelMarks)}
      />
      <Content className="body">
        <Table
          dataSource={feedbacks}
          pagination={false}
          title={header}
          loading={isLoading}
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { field, order } = sorter;

            if (field === 'date') {
              dateSortingHandler(order);
            }

            if (field === 'globalScore') {
              globalSortingHandler(order);
            }
          }}
        >
          <Column
            title="Guest"
            render={({ firstName, lastName }) => (
              <div>{`${firstName} ${lastName}`}</div>
            )}
          />
          <Column
            title="Published"
            dataIndex="date"
            render={formatDate}
            sorter
            sortOrder={dateSorting}
            width={130}
          />
          <Column
            title="Global"
            dataIndex="globalScore"
            render={(score) => score.toFixed(1)}
            sorter
            sortOrder={globalSorting}
          />
          <Column
            title={grayCell('Experience')}
            dataIndex="experienceScore"
            render={grayCell}
          />
          <Column
            title={grayCell('Service')}
            dataIndex="serviceScore"
            render={grayCell}
          />
          <Column
            title={grayCell('Vibe')}
            dataIndex="vibeScore"
            render={grayCell}
          />
          <Column
            title={grayCell('Room')}
            dataIndex="roomScore"
            render={grayCell}
          />
          <Column
            title={grayCell('Breakfast')}
            dataIndex="breakfastScore"
            render={grayCell}
          />
          <Column
            title="Comment"
            dataIndex="comment"
            render={(comment) => (
              <div style={{ lineHeight: '18px' }}>{comment}</div>
            )}
          />
        </Table>
        {pagination('footer')}
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  hotelId: state.hotelAdmin.hotelId,
  feedbacks: state.hotelAdmin.feedbacks || [],
  hotelMarks: state.hotelAdmin.hotelMarks,
  isLoading: state.hotelAdmin.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchFeedbacks: _.debounce(
    ({ hotelId, offset, search, sorting, beginning, ending }) =>
      dispatch(
        tryFetchFeedbacks({
          hotelId,
          offset,
          search,
          sorting,
          beginning,
          ending,
        })
      ),
    800
  ),
  fetchMarks: (hotelId: any) => dispatch(tryFetchMarks({ hotelId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtranetFeedbacks);
