import { SearchOutlined } from '@ant-design/icons';
import { Input, Layout, Table, TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import Column from 'antd/lib/table/Column';
import moment from 'moment';
import type { RangeValue } from 'rc-picker/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { RangePicker } from 'app/components/fields/DatePicker';
import ListPage from 'app/components/lists/ListPage';
import Pagination from 'app/components/lists/Pagination';
import {
  StatusCell,
  dateTimeCell,
  dateTimeCell2,
} from 'app/components/lists/cells';
import { PAGE_LIMIT } from 'app/components/lists/constants';
import ModalCheckBox from 'app/components/pages/Extranet/ExtranetBookings/ModalCheckBox';
import { BookingsQueryParams, useBookings } from 'app/hooks/data/useBookings';
import { PSP } from 'app/typings/PSP';
import {
  BookingListItem,
  BookingStatus,
} from 'app/typings/adminBookings/bookings';
import { scrollToTop } from 'app/utils/scroll';
import { SortOrder, formatSortingForAPI } from 'app/utils/sort';
import { toSnakeCase } from 'app/utils/strings';

import 'styles/lists/left-right-header.scss';
import 'styles/pages/Booking/booking.scss';

export type BookingsListQueryParam = {
  search: string;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  pageNumber: number;
  sortOrder?: SortOrder;
  sortField?: string;
  psp: PSP[];
  status: BookingStatus[];
};

export function isValidStatus(
  maybeStatus: string
): maybeStatus is BookingStatus {
  return Object.values(BookingStatus).some((status) => status === maybeStatus);
}

export function isPSP(maybePSP: string): maybePSP is PSP {
  return Object.values(PSP).some((psp) => psp === maybePSP);
}

const statusCheckBoxItems = [
  { value: BookingStatus.Accepted, label: 'Accepted' },
  { value: BookingStatus.Cancelled, label: 'Cancelled' },
  { value: BookingStatus.Pending, label: 'Pending' },
  { value: BookingStatus.Expired, label: 'Expired' },
  { value: BookingStatus.Aborted, label: 'Aborted' },
  { value: BookingStatus.Rejected, label: 'Rejected' },
] satisfies { value: BookingStatus; label: string }[];

const LIMIT = 50;

const pspCheckBoxItems = [
  {
    value: PSP.Ayden,
    label: 'Adyen',
  },
  {
    value: PSP.Stripe,
    label: 'Stripe',
  },
] satisfies { value: PSP; label: string }[];

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: SortOrder;
  filters?: Record<string, FilterValue | null>;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  status?: BookingStatus[];
  psp?: PSP[];
  offset: number;
}

const makePayload = (
  qs: TableParams,
  search: string,
  formattedSort?: string
): BookingsQueryParams => {
  const offset: number = ((qs.pagination?.current || 1) - 1) * PAGE_LIMIT;

  return {
    search,
    sorting: formattedSort,
    beginning: qs.startDate?.format('YYYY-MM-DD') || undefined,
    ending: qs.endDate?.format('YYYY-MM-DD') || undefined,
    offset: offset,
    status: qs.status,
    psp: qs.psp,
  };
};

const convertPsp = (pspToConvert: string) =>
  pspToConvert.charAt(0) + pspToConvert.slice(1).toLowerCase();

const BookingsList = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams.get('search') ?? ''
  );
  const [tableDataSize, setTableDataSize] = useState<number>(0);
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({
    status: [
      BookingStatus.Cancelled,
      BookingStatus.Accepted,
      BookingStatus.Pending,
      BookingStatus.Aborted,
      BookingStatus.Expired,
      BookingStatus.Rejected,
    ],
    psp: [PSP.Ayden, PSP.Stripe],
  });

  const getInitialTableParams = () => {
    setFilteredInfo(
      searchParams.get('filters')
        ? JSON.parse(searchParams.get('filters') as string)
        : { status: filteredInfo.status, psp: filteredInfo.psp }
    );

    return {
      pagination: {
        current: searchParams.get('page')
          ? parseInt(searchParams.get('page') as string, 10)
          : 1,
        total: tableDataSize,
        pageSize: LIMIT,
        showQuickJumper: false,
        showSizeChanger: false,
        showLessItems: true,
      },
      sortField: searchParams.get('sortField') || undefined,
      sortOrder: (searchParams.get('sortOrder') as SortOrder) || undefined,
      filters: searchParams.get('filters')
        ? JSON.parse(searchParams.get('filters') as string)
        : { status: filteredInfo.status, psp: filteredInfo.psp },
      offset: 0,
      startDate: moment().subtract(1, 'month'),
      endDate: moment(),
      psp: [PSP.Ayden, PSP.Stripe],
      status: [
        BookingStatus.Cancelled,
        BookingStatus.Accepted,
        BookingStatus.Pending,
        BookingStatus.Aborted,
        BookingStatus.Expired,
        BookingStatus.Rejected,
      ],
    };
  };

  const [tableParams, setTableParams] = useState<TableParams>(
    getInitialTableParams
  );

  const formatSorting = useCallback(() => {
    console.log(tableParams);

    if (!tableParams.sortOrder || !tableParams.sortField) return undefined;

    return `${toSnakeCase(tableParams.sortField)}:${formatSortingForAPI(
      tableParams.sortOrder
    )}`;
  }, [tableParams]);

  const { data: bookings, isLoading: loading } = useBookings(
    makePayload(tableParams, searchTerm, formatSorting())
  );

  useEffect(() => {
    if (bookings && !loading) {
      setTableDataSize(bookings.length);
    }
  }, [bookings, loading]);

  useEffect(() => {
    const offset: number =
      ((tableParams.pagination?.current || 1) - 1) * PAGE_LIMIT;

    const params = new URLSearchParams({
      search: searchTerm || '',
      beginning: tableParams.startDate?.format('YYYY-MM-DD') || '',
      ending: tableParams.endDate?.format('YYYY-MM-DD') || '',
      page: tableParams.pagination?.current?.toString() || '1',
      sortField: tableParams.sortField || '',
      sortOrder: tableParams.sortOrder || '',
      filters: JSON.stringify(tableParams.filters) || '{}',
      offset: JSON.stringify(offset),
    });

    history.replace({
      search: params.toString(),
    });
  }, [tableParams, searchTerm, history]);

  const handleOffset = (x: number) => {
    const calcPagination = (oldValue: TableParams) =>
      (oldValue.pagination?.current || 0) + x;

    setTableParams((oldValue) => ({
      ...oldValue,
      pagination: {
        ...oldValue.pagination,
        current: calcPagination(oldValue),
      },
    }));

    scrollToTop();
  };

  const handleStatus = (nextStatus: BookingStatus[]) => {
    setTableParams((oldValue) => ({
      ...oldValue,
      status: nextStatus,
      pagination: {
        ...oldValue.pagination,
        current: 1,
      },
    }));
  };

  const handlePSP = (nextPSP: PSP[]) => {
    setTableParams((oldValue) => ({
      ...oldValue,
      psp: nextPSP,
      pagination: {
        ...oldValue.pagination,
        current: 1,
      },
    }));
  };

  const handleFilterDate = (interval: RangeValue<moment.Moment> | null) => {
    if (interval === null || interval[0] === null || interval[1] === null) {
      setTableParams((oldValue) => ({
        ...oldValue,
        startDate: undefined,
        endDate: undefined,
        pagination: {
          ...oldValue.pagination,
          current: 1,
        },
      }));
    } else {
      const startDate: moment.Moment = interval[0];
      const endDate: moment.Moment = interval[1];

      setTableParams((oldValue) => ({
        ...oldValue,
        startDate,
        endDate,
        pagination: {
          ...oldValue.pagination,
          current: 1,
        },
      }));
    }
  };

  const handleRowActions = ({ code }: BookingListItem) => ({
    onClick: () => history.push(`bookings/${code}`),
  });

  const header = () => (
    <div className="left-right-header">
      <div className="left-right-header__left">
        <Input
          placeholder="Search here..."
          suffix={<SearchOutlined className="certain-category-icon" />}
          className="left-right-header__search"
          size="large"
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            setTableDataSize(bookings?.length ?? 0);
          }}
        />
        <RangePicker
          size="large"
          value={[tableParams.startDate || null, tableParams.endDate || null]}
          onChange={handleFilterDate}
          format="ddd DD MMM"
          ranges={{
            Today: [moment(), moment()],
            'This Week': [
              moment().startOf('isoWeek'),
              moment().endOf('isoWeek'),
            ],
            'Last month': [moment().subtract(1, 'month'), moment()],
            'Last 3 months': [moment().subtract(3, 'months'), moment()],
            'Last year': [moment().subtract(1, 'year'), moment()],
          }}
        />
      </div>
      <Pagination
        className="left-right-header__right"
        pageNumber={tableParams.pagination?.current || 1}
        pageResultsLength={bookings?.length || 0}
        handleOffset={handleOffset}
      />
    </div>
  );

  return (
    <ListPage title="Bookings">
      <Layout className="booking">
        <Table
          title={header}
          loading={loading}
          onRow={handleRowActions}
          dataSource={bookings}
          pagination={false}
          rowKey="code"
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { field, order } = sorter;

            setTableParams((oldValue) => ({
              ...oldValue,
              sortOrder: order,
              sortField: typeof field !== 'string' ? field[1] : field,
            }));
          }}
        >
          <Column
            key="code"
            title="Code"
            dataIndex="code"
            render={(elem) => <div style={{ fontWeight: 500 }}>{elem}</div>}
          />

          <Column
            title="Created at"
            sorter
            dataIndex="createdAt"
            render={dateTimeCell2}
          />

          <Column
            key="firstName"
            title="First name"
            sorter
            dataIndex={['customer', 'firstName']}
          />

          <Column
            key="lastName"
            title="Last name"
            sorter
            dataIndex={['customer', 'lastName']}
          />

          <Column
            key="checkin"
            title="Check in"
            render={dateTimeCell('dd/MM/yyyy')}
            dataIndex="checkin"
          />

          <Column key="hotelName" title="Hotel" dataIndex="hotelName" />

          <Column
            key="status"
            className="sort-column"
            title={
              <ModalCheckBox
                title="Status"
                value={tableParams?.status || []}
                onChange={handleStatus}
                items={statusCheckBoxItems}
              />
            }
            dataIndex="status"
            render={StatusCell}
          />

          <Column
            key="payment"
            className="sort-column"
            title={
              <ModalCheckBox
                title="Payment"
                onChange={handlePSP}
                items={pspCheckBoxItems}
                value={tableParams?.psp || []}
              />
            }
            dataIndex="psp"
            render={convertPsp}
          />
        </Table>

        <Pagination
          className="booking__footer"
          pageNumber={tableParams.pagination?.current || 1}
          pageResultsLength={bookings?.length || 0}
          handleOffset={handleOffset}
        />
      </Layout>
    </ListPage>
  );
};

export default BookingsList;
