import { PAX } from 'app/typings/PAX';

type Customer = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export type ExtranetBooking = {
  customer: Customer;
  checkin: string;
  checkout: string;
  code: string;
  pax: PAX;
  roomCategory: string;
  status: ExtranetBookingStatus;
  totalAmount: number;
  dayPackage: boolean;
};

export enum ExtranetBookingStatus {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
}

export type ExtranetBookingWithNbNight = ExtranetBooking & { nbNight: number };
