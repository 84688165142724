import { Form } from 'antd';
import { FC } from 'react';

import {
  HotelServiceForm,
  IHotelsServicesType,
  Mode,
} from 'app/components/commons/Hotel/Types';
import TimeRangeSection from 'app/components/commons/TimeRangeSection/TimeRangeSection';
import { parseOpeningHoursFromAPI } from 'app/components/commons/TimeRangeSection/utils';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';

const OpeningHours: FC<Props> = ({
  service,
  hotelsServicesType,
  mode,
  form,
}) => {
  if (!hotelsServicesType.openingHoursRequired) {
    return null;
  }

  return (
    <div className="service-block">
      <FormLegacyRenderProp
        form={form}
        initialValues={{
          services: [
            {
              ...service,
              openingHours: parseOpeningHoursFromAPI(service.openingHours),
            },
          ],
        }}
      >
        {(values, { setFieldsValue, validateFields }) => (
          <Form.List name="services">
            {(fields) => (
              <div className="opening-hours">
                {fields.map((field, fieldIndex) => (
                  <TimeRangeSection
                    key={field.key}
                    mode={mode}
                    openingHours={values.services?.[fieldIndex]?.openingHours}
                    field={field}
                    setFieldsValue={(openingHours) => {
                      setFieldsValue({
                        ...values,
                        services: values.services?.map(
                          (formServ: any, servIndex: any) => {
                            if (servIndex === fieldIndex) {
                              return {
                                ...formServ,
                                openingHours,
                              };
                            }

                            return formServ;
                          }
                        ),
                      });
                    }}
                    previousPrefixes={['services']}
                    validateFields={validateFields}
                  />
                ))}
              </div>
            )}
          </Form.List>
        )}
      </FormLegacyRenderProp>
    </div>
  );
};

type Props = {
  service: HotelServiceForm;
  hotelsServicesType: IHotelsServicesType;
  mode?: Mode;
  form: any;
};

export default OpeningHours;
