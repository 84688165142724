import { message } from 'antd';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as hotelsActions from 'app/redux/actions/hotels';
import { createHotel } from 'app/redux/models/Hotel/Hotel';
import {
  HotelItem,
  createHotelItem,
} from 'app/redux/models/HotelItem/HotelItem';
import { get, post, requestGenerator, put as rput } from 'app/redux/requests';
import { ChannelManager } from 'app/typings/channelManagers';

export function* tryFetchHotel(
  action: ReturnType<typeof hotelsActions.tryFetchHotel>
) {
  const { hotelId } = action.payload;

  yield requestGenerator(
    () => put(hotelsActions.startLoading()),
    () => get(`hotels/${hotelId}`),
    (result: any) => {
      const hotel = createHotel(result);

      return put(hotelsActions.fetchHotelSuccess({ hotel }));
    },
    () => {
      message.error('Error fetching Hotel');

      return put(hotelsActions.stopLoading());
    }
  );
}

export function* tryFetchHotels() {
  yield requestGenerator(
    () => put(hotelsActions.startLoading()),
    () => get('hotels'),
    (result: Array<any>) => {
      const hotels = result.map<HotelItem>((r) => createHotelItem(r));

      return put(hotelsActions.fetchHotelsSuccess({ hotels }));
    },
    () => {
      message.error('Error fetching Hotels');

      return put(hotelsActions.stopLoading());
    }
  );
}

export function* trySaveHotel(
  action: ReturnType<typeof hotelsActions.trySaveHotel>
) {
  const { hotel } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () =>
      post('hotels', {
        ...hotel,
        channelManager:
          hotel.channelManager === ChannelManager.NONE
            ? undefined
            : hotel.channelManager,
      }),
    (result: any) => {
      message.success('Hotel successfully saved!');

      const updatedHotel = createHotel(result);

      return put(hotelsActions.fetchHotelSuccess({ hotel: updatedHotel }));
    },
    () => message.error('Error saving Hotel'),
    { expectJson: true }
  );
}

export function* trySaveSpaces(
  action: ReturnType<typeof hotelsActions.trySaveSpaces>
) {
  const { hotelId, spaces } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => rput(`hotels/${hotelId}/spaces`, spaces),
    () =>
      all([
        put(hotelsActions.saveSpacesSuccess()),
        put(hotelsActions.tryFetchHotel({ hotelId })),
      ]),
    () => message.error('Error posting Spaces'),
    { expectJson: false }
  );
}

export function* trySaveServices(
  action: ReturnType<typeof hotelsActions.trySaveServices>
) {
  const { hotelId, services } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => rput(`hotels/${hotelId}/services`, services),
    () => put(hotelsActions.tryFetchHotel({ hotelId })),
    () => message.error('Error updating Hotel Services'),
    { expectJson: false }
  );
}

export default function* hotelsSaga() {
  yield all([
    takeEvery(hotelsActions.HOTELS_TRY_FETCH_HOTELS, tryFetchHotels),
    takeEvery(hotelsActions.HOTELS_TRY_FETCH_HOTEL, tryFetchHotel),
    takeEvery(hotelsActions.HOTELS_TRY_SAVE_HOTEL, trySaveHotel),
    takeEvery(hotelsActions.HOTELS_TRY_SAVE_SPACES, trySaveSpaces),
    takeEvery(hotelsActions.HOTELS_TRY_SAVE_SERVICES, trySaveServices),
  ]);
}
