import { Form } from 'antd';
import { FC } from 'react';

import { SpaceValueType } from 'app/components/commons/Hotel/Types';
import Pictures from 'app/components/commons/Package/Pictures/Pictures';
import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';

import './InformativePhoto.scss';

const RULES = [{ required: true, message: 'Required' }];
const DEFAULT_PICTURES_CATEGORIES = [
  { name: 'Official', type: 'official' },
  { name: 'Sourcing', type: 'sourcing' },
];
const DEFAULT_KIND = 'sourcing';

export const InformativePhoto: FC<Props> = ({
  mode = 'edit',
  values,
  hotelId,
  picturesCategories = DEFAULT_PICTURES_CATEGORIES,
  kind = DEFAULT_KIND,
}) => (
  <div className="informative-photo">
    <div
      className={`input-row input-row-medium-input ${
        mode === 'view' ? 'input-row-no-rules' : ''
      }`}
    >
      <Form.Item name="informativePhoto" label="Hotel facade" rules={RULES}>
        {mode === 'edit' || !values.informativePhoto ? (
          <Pictures
            sectionTitle="Hotel facade"
            hotelId={hotelId}
            picturesCategories={picturesCategories}
            kind={kind}
            editable
            removable
            modalSubtitle="Select or upload a picture to illustrate the hotel facade"
            maxPictures={1}
          />
        ) : (
          <UploadList
            pictures={values.informativePhoto}
            uploadingItems={{}}
            previewable
          />
        )}
      </Form.Item>
    </div>
  </div>
);

type Props = {
  mode?: string;
  values: SpaceValueType;
  hotelId: number;
  picturesCategories?: Array<{ name: string; type: string }>;
  kind?: string;
};

export default InformativePhoto;
