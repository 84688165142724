import { Form, FormListFieldData, Select } from 'antd';
import { useMemo } from 'react';

import { HeaderWrapper } from 'app/components/pages/Edito/commons/Detail/HeaderWrapper/HeaderWrapper';
import { Tag } from 'app/redux/models/Tag/Tag';
import { FilterCategory, TagCriteria } from 'app/typings/edito';
import { filterLabelOption } from 'app/utils/form';
import { requiredRule } from 'app/utils/rules';
import { capitalizeFirstLetter } from 'app/utils/string';

type Props = {
  field: FormListFieldData;
  criteria: TagCriteria;
  tags: Tag[];
};

const filterCategoryByCriteria: {
  [criteria in TagCriteria]: FilterCategory[];
} = {
  experience: ['foodAndDrinks', 'experiences'],
  room: ['room'],
  situation: ['location'],
  style: ['style'],
};

export const TagForm = ({ field, criteria, tags }: Props) => {
  const { name } = field;

  const options = useMemo(
    () =>
      tags
        .filter(
          (tag) =>
            tag.filterCategory &&
            filterCategoryByCriteria[criteria].includes(
              tag.filterCategory as FilterCategory
            )
        )
        .map((tag) => ({
          label: tag.name,
          value: tag.id,
        })),
    [criteria, tags]
  );

  return (
    <HeaderWrapper
      isBold={false}
      title={`${capitalizeFirstLetter(criteria.toString())} filter`}
    >
      <Form.Item
        name={[name, 'searchFilters', criteria.toString()]}
        rules={[requiredRule]}
      >
        <Select
          options={options}
          mode="multiple"
          allowClear
          filterOption={filterLabelOption}
          placeholder={`Search a filter for ${criteria}`}
        />
      </Form.Item>
    </HeaderWrapper>
  );
};
