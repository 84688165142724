import { Button, Popconfirm } from 'antd';

import './Delete.scss';

export const Delete = ({ entityDescription, onDelete }: Props) => (
  <div className="extra-btn">
    <Popconfirm
      title={`Are you sure you want to delete ${entityDescription}? (This action is irrevocable)`}
      placement="bottomRight"
      onConfirm={onDelete}
      okText="Yes"
      cancelText="No"
    >
      <Button size="large">Delete</Button>
    </Popconfirm>
  </div>
);

type Props = {
  entityDescription: string;
  onDelete: () => void;
};

export default Delete;
