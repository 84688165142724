import {
  autoCompleteService,
  geocoderService,
  placesService,
} from './services';
import {
  AutocompletePrediction,
  AutocompletionRequest,
  FormattedPlaceProperties,
  GeocoderAddressComponent,
  PlaceDetailsRequest,
  PlaceResult,
  PlaceSearchRequest,
} from './typings';

const MAX_TYPES = 5;

export const fetchPlacePredictions = (
  payload: AutocompletionRequest,
  callback?: (predictions: Array<AutocompletePrediction>) => void
) => {
  if (payload.types.length > MAX_TYPES) {
    throw new Error(
      `Cannot fetch place predictions with above ${MAX_TYPES} types`
    );
  }

  autoCompleteService.getPlacePredictions(payload, callback);
};

export const placeFromCoords = async (coords: { lng: number; lat: number }) => {
  const places = await geocoderService.geocode({ location: coords });

  return places.results.find((placeResult) =>
    placeResult.types.includes('locality')
  );
};

export const getPlaceDetails = (
  request: PlaceDetailsRequest,
  callback?: (placeResult: PlaceResult) => void
) => {
  placesService.getDetails(request, callback);
};

export const nearbySearch = (
  request: PlaceSearchRequest,
  callback?: (placeResult?: Array<PlaceResult>) => void
) => {
  placesService.nearbySearch(request, callback);
};

export function formatGooglePlacePayload(
  place: GeocoderAddressComponent[]
): FormattedPlaceProperties {
  return place.reduce((previousProperties, addressEntry) => {
    const [firstTypeEntry] = addressEntry.types;

    switch (firstTypeEntry) {
      case 'street_number':
        return { ...previousProperties, streetNumber: addressEntry.short_name };
      case 'route':
        return { ...previousProperties, street: addressEntry.short_name };
      case 'locality':
        return { ...previousProperties, city: addressEntry.short_name };
      case 'administrative_area_level_2':
        return { ...previousProperties, department: addressEntry.short_name };
      case 'administrative_area_level_1':
        return { ...previousProperties, region: addressEntry.short_name };
      case 'postal_code':
        return { ...previousProperties, zipCode: addressEntry.short_name };
      default:
        return previousProperties;
    }
  }, {});
}
