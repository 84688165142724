import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { SpaceType } from 'app/redux/models/SpaceType/SpaceType';
import { apiRequest } from 'app/utils/request/api';

const path = 'hotels/spaces/types' as const;

export function useHotelSpacesTypes() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<SpaceType[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
