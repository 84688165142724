export const GUEST_BED_BED_CATEGORY_ID = 2;
export const BABY_COT_BED_CATEGORY_ID = 3;
export const BED_TASK_CATEGORIES = [
  BABY_COT_BED_CATEGORY_ID,
  GUEST_BED_BED_CATEGORY_ID,
];
export const DAY_PACKAGE_ROOM_CATEGORY_ID = 50;

export type Room = {
  hotelId: number;
  categoryId: number;
  categoryName: string;
  name: string;
  hasPRM: boolean;
  area: number;
  beds: Array<Bed>;
  featureIds: Array<number>;
  pictures: Array<Picture>;
  id: number;
  bookingcomId?: number;
  bookingcomName?: string;
  categoryRoomCount?: number;
};

export type Bed = {
  categoryId?: number;
  included?: boolean;
  price?: number;
  childPrice?: number;
  id?: number;
  priority?: number;
};

export type Picture = {
  pictureId: string;
  name: string;
  size: number;
  dimensions: { width: number; height: number };
  updatedAt: string;
  alt?: string;
  credit?: string;
  id: number;
  kind?: string;
};

export type RoomCategory = {
  id: number;
  name: string;
};

export type RoomFeature = {
  basic: boolean;
  category: string;
  id: number;
  name: string;
  priority: number;
};

export type BedCategory = {
  id: number;
  name: string;
  capacity: number;
  priority: number;
};

export type RoomListElement = {
  readonly id: number;
  readonly name: string;
  readonly hotelId: number;
  readonly hotelName: string;
  readonly categoryName: string;
  readonly coverPicture: Picture;
  readonly countryId: number;
  readonly published: boolean;
  readonly updatedAt: string;
};
