import React from 'react';

import { AdminUser } from 'app/typings';

type UserContextType = {
  token?: string;
  user?: AdminUser;
};

type Props = {
  children: React.ReactNode;
  token?: string;
  user?: AdminUser;
};

export const UserContext = React.createContext<UserContextType>({});

const UserContextProvider = ({ children, token, user }: Props) => {
  return (
    <UserContext.Provider value={{ token, user }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
