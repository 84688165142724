import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export const ApplicationFeesTooltip = () => {
  return (
    <Tooltip
      title="Fees for preparing the additional bed (bed linens, house keeping, etc). This does not include any fees related to your Staycation package."
      placement="right"
    >
      <span className="grey-icon margin-left">
        <InfoCircleOutlined />
      </span>
    </Tooltip>
  );
};
