import { Form, FormListFieldData, Select } from 'antd';

import { HeaderWrapper } from 'app/components/pages/Edito/commons/Detail/HeaderWrapper/HeaderWrapper';
import { DEFAULT_DISTANCE_FILTER } from 'app/utils/filters/filters';
import { requiredRule } from 'app/utils/rules';

type Props = {
  field: FormListFieldData;
};

const options: { label: string; value: string }[] = DEFAULT_DISTANCE_FILTER.map(
  (price) => ({
    label: `${price.start ?? '0'}-${price.end ?? ''} km`,
    value: `${price.start ?? ''}-${price.end ?? ''}`,
  })
);

export const DistanceForm = ({ field }: Props) => {
  const { name } = field;

  return (
    <HeaderWrapper isBold={false} title="Distance filter">
      <Form.Item
        name={[name, 'searchFilters', 'distances']}
        rules={[requiredRule]}
      >
        <Select
          options={options}
          mode="multiple"
          allowClear
          placeholder={`Search a filter for distance`}
        />
      </Form.Item>
    </HeaderWrapper>
  );
};
