import type { Location } from 'app/redux/models/Location/Location';
import { createLocation } from 'app/redux/models/Location/Location';
import { ChannelManager } from 'app/typings/channelManagers';

type Insights = {
  id: number;
  tier: number;
  presenceRatio: number;
  value: number;
};

export type HotelItem = {
  readonly id: number;
  readonly name: string;
  readonly countryId: number;
  readonly groupId: number;
  readonly insights: Insights;
  readonly location: Location;
  readonly contacts: Array<string>;
  readonly bookingUrl: string;
  readonly experiencesValue: number;
  readonly snoozeNotifications: string;
  readonly roomCount: number;
  readonly updatedAt: string;
  readonly stars: number;
  readonly hotelAdminAccess: boolean;
  readonly channelManager?: ChannelManager;
  readonly pspId: string;
  readonly pspOnboardingDone: boolean;
  readonly paymentReady: boolean;
  readonly adyenAccountRef?: string;
  managerId: number;
};

export function createHotelItem(values: any): HotelItem {
  const {
    id,
    name,
    countryId,
    groupId,
    insights,
    location,
    contacts,
    bookingUrl,
    experiencesValue,
    snoozeNotifications,
    roomCount,
    updatedAt,
    stars,
    hotelAdminAccess,
    pspId,
    pspOnboardingDone,
    paymentReady,
    adyenAccountRef,
    channelManager,
    managerId,
  } = values;

  return {
    id,
    name,
    countryId,
    groupId,
    insights,
    location: createLocation(location),
    contacts,
    bookingUrl,
    experiencesValue,
    snoozeNotifications,
    roomCount,
    updatedAt,
    stars,
    hotelAdminAccess,
    pspId,
    pspOnboardingDone,
    paymentReady,
    adyenAccountRef,
    channelManager,
    managerId,
  };
}
