export enum ChannelManager {
  NONE = 'NONE',
  D_EDGE = 'DEDGE',
  SITEMINDER = 'SITEMINDER',
  RESERVIT = 'RESERVIT',
  CUBILIS = 'CUBILIS',
  TRAVELCLICK = 'TRAVELCLICK',
  ROOMCLOUD = 'ROOMCLOUD',
}

export const CHANNEL_MANAGER_LABELS = [
  { manager: ChannelManager.NONE, label: 'None' },
  { manager: ChannelManager.D_EDGE, label: 'D-Edge' },
  { manager: ChannelManager.SITEMINDER, label: 'Siteminder' },
  { manager: ChannelManager.RESERVIT, label: 'Reservit' },
  { manager: ChannelManager.CUBILIS, label: 'Cubilis' },
  { manager: ChannelManager.TRAVELCLICK, label: 'Travelclick' },
  { manager: ChannelManager.ROOMCLOUD, label: 'Roomcloud' },
] as const;
