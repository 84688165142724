import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Collapse, CollapsePanelProps, Form, Radio, Switch } from 'antd';
import React, { useContext, useState } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { ApplicationFeesTooltip } from 'app/components/commons/Room/Beds/_components/ApplicationFeesTooltip';
import { RoomCapacity } from 'app/components/commons/Room/Beds/_components/RoomCapacity/RoomCapacity';
import PanelHeader from 'app/components/layout/PanelHeader/PanelHeader';

import { Bed, BedCategory } from '../../HotelBuilder/Types';

import './ExtraBed.scss';

const RULES = [{ required: true, message: 'Required' }];

const { Panel } = Collapse;

type Props = Partial<CollapsePanelProps> & {
  categoryName: string;
  categoryId: number;
  bed?: Bed;
  listBed: Bed[];
  remove: (index: number) => void;
  add: (
    bed: Omit<Bed, 'included'> & { included: boolean },
    index?: number
  ) => void;
  previousPrefixes: Array<string | number>;
  prefix: Array<string | number>;
  bedCategories?: BedCategory[];
  mode?: 'view' | 'edit';
};

export const ExtraBed = ({
  categoryName,
  categoryId,
  bed,
  listBed,
  add,
  remove,
  previousPrefixes,
  prefix,
  bedCategories,
  mode = 'edit',
}: Props) => {
  const isViewMode = mode === 'view';
  const form = Form.useFormInstance();
  const { currencyInput } = useContext(CurrencyContext);

  const [isCollapseActive, setIsCollapseActive] = useState(bed ? true : false);
  const [activeKey, setActiveKey] = useState<number | undefined>(undefined);

  const toggleSwitch = (checked: boolean) => {
    if (!checked) {
      listBed.forEach((bed, index) => {
        if (bed.categoryId === categoryId) remove(index);
      });
    } else {
      add({
        categoryId: categoryId,
        included: true,
        price: 0,
      });
    }

    setIsCollapseActive(checked);
    setOpenCollapse(checked);
  };

  const setOpenCollapse = (switchOn: boolean) => {
    if (switchOn) {
      activeKey === categoryId
        ? setActiveKey(undefined)
        : setActiveKey(categoryId);
    } else {
      setActiveKey(undefined);
    }
  };

  return (
    <Collapse
      collapsible={isCollapseActive ? 'header' : 'disabled'}
      className="extraBedCollapse"
      bordered={false}
      activeKey={activeKey}
      onChange={() => setOpenCollapse(isCollapseActive)}
    >
      <Panel
        key={categoryId}
        className="extraBedPanel"
        header={
          <PanelHeader
            name={categoryName}
            actions={
              <Form.Item rules={RULES}>
                <Switch
                  checkedChildren={<CheckOutlined color="#fff" />}
                  unCheckedChildren={<CloseOutlined color="#fff" />}
                  onChange={toggleSwitch}
                  defaultChecked={bed ? true : false}
                  disabled={mode === 'view'}
                />
              </Form.Item>
            }
          />
        }
      >
        <RoomCapacity
          capacity={
            bedCategories?.find((cat) => cat.id === categoryId)?.capacity
          }
          categoryId={categoryId}
        />
        <div className="formItem extraBedPanel__cost bedCost">
          <p className="extraBedPanel__cost_title bedCostTitle">Cost of bed</p>
          <Form.Item name={[...prefix, 'included']}>
            <Radio.Group className="bedCostForm" disabled={isViewMode}>
              <Radio value={true}>Free of charge</Radio>
              <Radio value={false}>
                Applicable fees
                <ApplicationFeesTooltip />
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        {!form.getFieldValue([...previousPrefixes, ...prefix, 'included']) && (
          <div className="formItem extraBedPanel__fees bedPrice">
            <p className="extraBedPanel__fees_title bedPriceTitle">
              Fees amount
            </p>
            <div className="bedPriceForm">
              <Form.Item name={[...prefix, 'price']} rules={RULES}>
                {currencyInput({
                  min: 0,
                  placeholder: 'Fees',
                  onWheel: (e) => e.currentTarget.blur(),
                  disabled: isViewMode,
                })}
              </Form.Item>
            </div>
          </div>
        )}
      </Panel>
    </Collapse>
  );
};
