import { NamePath } from 'antd/es/form/interface';
import Form, { Rule } from 'antd/lib/form';
import classNames from 'classnames';

import { OptGroup, Option, Select } from 'app/components/fields/Select';
import { BED_TASK_CATEGORIES, BedCategory } from 'app/typings';
import { pluralize } from 'app/utils/strings';

type SelectBedByPriorityProps = {
  bedCategories: BedCategory[];
  index: number;
  getFieldValue: (name: NamePath) => number;
  name: NamePath;
  label: string;
  rules: Rule[];
};

export const SelectBedByPriority = ({
  bedCategories,
  getFieldValue,
  index,
  name,
  label,
  rules,
  ...restField
}: SelectBedByPriorityProps) => {
  const filteredBedCategories = bedCategories.filter(
    (bedCategories) =>
      !BED_TASK_CATEGORIES.some((bedTask) => bedCategories.id === bedTask)
  );

  const { standardBeds, otherBeds } = filteredBedCategories.reduce<{
    standardBeds: BedCategory[];
    otherBeds: BedCategory[];
  }>(
    (acc, bed) => {
      acc[bed.priority === 1 ? 'standardBeds' : 'otherBeds'].push(bed);

      return acc;
    },
    { standardBeds: [], otherBeds: [] }
  );

  return (
    <>
      <p className="bedTypeTitle">Type</p>
      <div className="bedTypeForm">
        <Form.Item {...restField} name={name} rules={rules}>
          <Select
            placeholder="Select a type"
            optionLabelProp="label"
            optionFilterProp="children"
            className="bedTypeForm"
            useDefaultPopupContainer
            showSearch
          >
            <OptGroup label="Standard beds">
              {standardBeds.map((category) => (
                <Option
                  key={category.id}
                  value={category.id}
                  label={category.name}
                >
                  <div className="optionItem">
                    <div
                      className={classNames([
                        'optionBedName',
                        {
                          selected:
                            getFieldValue(['beds', index, 'categoryId']) ===
                            category.id,
                        },
                      ])}
                    >
                      {category.name}
                    </div>
                    <div className="optionBedCapacity">
                      {pluralize(category.capacity, 'guest', 'guests')}
                    </div>
                  </div>
                </Option>
              ))}
            </OptGroup>
            <OptGroup label="Other beds">
              {otherBeds.map((category) => (
                <Option
                  key={category.id}
                  value={category.id}
                  label={category.name}
                >
                  <div className="optionItem">
                    <div
                      className={classNames([
                        'optionBedName',
                        {
                          selected:
                            getFieldValue(['beds', index, 'categoryId']) ===
                            category.id,
                        },
                      ])}
                    >
                      {category.name}
                    </div>
                    <div className="optionBedCapacity">
                      {pluralize(category.capacity, 'guest', 'guests')}
                    </div>
                  </div>
                </Option>
              ))}
            </OptGroup>
          </Select>
        </Form.Item>
      </div>
    </>
  );
};
