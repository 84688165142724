import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { Empty, Form, FormInstance, Input, Switch } from 'antd';
import { ValidatorRule } from 'rc-field-form/lib/interface';
import { FC, useMemo, useState } from 'react';

import './HotelLinks.scss';

const RULES = [{ required: true, message: 'Required' }];

export const BOOKING_COM_URL_REGEXP =
  /^https:\/\/www\.booking\.com\/.*\.html[ ]*$/;

const KEYS = [
  'websiteUrl',
  'bookingUrl',
  'bookingComUrl',
  'instagramUrl',
  'instagramLocationUrl',
];

export const HotelLinks: FC<Props> = ({
  mode = 'edit',
  values,
  prefix = '',
  form,
}) => {
  const bookingComFieldName = useMemo(() => {
    return prefix ? [prefix, 'bookingComUrl'] : 'bookingComUrl';
  }, [prefix]);

  const [noBookingUrl, setNoBookingUrl] = useState(
    values?.bookingComUrl === '' || false
  );

  const bookingUrlValidatorRule: ValidatorRule = {
    validator: async (_, bookingUrl: string) => {
      if (
        !noBookingUrl &&
        !bookingUrl?.match(new RegExp(BOOKING_COM_URL_REGEXP))
      ) {
        return Promise.reject(
          new Error(
            'Need booking.com url format (https://www.booking.com/.html)'
          )
        );
      }

      return Promise.resolve();
    },
  };

  const notEmpty = useMemo(
    () => !values || KEYS.some((key) => values[key]),
    [values]
  );

  if (mode === 'view' && !notEmpty) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <div className="hotel-links">
      {(mode === 'edit' || values.websiteUrl) && (
        <div
          className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
        >
          <Form.Item
            name={prefix ? [prefix, 'websiteUrl'] : 'websiteUrl'}
            label="Website"
            rules={[...RULES, { type: 'url', message: 'Need url format' }]}
          >
            {mode === 'edit' || !values ? (
              <Input />
            ) : (
              <LinkOutlined onClick={() => window.open(values.websiteUrl)} />
            )}
          </Form.Item>
        </div>
      )}
      {(mode === 'edit' || values.bookingUrl) && (
        <div
          className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
        >
          <Form.Item
            name={prefix ? [prefix, 'bookingUrl'] : 'bookingUrl'}
            label="Reservation"
            rules={[...RULES, { type: 'url', message: 'Need url format' }]}
            extra={
              mode === 'edit' && (
                <div className="description">
                  <InfoCircleOutlined />
                  <div>
                    Link of the the booking page on the hotel’s own website
                  </div>
                </div>
              )
            }
          >
            {mode === 'edit' || !values ? (
              <Input />
            ) : (
              <LinkOutlined onClick={() => window.open(values.bookingUrl)} />
            )}
          </Form.Item>
        </div>
      )}
      {(mode === 'edit' || values.bookingComUrl) && (
        <div
          className={`input-row ${mode === 'view' ? 'input-row-no-rules' : ''}`}
        >
          <Form.Item
            name={bookingComFieldName}
            label="Booking.com"
            validateFirst
            rules={[
              { required: !noBookingUrl, message: 'Required' },
              bookingUrlValidatorRule,
            ]}
            extra={
              mode === 'edit' && (
                <div className="description">
                  <Switch
                    size="small"
                    checked={noBookingUrl}
                    onChange={(value) => {
                      setNoBookingUrl(value);
                      form.setFieldValue(bookingComFieldName, '');
                      form.validateFields([bookingComFieldName]);
                      form.isFieldTouched(bookingComFieldName);
                    }}
                  />
                  <div>Hotel is not on Booking.com</div>
                </div>
              )
            }
          >
            {mode === 'edit' || !values ? (
              <Input disabled={noBookingUrl} />
            ) : (
              <LinkOutlined onClick={() => window.open(values.bookingComUrl)} />
            )}
          </Form.Item>
        </div>
      )}
      {(mode === 'edit' || values.instagramUrl) && (
        <div className="input-row">
          <Form.Item
            name={prefix ? [prefix, 'instagramUrl'] : 'instagramUrl'}
            label="Instagram"
            rules={[{ type: 'url', message: 'Need url format' }]}
            extra={
              mode === 'edit' && (
                <div className="description">
                  <InfoCircleOutlined />
                  <div>If there is one</div>
                </div>
              )
            }
          >
            {mode === 'edit' || !values ? (
              <Input />
            ) : (
              <LinkOutlined onClick={() => window.open(values.instagramUrl)} />
            )}
          </Form.Item>
        </div>
      )}
      {(mode === 'edit' || values.instagramLocationUrl) && (
        <div className="input-row">
          <Form.Item
            name={
              prefix ? [prefix, 'instagramLocationUrl'] : 'instagramLocationUrl'
            }
            label="Instagram location"
            rules={[{ type: 'url', message: 'Need url format' }]}
            extra={
              mode === 'edit' && (
                <div className="description">
                  <InfoCircleOutlined />
                  <div>If there is one</div>
                </div>
              )
            }
          >
            {mode === 'edit' || !values ? (
              <Input />
            ) : (
              <LinkOutlined
                onClick={() => window.open(values.instagramLocationUrl)}
              />
            )}
          </Form.Item>
        </div>
      )}
    </div>
  );
};

type Props = {
  mode?: string;
  values?: any;
  prefix?: string;
  form: FormInstance<unknown>;
};

export default HotelLinks;
