import React, { useMemo } from 'react';

import {
  CustomPictureRow,
  InputRow,
  InputTextAreaRow,
  SelectWithPictureRow,
} from 'app/components/commons/CustomFields/CustomFields';
import { SortablePanel } from 'app/components/layout/Sortable/Collapse';
import { useHotels } from 'app/hooks/data/hotels/useHotel';
import { usePackages } from 'app/hooks/data/packages/usePackages';
import { useGetRooms } from 'app/hooks/data/rooms/useGetRooms';
import type { SectionPackage } from 'app/redux/models/Guides/Guides';
import type { Picture } from 'app/redux/models/Picture/Picture';

type Field = {
  name: number;
  key: number;
};

type Props = {
  handleRemove: (index: number) => void;
  field: Field;
  index: number;
  sectionPackage?: Partial<SectionPackage>;
  formattedPackages: Array<{
    id: number;
    name: string;
    coverPicture: Picture;
    searchName?: string;
  }>;
};

const SectionPackageComponent = ({
  handleRemove,
  field,
  index,
  sectionPackage,
  formattedPackages,
  ...props
}: Props) => {
  const { data: packages } = usePackages();
  const { data: rooms } = useGetRooms();
  const { data: hotels } = useHotels();

  const { pkg, room, hotel, filteredRooms } = useMemo(() => {
    const pkg = packages?.find(
      (_package) => _package.id === sectionPackage?.packageId
    );

    const room = rooms?.find((_room) => _room.id === sectionPackage?.roomId);

    const filteredRooms = rooms?.filter(
      (_room) => _room.hotelId === pkg?.hotelId
    );

    const hotel = hotels?.find((_hotel) => _hotel.id === pkg?.hotelId);

    return { pkg, room, hotel, filteredRooms };
  }, [packages, rooms, hotels, sectionPackage]);

  const defaultName = `Package ${index + 1}`;
  const composedName = `${room?.name} • ${hotel?.name}`;
  const fullName = room ? composedName : hotel?.name;
  const title = pkg ? fullName : defaultName;
  const { name, key } = field;

  return (
    <SortablePanel
      index={index}
      key={`pkg-${key}`}
      title={title}
      onRemove={() => handleRemove(index)}
      card={false}
      {...props}
    >
      <InputRow
        label="Title"
        name={[name, 'title']}
        placeholder="The best view in London"
      />
      <InputTextAreaRow
        label="Description"
        name={[name, 'description']}
        placeholder="The kind of view you'll never find anywhere else"
        rows={2}
      />
      <SelectWithPictureRow
        label="Package"
        name={[name, 'packageId']}
        placeholder="Select a package from the list"
        required
        showSearch
        dataSource={formattedPackages}
      />
      {sectionPackage?.packageId && (
        <SelectWithPictureRow
          label="Room"
          name={[name, 'roomId']}
          placeholder="Select a room from the list"
          dataSource={filteredRooms ?? []}
        />
      )}
      {pkg?.hotelId ? (
        <CustomPictureRow
          label="Cover Picture"
          name={[name, 'coverPicture']}
          hotelId={pkg.hotelId}
        />
      ) : null}
    </SortablePanel>
  );
};

export default SectionPackageComponent;
