import { Form, FormListFieldData, Select } from 'antd';

import { HeaderWrapper } from 'app/components/pages/Edito/commons/Detail/HeaderWrapper/HeaderWrapper';
import { SortFilter } from 'app/typings/filters';
import { requiredRule } from 'app/utils/rules';

type Props = {
  field: FormListFieldData;
};

const sortOptions: { label: string; value: SortFilter }[] = [
  {
    label: 'Relevance',
    value: 'BEST',
  },
  {
    label: 'Price',
    value: 'CHEAPEST',
  },
  {
    label: 'Distance',
    value: 'CLOSEST',
  },
  {
    label: 'Recency',
    value: 'NEWEST',
  },
  {
    label: 'Rating',
    value: 'RATING',
  },
];

export const SortForm = ({ field }: Props) => {
  const { name } = field;

  return (
    <HeaderWrapper isBold={false} title="Sort">
      <Form.Item name={[name, 'searchFilters', 'sort']} rules={[requiredRule]}>
        <Select options={sortOptions} placeholder="Select sort" />
      </Form.Item>
    </HeaderWrapper>
  );
};
