import { Form, FormInstance, FormListFieldData, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import MultipleDatePicker from 'app/components/pages/Collections/CollectionDetail/CollectionSections/MultipleDatePicker/MultipleDatePicker';
import { HeaderWrapper } from 'app/components/pages/Edito/commons/Detail/HeaderWrapper/HeaderWrapper';
import { IEditoEventForm, SectionExperienceType } from 'app/typings/edito';
import { requiredRule } from 'app/utils/rules';

import { FormLink } from '../FormLink/FormLink';

import './ExperienceTypeForm.scss';

interface Props {
  field: FormListFieldData;
  form: FormInstance<IEditoEventForm>;
}

const experienceTypesOptions: DefaultOptionType[] = [
  {
    label: 'Experiences with night (staycations)',
    value: 'staycation',
  },
  {
    label: 'Experiences by day (moments)',
    value: 'moment',
  },
];

const nightCountOptions: DefaultOptionType[] = [
  {
    label: '1 night',
    value: 1,
  },
  {
    label: '2 nights',
    value: 2,
  },
];

export const ExperienceTypeForm = ({ field, form }: Props) => {
  const { name } = field;

  const nightCountField = [
    'sections',
    field.name,
    'searchFilters',
    'nightCount',
  ];

  const experienceType = Form.useWatch(
    ['sections', field.name, 'searchFilters', 'experienceType'],
    form
  );

  const nightCount = Form.useWatch(nightCountField, form);

  const onChangeExperienceType = (experienceType: SectionExperienceType) => {
    form.setFieldValue(nightCountField, experienceType === 'moment' ? 0 : 1);
  };

  const onChangeNightCount = (newNightCount: 1 | 2) => {
    if (newNightCount === 1) {
      form.setFieldValue(
        ['sections', field.name, 'searchFilters', 'dates'],
        undefined
      );
    }
  };

  return (
    <HeaderWrapper title="Type" gapTitleChildren={16}>
      <div className="experienceTypeContainer">
        <div className="experienceTypeSubContainer">
          <HeaderWrapper isBold={false} title="Experience type">
            <Form.Item
              name={[name, 'searchFilters', 'experienceType']}
              rules={[requiredRule]}
            >
              <Select
                onChange={onChangeExperienceType}
                options={experienceTypesOptions}
                placeholder="Select experience type"
              />
            </Form.Item>
          </HeaderWrapper>
        </div>
        <div className="experienceTypeSubContainer">
          {experienceType === 'staycation' ? (
            <div className="staycationContainer">
              <div className="nightCountContainer">
                <FormLink />
                <HeaderWrapper isBold={false} title="Night count selected">
                  <Form.Item
                    name={[name, 'searchFilters', 'nightCount']}
                    rules={[requiredRule]}
                  >
                    <Select
                      onChange={onChangeNightCount}
                      options={nightCountOptions}
                      placeholder="Select night count"
                    />
                  </Form.Item>
                </HeaderWrapper>
              </div>
              {nightCount === 2 && (
                <>
                  <div className="multiNightLink" />
                  <div className="datesMultiNightContainer">
                    <FormLink width={12} />
                    <HeaderWrapper isBold={false} title="Dates">
                      <Form.Item
                        name={[name, 'searchFilters', 'dates']}
                        rules={[requiredRule]}
                      >
                        <MultipleDatePicker />
                      </Form.Item>
                    </HeaderWrapper>
                  </div>
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </HeaderWrapper>
  );
};
