import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useMutation, useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { BookingDetails } from 'app/typings/adminBookings/bookings';
import { apiMutation, apiRequest } from 'app/utils/request/api';

const path = 'admin/bookings/:bookingCode' as const;

const updateBookingEmailPath = 'admin/bookings/:bookingCode/email' as const;

export function useAdminBooking(bookingCode?: string) {
  return useQuery({
    queryKey: [path, { bookingCode }],
    queryFn: () =>
      !!bookingCode
        ? (apiRequest({
            path,
            params: { bookingCode },
          }) as Promise<BookingDetails>)
        : undefined,
    enabled: !!bookingCode,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}

export function useUpdateBookingEmail(bookingCode: string) {
  return useMutation({
    mutationFn: async (payload: { email: string }) =>
      apiMutation(
        'PUT',
        {
          path: updateBookingEmailPath,
          params: { bookingCode },
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      message.success('Email updated');
    },
    onError: () => {
      message.error('Failed to update email', 10);
    },
  });
}
