import { ArrowRightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Card, Row } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useContext } from 'react';

import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

import { TaskName } from '../../commons/TaskCenter/Types';
import { isDoneTask } from '../../commons/TaskCenter/Utils';

const page = 'stock score';

type HotelScoreBodyProps = {
  isManualHotel: boolean;
  openBulkEdit: (clickEvent: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  openAutoInventory: (
    clickEvent: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
};

export const HotelScoreBody = ({
  isManualHotel,
  openBulkEdit,
  openAutoInventory,
}: HotelScoreBodyProps) => {
  const { track } = useAmplitude();
  const { setOpenTask, doneTasks, openTaskCenter } =
    useContext(TaskCenterContext);

  const isLastMinutePricingRuleActivated = isDoneTask(
    TaskName.LastMinutePricingRule,
    doneTasks
  );

  const isPricingRuleActivated = isDoneTask(TaskName.PricingRule, doneTasks);

  const isAutoInventoryActivated = isDoneTask(
    TaskName.AutoInventory,
    doneTasks
  );

  return (
    <Row style={{ gap: '16px' }}>
      {isManualHotel && (
        <>
          <Card className="hotel-score__card">
            <Meta
              title={
                <Row className="hotel-score__row">
                  <div>Automate your stock</div>
                  {isAutoInventoryActivated && (
                    <CheckCircleOutlined className="hotel-score__checkcircle" />
                  )}
                </Row>
              }
              description="Uplift bookings by automating your inventory."
            />
            <Button
              className="hotel-score__task-button"
              onClick={(clickEvent) => {
                openAutoInventory(clickEvent);
                track('Open Auto inventory modal', {
                  from: page,
                });
              }}
            >
              Activate auto inventory
              <ArrowRightOutlined />
            </Button>
          </Card>
          <Card className="hotel-score__card">
            <Meta
              title="Load more weekends"
              description="Add stock on Fridays and Saturdays to be visible on peak."
            />
            <Button
              className="hotel-score__task-button"
              onClick={(clickEvent) => {
                openBulkEdit(clickEvent);
                track('Open Bulk Edit', {
                  from: page,
                });
              }}
            >
              Add weekends
              <ArrowRightOutlined />
            </Button>
          </Card>
        </>
      )}
      {!isManualHotel && (
        <>
          <Card className="hotel-score__card">
            <Meta
              title={
                <Row className="hotel-score__row">
                  <div>Set default discount</div>
                  {isPricingRuleActivated && (
                    <CheckCircleOutlined className="hotel-score__checkcircle" />
                  )}
                </Row>
              }
              description="Hotels who activate a default discount double their bookings on average."
            />
            <Button
              className="hotel-score__task-button"
              onClick={() => {
                openTaskCenter();
                setOpenTask(TaskName.PricingRule);
                track('Open Task center', {
                  from: page,
                  task: TaskName.PricingRule,
                });
              }}
            >
              Activate default discount
              <ArrowRightOutlined />
            </Button>
          </Card>
          <Card className="hotel-score__card">
            <Meta
              title={
                <Row className="hotel-score__row">
                  <div>Boost your last minute fill rate</div>
                  {isLastMinutePricingRuleActivated && (
                    <CheckCircleOutlined className="hotel-score__checkcircle" />
                  )}
                </Row>
              }
              description="Add 5% to your last minute discount to uplift your last minute bookings."
            />
            <Button
              className="hotel-score__task-button"
              onClick={() => {
                openTaskCenter();
                setOpenTask(TaskName.LastMinutePricingRule);
                track('Open Task center', {
                  from: page,
                  task: TaskName.LastMinutePricingRule,
                });
              }}
            >
              Activate last minute discount
              <ArrowRightOutlined />
            </Button>
          </Card>
        </>
      )}
    </Row>
  );
};
