const RedirectIcon = () => (
  <span role="img" aria-label="redirect" className="anticon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 2C21.1357 2 21.2652 2.02705 21.3832 2.07605L21.9933 2.88432L22 3V9C22 9.55228 21.5523 10 21 10C20.4872 10 20.0645 9.61396 20.0067 9.11662L20 9L19.9998 5.41443L11.7071 13.7071C11.3166 14.0976 10.6834 14.0976 10.2929 13.7071C9.93241 13.3466 9.90468 12.7794 10.2097 12.3871L10.2929 12.2929L18.5858 4H15C14.4872 4 14.0645 3.61396 14.0067 3.11662L14 3C14 2.48716 14.386 2.06449 14.8834 2.00673L15 2H21Z"
        fill="black"
      />
      <path
        d="M7 5C7 4.44772 6.55228 4 6 4H5L4.82378 4.00509C3.24945 4.09638 2 5.40287 2 7V19L2.00509 19.1762C2.09638 20.7506 3.40287 22 5 22H17L17.1762 21.9949C18.7506 21.9036 20 20.5971 20 19V18L19.9933 17.8834C19.9355 17.386 19.5128 17 19 17C18.4477 17 18 17.4477 18 18V19L17.9933 19.1165C17.9354 19.6135 17.5123 20 17 20H5L4.88348 19.9933C4.38653 19.9354 4 19.5123 4 19V7L4.00674 6.88348C4.06457 6.38653 4.48769 6 5 6H6L6.11662 5.99327C6.61396 5.93551 7 5.51284 7 5Z"
        fill="black"
      />
    </svg>
  </span>
);

export default RedirectIcon;
