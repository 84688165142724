import type { AnyAction } from 'redux';

import * as actions from 'app/redux/actions/hotels';
import { ChannelManager } from 'app/typings/channelManagers';
import { Hotel } from 'app/typings/hotels/Hotel/Hotel';
import { RateMode } from 'app/typings/rateModes';

import { HotelItem } from '../models/HotelItem/HotelItem';
import { NewHotel } from '../models/NewHotel/NewHotel';

export type Hotels = {
  hotel: Hotel | null;
  hotels: Array<HotelItem>;
  newHotel: Partial<NewHotel> | null;
  isLoading: boolean;
};

export const initialState: Hotels = {
  hotel: null,
  hotels: [],
  newHotel: null,
  isLoading: false,
};

function hotels(state = initialState, action: AnyAction): Hotels {
  if (actions.startLoading.match(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (actions.stopLoading.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (actions.fetchHotelsSuccess.match(action)) {
    return {
      ...state,
      hotels: action.payload.hotels,
      isLoading: false,
    };
  }

  if (actions.fetchHotelSuccess.match(action)) {
    return {
      ...state,
      hotel: {
        ...action.payload.hotel,
        channelManager:
          action.payload.hotel.channelManager || ChannelManager.NONE,
        rateMode: action.payload.hotel.rateMode || RateMode.MULTI_RATE,
      },
    };
  }

  return state;
}

export default hotels;
