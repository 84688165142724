import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { VoucherForm } from 'app/typings/adminBookings/bookingVoucher';
import { apiMutation } from 'app/utils/request/api';

const voucherPath = 'vouchers' as const;
const deleteOrUpdateVoucherPath = 'vouchers/:voucherCode' as const;

export function useDeleteBookingVoucher(voucherCode?: string) {
  return useMutation({
    mutationFn: !!voucherCode
      ? async () =>
          apiMutation('DELETE', {
            path: deleteOrUpdateVoucherPath,
            params: { voucherCode },
          }) as Promise<void>
      : async () => {},
    onSuccess: () => {
      message.success('Voucher deleted');
    },
    onError: () => {
      message.error('Failed to delete voucher', 10);
    },
  });
}

export function useCreateBookingVoucher() {
  return useMutation({
    mutationFn: async (payload: VoucherForm) =>
      apiMutation(
        'POST',
        {
          path: voucherPath,
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      message.success('Voucher created');
    },
    onError: () => {
      message.error('Failed to create voucher', 10);
    },
  });
}

export function useUpdateBookingVoucher(voucherCode?: string) {
  return useMutation({
    mutationFn: !!voucherCode
      ? async (payload: VoucherForm) =>
          apiMutation(
            'PUT',
            {
              path: deleteOrUpdateVoucherPath,
              params: { voucherCode },
            },
            payload
          ) as Promise<void>
      : async () => {},
    onSuccess: () => {
      message.success('Voucher updated');
    },
    onError: () => {
      message.error('Failed to update voucher', 10);
    },
  });
}
