import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';
import { useMemo } from 'react';

import { Bill } from 'app/redux/models/Bill/Bill';
import { PSP } from 'app/typings/PSP';
import { apiRequest } from 'app/utils/request/api';

const path = '/admin/bills' as const;

export type GetBillsQueryParams = {
  search?: string;
  provider?: PSP;
  sortingColumn?: string;
  sortingOrder?: string;
  offset?: number;
  limit?: number;
};

export function useGetBills(queryParams?: GetBillsQueryParams) {
  const key = useMemo(() => [path, { queryParams }], [queryParams]);

  return useQuery({
    queryKey: [key],
    queryFn: () =>
      apiRequest({
        path,
        queryParams,
      }).catch((err) => message.error('Error fetching bills', err)) as Promise<
        Bill[]
      >,
  });
}
