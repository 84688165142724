import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React from 'react';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { AutoInventorySteps } from 'app/typings/autoInventory';

import './HowDoesItWork.scss';

const { Panel } = Collapse;

export type Item = {
  title: string;
  description: string;
  emoji: string;
};

type TrackFrom = 'princing-rule' | 'auto-inventory';

type HowDoesItWorkProps = {
  items: Item[];
  trackFrom: TrackFrom;
  stepFrom?: AutoInventorySteps;
};

const HowDoesItWork = ({ items, trackFrom, stepFrom }: HowDoesItWorkProps) => {
  const { track } = useAmplitude();

  return (
    <div className="howDoesItWork">
      <Collapse
        className="howDoesItWork-collapse"
        bordered={false}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? 180 : 0} />
        )}
        onChange={(e) =>
          track('How does it work', { from: trackFrom, step: stepFrom })
        }
      >
        <Panel
          key="Pricing Rule Description"
          header={
            <div className="explanation-header">
              <QuestionCircleOutlined /> <h3>How does it work?</h3>
            </div>
          }
          className="explanation-panel"
        >
          <ul className="description-list">
            {items.map((item) => {
              return (
                <li className="description-list__item">
                  <span className="description-list__item--bold">
                    {`${item.emoji} ${item.title} : `}
                  </span>
                  {item.description}
                </li>
              );
            })}
          </ul>
        </Panel>
      </Collapse>
    </div>
  );
};

export default HowDoesItWork;
