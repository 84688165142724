import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { HotelServiceForm } from 'app/components/commons/Hotel/Types';
import { apiMutation } from 'app/utils/request/api';

const saveHotelServicesPath = 'hotels/:hotelId/services' as const;

export function useSaveHotelServices(hotelId: string) {
  return useMutation({
    mutationFn: async (payload: HotelServiceForm[]) =>
      apiMutation(
        'PUT',
        {
          path: saveHotelServicesPath,
          params: { hotelId },
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      message.success('Hotel successfully saved!');
    },
    onError: () => {
      message.error('Error posting services');
    },
  });
}
