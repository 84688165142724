import { PAX } from 'app/typings/PAX';
import { PackageFilters, Range } from 'app/typings/filters';

export const DEFAULT_PAX: PAX = {
  adults: 2,
  children: 0,
  babies: 0,
};

export const DEFAULT_FILTERS: Required<PackageFilters> = {
  ...DEFAULT_PAX,
  dates: [],
  distances: [],
  hotelServices: [],
  tagIds: [],
  prices: [],
  pmr: false,
  sort: 'BEST',
  nightCount: 1,
};

export const DEFAULT_PRICE_FILTER: Range[] = [
  { end: 100 },
  { start: 100, end: 150 },
  { start: 150, end: 200 },
  { start: 200, end: 300 },
  { start: 300 },
];

export const DEFAULT_DISTANCE_FILTER: Range[] = [
  { end: 5 },
  { start: 5, end: 20 },
  { start: 20, end: 50 },
  { start: 50, end: 100 },
  { start: 100, end: 250 },
];

export const BABY_SITTER_TAG_ID = 4 as const;
export const PARKING_TAG_ID = 5 as const;
export const PETS_TAG_ID = 6 as const;

export function parseRange(value: string): Range {
  const [start, end] = value
    .split('-')
    .map((bound) => parseInt(bound, 10) || undefined);

  return { start, end };
}
