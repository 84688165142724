import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { BedCategory } from 'app/typings';
import { apiRequest } from 'app/utils/request/api';

const path = '/rooms/beds/categories' as const;

export function useBedCategories() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<BedCategory[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
