import { Form, FormInstance, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { DefaultOptionType } from 'antd/lib/select';
import { useMemo } from 'react';

import { BannerInfo } from 'app/components/commons/BannerInfo/BannerInfo';
import { useAppSelector } from 'app/redux/hooks';
import { selectClubs } from 'app/redux/selectors/conf';
import { IEditoEventForm } from 'app/typings/edito';
import { filterLabelOption } from 'app/utils/form';
import { requiredRule } from 'app/utils/rules';

import { EditoContainer } from '../../../commons/Detail/EditoContainer/EditoContainer';
import { HeaderWrapper } from '../../../commons/Detail/HeaderWrapper/HeaderWrapper';

import './EditoEventLocation.scss';

type Props = {
  form: FormInstance<IEditoEventForm>;
};

const NO_CLUB = {
  label: 'No club',
  value: 'empty',
};

export const EditoEventLocation = ({ form }: Props) => {
  const clubs = useAppSelector(selectClubs);
  const countries = useAppSelector((state) => state.conf.countries);
  const selectedCountryId = Form.useWatch('countryId', form);

  const countryOptions: DefaultOptionType[] = useMemo(
    () =>
      countries.map((country) => ({
        label: country.name,
        value: country.id,
      })),
    [countries]
  );

  const resetClubId = () => {
    form.setFieldValue('clubId', 'empty');
  };

  const filteredClubs = useMemo(
    () =>
      clubs
        .filter((club) => {
          return selectedCountryId
            ? selectedCountryId === club.countryId
            : true;
        })
        .map((club) => ({
          label: club.name,
          value: club.id,
        })),
    [selectedCountryId, clubs]
  );

  const clubOptions = [NO_CLUB, ...filteredClubs];

  return (
    <EditoContainer
      title="Location"
      subTitle="Define where your event will be visible to users"
    >
      <div className="editoEventLocationContainer">
        <div className="editoEventLocationSelectContainer">
          <HeaderWrapper title="Country">
            <FormItem name="countryId" rules={[requiredRule]}>
              <Select
                showSearch
                onChange={resetClubId}
                options={countryOptions}
                placeholder="Select country"
                filterOption={filterLabelOption}
              />
            </FormItem>
          </HeaderWrapper>
          <HeaderWrapper title="Club" addTitleOptional>
            <FormItem name="clubId">
              <Select
                showSearch
                disabled={!selectedCountryId}
                options={clubOptions}
                placeholder="Select club"
                filterOption={filterLabelOption}
              />
            </FormItem>
          </HeaderWrapper>
        </div>
        <BannerInfo title="If you select only a country, the event will appear on every club of the selected country" />
      </div>
    </EditoContainer>
  );
};
