import { Tag } from 'antd';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import classNames from 'classnames';
import { addDays, format, isAfter, isBefore, subDays } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React, { useState } from 'react';

import { Select } from 'app/components/fields/Select';

import './MultipleDatePicker.css';

type Value = string[] | undefined;

type MultipleDatePickerProps = {
  value?: Value;
  onChange?: (value: Value) => void;
  disabled?: boolean;
};

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export const MultipleDatePicker = ({
  onChange,
  value,
  disabled = false,
}: MultipleDatePickerProps) => {
  const [open, setOpen] = useState(false);
  const [arrayDateSelect, setArrayDateSelect] = useState<string[]>(value || []);

  if (!onChange) {
    return null;
  }

  const onValueChange = (date: Date | null) => {
    if (date === null) {
      return;
    }

    let arrayDates = arrayDateSelect;
    const formatDate = format(date, 'yyyy-MM-dd');

    const index = arrayDates.indexOf(formatDate);

    if (index > -1) {
      arrayDates = arrayDates.filter((item) => item !== formatDate);
    } else {
      arrayDates = [...arrayDates, formatDate];
    }

    arrayDates = arrayDates.sort();
    setArrayDateSelect(arrayDates);

    onChange(arrayDates);
  };

  const dateRender = (currentDate: Date) => {
    const arrayDates = arrayDateSelect;

    const t = format(currentDate, 'yyyy-MM-dd');
    const isSelected = arrayDates.indexOf(t) > -1;

    return (
      <div
        className={classNames(
          {
            dateSelected: isSelected,
          },
          'ant-picker-cell-inner'
        )}
      >
        {format(currentDate, 'dd')}
      </div>
    );
  };

  const handleClose = (value: string) => {
    let arrayDates = arrayDateSelect;

    arrayDates = arrayDates.filter((item) => item !== value);
    setArrayDateSelect(arrayDates);
    onChange(arrayDates);
  };

  const renderTag = ({ value }: { value: string }) => {
    return (
      <Tag
        closable={!disabled}
        className={disabled ? 'date-tag-disabled' : ''}
        onClose={() => handleClose(value)}
      >
        {format(new Date(value), 'EEE dd MMM')}
      </Tag>
    );
  };

  return (
    <Select
      useDefaultPopupContainer={false}
      allowClear={!disabled}
      placeholder={'Select few dates'}
      mode="multiple"
      value={arrayDateSelect}
      tagRender={renderTag}
      open={open}
      onClear={() => setArrayDateSelect([])}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      dropdownMatchSelectWidth={false}
      popupClassName={`multiple-dropdown`}
      dropdownStyle={{ height: '270px', width: '280px', minWidth: '0' }}
      disabled={disabled}
      dropdownRender={() => {
        return (
          <DatePicker
            format={(value) => ''}
            onChange={onValueChange}
            open={open}
            value={undefined}
            dateRender={dateRender}
            disabledDate={(d) =>
              !d ||
              isAfter(d, addDays(new Date(), 60)) ||
              isBefore(d, subDays(new Date(), 1))
            }
            style={{ visibility: 'hidden' }}
            getPopupContainer={(node) =>
              (node.parentNode || node) as HTMLElement
            }
          />
        );
      }}
    />
  );
};

export default MultipleDatePicker;
