import { useMutation } from '@tanstack/react-query';

import { apiMutation } from 'app/utils/request/api';

const path = '/hotel-admin/:hotelId/stock-scrapper' as const;

export function useDisconnect(hotelId: number) {
  return useMutation({
    mutationFn: async () =>
      apiMutation('DELETE', {
        path,
        params: { hotelId: hotelId.toString() },
      }) as Promise<void>,
    onSuccess: (_, payload) => {},
  });
}
