import { useEffect, useState } from 'react';

function getStorageValue<T = unknown>(key: string, defaultValue: T) {
  const saved = localStorage.getItem(key);
  const initial = !!saved && JSON.parse(saved);

  return initial || defaultValue;
}

export const useLocalStorage = <T = unknown>(key: string, defaultValue: T) => {
  const [value, setValue] = useState<T>(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return { value, setValue };
};
