import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { apiMutation } from 'app/utils/request/api';

import { listPath as fetchPath } from './useGuides';

const pathPublish = '/guides/:id/publish' as const;
const pathFeature = '/guides/:id/feature' as const;
const pathHybrid = '/guides/:id/hybrid' as const;
const pathSort = '/guides/sort' as const;

export const useUpdatePublished = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { id: number; published: boolean }) =>
      apiMutation(
        'PATCH',
        {
          path: pathPublish,
          params: { id: payload.id.toString() },
        },
        payload
      ),
    onSuccess: (_, payload) => {
      message.success(
        `${payload.published ? 'Published' : 'Unpublished'} guide #${
          payload.id
        }!`
      );

      queryClient.invalidateQueries({
        queryKey: [fetchPath],
      });
    },
    onError: (_, payload) => {
      message.error(
        `Failed to ${payload.published ? 'published' : 'unpublished'} #${
          payload.id
        }!`
      );
    },
  });
};

export const useUpdateFeaturedGuide = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { id: number; featured: boolean }) =>
      apiMutation(
        'PATCH',
        {
          path: pathFeature,
          params: { id: payload.id.toString() },
        },
        payload
      ),
    onSuccess: (_, payload) => {
      message.success(`Featured guide #${payload.id}!`);

      queryClient.invalidateQueries({
        queryKey: [fetchPath],
      });
    },
    onError: (_, payload) => {
      message.error(`Failed to feature guide #${payload.id}!`);
    },
  });
};

export const useUpdateHybridGuide = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { id: number; isHybrid: boolean }) =>
      apiMutation(
        'PATCH',
        {
          path: pathHybrid,
          params: { id: payload.id.toString() },
        },
        payload
      ),
    onSuccess: (_, payload) => {
      message.success(`Success hybrid conversion for guide #${payload.id}!`);

      queryClient.invalidateQueries({
        queryKey: [fetchPath],
      });
    },
    onError: (_, payload) => {
      message.error(`Failed to toggle hybrid for guide #${payload.id}!`);
    },
  });
};

export const useUpdateSortGuides = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: number[]) =>
      apiMutation(
        'PATCH',
        {
          path: pathSort,
        },
        payload
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [fetchPath],
      });
    },
  });
};
