import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/redux/store';

export const selectConf = (state: RootState) => state.conf;
export const selectCountries = createSelector(
  selectConf,
  (conf) => conf.countries
);
export const selectClubs = createSelector(selectConf, (conf) => conf.clubs);
export const selectHotelGroups = createSelector(
  selectConf,
  (conf) => conf.hotelGroups
);
export const selectExperienceCategories = createSelector(
  selectConf,
  (conf) => conf.experienceCategories
);
export const selectRoomCategories = createSelector(
  selectConf,
  (conf) => conf.roomCategories
);
export const selectRoomFeatures = createSelector(
  selectConf,
  (conf) => conf.roomFeatures
);

export const selectExperienceCategoriesByIds = (categoryIds: number[]) =>
  createSelector(selectExperienceCategories, (experienceCategories) =>
    experienceCategories.filter((experienceCategory) =>
      categoryIds.includes(experienceCategory.id)
    )
  );
export const selectSpaceTypes = createSelector(
  selectConf,
  (conf) => conf.spaceTypes
);

export const selectCancellationReasons = (state: RootState) =>
  selectConf(state).cancellationReasons;
