import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { apiMutation } from 'app/utils/request/api';

const editBookingFeedbackPublishedPath =
  'admin/bookings/:bookingCode/feedback/:feedbackId/published' as const;
const editBookingReviewPath =
  'admin/bookings/:bookingCode/feedback/:feedbackId/comment' as const;

export function useEditBookingFeedbackPublished(
  bookingCode: string,
  feedbackId: number,
  published: boolean
) {
  return useMutation({
    mutationFn: async (payload: { published: boolean }) =>
      apiMutation(
        'PUT',
        {
          path: editBookingFeedbackPublishedPath,
          params: { bookingCode, feedbackId: `${feedbackId}` },
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      message.success(`Feedback ${published ? 'published' : 'unpublished'}`);
    },
    onError: () => {
      message.error(
        `Failed to ${published ? 'publish' : 'unpublish'} feedback`,
        10
      );
    },
  });
}

export function useEditBookingReview(bookingCode: string, feedbackId?: number) {
  return useMutation({
    mutationFn: async (payload: { comment: string }) =>
      apiMutation(
        'PUT',
        {
          path: editBookingReviewPath,
          params: { bookingCode, feedbackId: `${feedbackId}` },
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      message.success('Feedback edited');
    },
    onError: () => {
      message.error('Failed to edit feedback', 10);
    },
  });
}
