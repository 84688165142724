import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, Radio } from 'antd';
import _ from 'lodash';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { ApplicationFeesTooltip } from 'app/components/commons/Room/Beds/_components/ApplicationFeesTooltip';
import { RoomCapacity } from 'app/components/commons/Room/Beds/_components/RoomCapacity/RoomCapacity';
import { SelectBedByPriority } from 'app/components/commons/Room/Beds/_components/SelectBedByPriority';
import { ExtraBed } from 'app/components/pages/Extranet/commons/ExtraBed/ExtraBed';
import {
  BABY_COT_BED_CATEGORY_ID,
  BED_TASK_CATEGORIES,
  Bed,
  BedCategory,
  Room,
} from 'app/typings';

import './BedsBuilder.scss';

const { Panel } = Collapse;

const RULES = [{ required: true, message: 'Required' }];

const BedsBuilder: FC<BedsBuilderProps> = ({
  fields,
  bedCategories,
  add,
  remove,
  mode = 'edit',
  values,
}) => {
  const [activeKeys, setActiveKeys] = useState<Array<string>>(
    fields.map((field) => `${field.key}`)
  );
  const { currencyFormatter, currencyInput } = useContext(CurrencyContext);
  const refs = useRef<Array<HTMLElement | null>>([]);

  const activeExtraBed = useCallback(() => {
    return BED_TASK_CATEGORIES.filter((category) =>
      values.beds.some((bed) => bed.categoryId === category)
    ).length;
  }, [values.beds]);

  useEffect(() => {
    refs?.current[fields.length - (1 + activeExtraBed())]?.scrollIntoView({
      behavior: 'smooth',
    });
    setActiveKeys((prevActiveKeys) => {
      const newKey = `${fields[fields.length - (1 + activeExtraBed())]?.key}`;
      const index = prevActiveKeys.indexOf(newKey);

      if (index < 0) {
        return [...prevActiveKeys, newKey];
      }

      return prevActiveKeys;
    });
  }, [fields, activeExtraBed]);

  const toggleActiveKey = (newActiveKeys: any) => {
    setActiveKeys(newActiveKeys);
  };

  const renderHeader = (index: any, name: any) => {
    if (index === 0) {
      return 'Bed #01';
    }

    return (
      <div
        className="bed-header"
        ref={(element) => (refs.current[index] = element)}
      >
        {`Bed #${_.padStart(`${index + 1}`, 2, '0')}`}
        {mode === 'edit' && <DeleteOutlined onClick={() => remove(name)} />}
      </div>
    );
  };

  const filteredBedCategories = bedCategories.filter(
    (bedCategories) =>
      !BED_TASK_CATEGORIES.some((bedTask) => bedCategories.id === bedTask)
  );

  return (
    <div className="bedsBuilder">
      <Collapse activeKey={activeKeys} onChange={toggleActiveKey}>
        {fields.map(
          ({ key, name, ...restField }, index) =>
            !BED_TASK_CATEGORIES.some(
              (task) => task === values.beds[index].categoryId
            ) && (
              <Panel header={renderHeader(index, name)} key={index}>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) =>
                    mode === 'edit' ? (
                      <>
                        <div className="input-row bedType">
                          <SelectBedByPriority
                            index={index}
                            getFieldValue={getFieldValue}
                            name={[name, 'categoryId']}
                            label="Type"
                            rules={RULES}
                            bedCategories={bedCategories}
                            {...restField}
                          />
                        </div>
                        {
                          <RoomCapacity
                            capacity={
                              bedCategories.find(
                                (cat) =>
                                  cat.id ===
                                  getFieldValue(['beds', name, 'categoryId'])
                              )?.capacity
                            }
                          />
                        }
                      </>
                    ) : (
                      <>
                        <div className="input-row bedType">
                          <p className="bedTypeTitle">Type</p>

                          <Form.Item
                            {...restField}
                            name={[name, 'categoryId']}
                            label="Type"
                            rules={RULES}
                          >
                            <div className="view-text bedTypeForm">
                              {
                                filteredBedCategories.find(
                                  (cat) =>
                                    cat.id ===
                                    getFieldValue(['beds', name, 'categoryId'])
                                )?.name
                              }
                            </div>
                          </Form.Item>
                        </div>
                      </>
                    )
                  }
                </Form.Item>
                {index > 0 && (
                  <>
                    <div className="input-row included bedCost">
                      <p className="bedCostTitle">Cost of bed</p>
                      <Form.Item
                        {...restField}
                        name={[name, 'included']}
                        noStyle
                      >
                        <Radio.Group
                          disabled={mode !== 'edit'}
                          className="bedCostForm"
                        >
                          <Radio value={true}>Free of charge</Radio>
                          <Radio value={false}>
                            Applicable fees
                            <ApplicationFeesTooltip />
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const display =
                          getFieldValue(['beds', name, 'included']) === false;

                        if (!display) {
                          return null;
                        }

                        return mode === 'edit' ? (
                          <div className="input-row bedPrice">
                            <p className="bedPriceTitle">Fees amount</p>
                            <div className="bedPriceForm">
                              <Form.Item
                                {...restField}
                                name={[name, 'price']}
                                rules={RULES}
                              >
                                {currencyInput({ min: 0 })}
                              </Form.Item>
                            </div>
                          </div>
                        ) : (
                          <div className="input-row bedPrice">
                            <p className="bedPriceTitle">Fees amount</p>
                            <div className="bedPriceForm">
                              <Form.Item
                                {...restField}
                                name={[name, 'price']}
                                rules={RULES}
                              >
                                <div className="view-text">{`${currencyFormatter(
                                  getFieldValue(['beds', name, 'price']) || 0
                                )}`}</div>
                              </Form.Item>
                            </div>
                          </div>
                        );
                      }}
                    </Form.Item>
                  </>
                )}
              </Panel>
            )
        )}

        {BED_TASK_CATEGORIES.map((bedTypeId, index) => {
          return (
            <ExtraBed
              key={'key' + index}
              listBed={values.beds}
              bed={values.beds.find((bed: Bed) => bed.categoryId === bedTypeId)}
              categoryName={
                bedTypeId === BABY_COT_BED_CATEGORY_ID
                  ? 'Baby cot'
                  : 'Guest bed'
              }
              categoryId={bedTypeId}
              add={add}
              remove={remove}
              previousPrefixes={['beds']}
              prefix={[
                values.beds.findIndex((bed) => bed.categoryId === bedTypeId),
              ]}
              bedCategories={bedCategories}
              mode={mode}
            />
          );
        })}
      </Collapse>
      <Form.Item>
        <div className="footer">
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              add({ included: true }, fields.length - activeExtraBed())
            }
          >
            Add bed
          </Button>
        </div>
      </Form.Item>
    </div>
  );
};

type FieldData = {
  name: number;
  key: number;
};

type BedsBuilderProps = {
  fields: Array<FieldData>;
  bedCategories: Array<BedCategory>;
  add: (
    bed: Omit<Bed, 'included'> & { included: boolean },
    index?: number
  ) => void;
  remove: (index: number) => void;
  mode?: 'view' | 'edit';
  values: Room;
};

const mapStateToProps = (state: any) => ({
  bedCategories: state.conf.bedCategories,
});

export default connect(mapStateToProps)(BedsBuilder);
