import React from 'react';

const StaycationLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_3892_62300)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6429 0C21.6015 0 23.9998 2.39837 23.9998 5.35691L24 8.20974C23.6946 8.2286 21.4935 8.85977 21.4805 12C21.4676 15.1404 23.694 15.7267 24 15.7441L23.9998 18.6431C23.9998 21.6016 21.6015 24 18.6429 24H5.35686C2.39835 24 0 21.6016 0 18.6431V5.35691C0 2.39837 2.39835 0 5.35686 0H18.6429ZM11.9668 5.56906C9.33379 5.56906 7.65353 6.98695 7.65353 9.11378C7.65353 10.6873 8.485 11.8112 10.1133 12.4164L12.2959 13.2637C13.3872 13.696 13.7683 14.0764 13.7683 14.8199C13.7683 15.6672 13.1793 16.1514 12.14 16.1514C10.9101 16.1514 10.1652 15.3732 10.1652 14.0591H7.35906C7.35906 16.7738 9.14324 18.3646 12.1227 18.3646C14.8076 18.3646 16.5745 16.8949 16.5745 14.6297C16.5745 13.0043 15.8123 11.8804 14.132 11.2233L12.1227 10.4279C10.9274 9.94377 10.4597 9.52877 10.4597 8.85441C10.4597 8.12818 10.9967 7.66131 11.9148 7.66131C13.0061 7.66131 13.595 8.2838 13.595 9.40773H16.4012C16.4012 7.07341 14.6344 5.56906 11.9668 5.56906Z"
        fill="#FF2E63"
      />
    </g>
    <defs>
      <clipPath id="clip0_3892_62300">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default StaycationLogo;
