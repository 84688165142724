import { Form, Layout } from 'antd';
import React, { useCallback, useState } from 'react';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import HotelDescriptions from 'app/components/commons/Hotel/MarketingBrief/HotelDescriptions/HotelDescriptions';
import HotelLinks from 'app/components/commons/Hotel/MarketingBrief/HotelLinks/HotelLinks';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useSaveHotelBrief } from 'app/hooks/data/hotels/useHotelBrief';
import { Hotel } from 'app/typings/hotels/Hotel/Hotel';
import { HotelBrief } from 'app/typings/hotels/HotelBrief/HotelBrief';

import './MarketingBrief.scss';

const { Content } = Layout;

type Props = {
  headerMenu: React.ReactNode;
  hotel?: Hotel;
  reloadHotel: () => void;
};

export const MarketingBrief = ({ headerMenu, hotel, reloadHotel }: Props) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [form] = Form.useForm();

  const { mutateAsync: saveBrief } = useSaveHotelBrief(`${hotel?.id}`);

  const onFinish = useCallback(
    async (values: HotelBrief) => {
      if (hotel?.id) {
        await saveBrief(values);
        await reloadHotel();
        setMode('view');
      }
    },
    [hotel?.id, reloadHotel, saveBrief]
  );

  const onCancel = () => {
    form.resetFields();
    form.validateFields();
    setMode('view');
  };

  if (!hotel) {
    return null;
  }

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={hotel.brief}
      className="marketing-brief-form"
    >
      {(values, { resetFields, isFieldsTouched, submit }) => (
        <SDetailLayout
          title={hotel.name}
          backRoute="/hotels"
          mode={mode}
          onEdit={() => setMode('edit')}
          onSave={submit}
          onCancel={onCancel}
          reset={resetFields}
          isDirty={isFieldsTouched()}
          headerMenu={headerMenu}
          isValid={form
            .getFieldsError()
            .every((item) => item.errors.length > 0)}
        >
          <Content className="hotel-detail__content">
            <SCard title="Hotel links" className="scard-with-pictures">
              <HotelLinks mode={mode} values={values} form={form} />
            </SCard>
            <SCard
              title="Hotel descriptions"
              subtitle="All useful information on this hotel to help marketers develop their package(s). Can be completed later."
              className="scard-with-pictures"
            >
              <HotelDescriptions mode={mode} />
            </SCard>
          </Content>
        </SDetailLayout>
      )}
    </FormLegacyRenderProp>
  );
};

export default MarketingBrief;
