import { AnyAction } from '@reduxjs/toolkit';

import {
  ROOMS_FETCH_HOTEL_ROOMS_SUCCESS,
  ROOMS_FETCH_ROOMS_SUCCESS,
  ROOMS_FETCH_ROOM_SUCCESS,
  ROOMS_START_LOADING,
  ROOMS_STOP_LOADING,
} from 'app/redux/actions/rooms';
import {
  RoomListElement,
  createRoomListElement,
} from 'app/redux/models/RoomListElement/RoomListElement';
import { Room } from 'app/typings';

export type Rooms = {
  readonly loading: boolean;
  readonly room: Room | undefined;
  readonly hotelRooms: Array<any>;
  readonly rooms: Array<RoomListElement>;
};

export const initialState: Rooms = {
  loading: false,
  room: undefined,
  hotelRooms: [],
  rooms: [],
};

function rooms(state = initialState, action: AnyAction): Rooms {
  switch (action.type) {
    case ROOMS_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ROOMS_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case ROOMS_FETCH_ROOM_SUCCESS:
      return {
        ...state,
        room: action.result,
      };
    case ROOMS_FETCH_HOTEL_ROOMS_SUCCESS:
      return {
        ...state,
        hotelRooms: action.result,
      };
    case ROOMS_FETCH_ROOMS_SUCCESS:
      return {
        ...state,
        rooms: action.result.map(createRoomListElement),
      };

    default:
      return state;
  }
}

export default rooms;
