import { Form, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SDetailLayout } from 'app/components/StaycationUI';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useCollection } from 'app/hooks/data/collections/useCollections';
import {
  useCreateCollection,
  useUpdateCollection,
} from 'app/hooks/data/collections/useSaveCollections';
import type { CollectionForm as CollectionFormType } from 'app/typings/collection';
import { slugify } from 'app/utils/strings';

import './CollectionDetail.scss';
import CollectionForm from './CollectionForm/CollectionForm';
import CollectionSections from './CollectionSections/CollectionSections';

const { Content } = Layout;

type Props = {
  match: {
    params: {
      id?: string;
    };
  };
};

const DEFAULT_VALUES = {
  sections: [],
  color: '#C8C4C5',
};

const CollectionDetail = ({ match }: Props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const collectionId = match.params?.id;
  const parsedCollectionId = collectionId
    ? parseInt(collectionId, 10)
    : undefined;

  const { data: collection } = useCollection(parsedCollectionId);
  const { mutateAsync: createCollection } = useCreateCollection();
  const { mutateAsync: updateCollection } = useUpdateCollection();

  const isNew = collectionId === undefined;

  const [isCollectionWhen, setIsCollectionWhen] = useState<boolean | undefined>(
    false
  );

  useEffect(() => {
    setIsCollectionWhen(
      collection?.sections &&
        collection?.sections.some((s) => s.rule.dates?.length > 0)
    );
  }, [collection]);

  const onChange = (newValues: CollectionFormType) => {
    if (newValues.name && isNew) {
      form.setFieldsValue({
        slug: slugify(newValues.name),
      });
    }
  };

  const onSubmit = async (newValues: CollectionFormType) => {
    const sections = newValues.sections.map((value, index) => {
      return {
        ...value,
        sectionOrder: index + 1,
      };
    });

    if (parsedCollectionId === undefined) {
      await createCollection({ ...newValues, sections });
    } else {
      await updateCollection({
        ...newValues,
        sections,
        id: parsedCollectionId,
      });
    }

    history.push('/collections');
  };

  const initialValues =
    collection && collection.id === parsedCollectionId
      ? {
          ...collection,
          sections: collection.sections?.sort(
            (a, b) => a.sectionOrder - b.sectionOrder
          ),
        }
      : undefined;

  if (!initialValues && !isNew) {
    return null;
  }

  return (
    <Layout className="collection-layout">
      <FormLegacyRenderProp
        form={form}
        onFinish={onSubmit}
        onValuesChange={onChange}
        initialValues={initialValues || DEFAULT_VALUES}
      >
        {(values) => (
          <SDetailLayout
            title={initialValues?.name || 'Collection'}
            backRoute="/collections"
            mode="edit"
            isDirty
            onSave={form.submit}
            isValid={form
              .getFieldsError()
              .every((item) => item.errors.length > 0)}
          >
            <Content className="page-content">
              <CollectionForm
                isNew={isNew}
                isCollectionWhen={isCollectionWhen}
                setIsCollectionWhen={setIsCollectionWhen}
              />
              <Form.List
                name="sections"
                rules={[
                  {
                    validator(
                      rule,
                      value,
                      callback: (message?: string) => void
                    ) {
                      value.length < 1
                        ? callback('At least one section is required')
                        : callback();
                    },
                  },
                ]}
              >
                {(fields, { add, remove, move }, { errors }) => (
                  <>
                    <CollectionSections
                      fields={fields}
                      add={add}
                      remove={remove}
                      move={move}
                      values={values}
                      isCollectionWhen={isCollectionWhen}
                    />
                    <Form.ErrorList errors={errors} />
                  </>
                )}
              </Form.List>
            </Content>
          </SDetailLayout>
        )}
      </FormLegacyRenderProp>
    </Layout>
  );
};

export default CollectionDetail;
