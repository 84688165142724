export enum BigCities {
  Paris,
  Lyon,
  Marseille,
  Londres,
  Madrid,
  London,
}

export const isSubCityRequired = (city: string) => {
  if (city) {
    return Object.values(BigCities).includes(city.trim());
  }

  return false;
};
