import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { apiMutation } from 'app/utils/request/api';

const path = 'rooms/:roomId/publish' as const;

export function usePublishRoom() {
  return useMutation({
    mutationFn: async (roomId: string) =>
      apiMutation('PATCH', {
        path,
        params: { roomId },
      }) as Promise<void>,
    onSuccess: () => {
      message.success(`Room status updated`);
    },
    onError: () => {
      message.error('Error modifying room status');
    },
  });
}
