import type { InputNumberProps } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useMemo } from 'react';

import { useHotels } from 'app/hooks/data/hotels/useHotel';
import { useCountries } from 'app/hooks/data/useCountries';
import { parseInputNumber, parseNumber } from 'app/utils/typing';

import CurrencyContext from '../CurrencyContext/CurrencyContext';
import CurrencyInput from '../CurrencyInput/CurrencyInput';

const CurrencyWrapper = ({
  children,
  hotelId,
  hotel,
  countryId,
  currency,
  countrySlug,
}: Props) => {
  const { data: hotels } = useHotels();
  const { data: countries } = useCountries();

  const country: { currency?: string; locale?: string; slug?: string } =
    useMemo(() => {
      if (!countries) {
        return {};
      }

      if (countryId) {
        return countries.find((c) => c.id === countryId) || {};
      }

      if (countrySlug) {
        return countries.find((c) => c.slug === countrySlug) || {};
      }

      if (hotel) {
        const hotelCountryId = hotel.countryId || hotel.address?.countryId;

        return countries.find((c) => c.id === hotelCountryId) || {};
      }

      if (hotelId && hotels) {
        const hotelMatch = hotels.find((h) => h.id === hotelId);
        const hotelCountryId = hotelMatch?.countryId;

        return countries.find((c) => c.id === hotelCountryId) || {};
      }

      return {};
    }, [countries, countryId, countrySlug, hotel, hotelId, hotels]);

  if (!countrySlug) {
    countrySlug = country.slug;
  }

  if (!currency) {
    currency = country.currency;
  }

  const locale = country.locale;

  const currencyFormatter = useMemo(() => {
    if (!currency || !locale) {
      return () => '0';
    }

    return (price: any) => {
      const parsedPrice = parseNumber(price) || 0;
      const formatter = new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        // @ts-ignore
        notation: 'standard',
        minimumFractionDigits: Number.isInteger(price) ? 0 : 2,
        maximumFractionDigits: 2,
      });

      return formatter.format(parsedPrice);
    };
  }, [currency, locale]);

  const currencySymbol = useMemo(() => {
    if (!currency) {
      return '';
    }

    return getSymbolFromCurrency(currency) || '';
  }, [currency]);

  const currencyInput = (props: InputNumberProps) => (
    <CurrencyInput
      currency={currencySymbol}
      parser={parseInputNumber}
      {...props}
    />
  );

  return (
    <CurrencyContext.Provider
      value={{
        currencyFormatter,
        currencySymbol,
        currencyInput,
        currency,
        locale,
        countrySlug,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

type Props = {
  children: React.ReactNode;
  hotelId?: number | null;
  hotel?: any;
  countryId?: number;
  currency?: string;
  countrySlug?: string;
};

export default CurrencyWrapper;
