import { Divider, FormInstance } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { Fragment, ReactNode } from 'react';

import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';

type DynamicFormListProps = {
  handleSaveForm?: (values: unknown) => void;
  initialFormValue?: Store;
  form: FormInstance<unknown>;
  fieldList: ReactNode[];
};

export const DynamicFormList: React.FC<DynamicFormListProps> = ({
  handleSaveForm,
  initialFormValue,
  form,
  fieldList,
}: DynamicFormListProps) => {
  return (
    <FormLegacyRenderProp
      name="dynamicFormList"
      autoComplete="off"
      form={form}
      onFinish={handleSaveForm}
      initialValues={initialFormValue}
    >
      {() => (
        <>
          {fieldList.map((field, index) => (
            <Fragment key={index}>
              {field}
              {index < fieldList.length - 1 && (
                <Divider className="divider" plain>
                  <div className="divider-text">and / or</div>
                </Divider>
              )}
            </Fragment>
          ))}
        </>
      )}
    </FormLegacyRenderProp>
  );
};
