import { Menu } from 'antd';
import React, { useMemo } from 'react';

import { useClubs } from 'app/hooks/data/useClubs';
import { useCountries } from 'app/hooks/data/useCountries';

const COUNTRIES_FLAGS = {
  FR: '🇫🇷',
  GB: '🇬🇧',
};

type HeaderFooterProps = {
  menuKey: string;
  updateQueryParams: (queryParams: Record<string, string>) => void;
};

const HeaderFooter = ({ menuKey, updateQueryParams }: HeaderFooterProps) => {
  const { data: countries } = useCountries();
  const { data: clubs } = useClubs();

  const sortedCountries = useMemo(() => {
    if (!countries) {
      return null;
    }

    return countries.sort((countryA, countryB) => countryA.id - countryB.id);
  }, [countries]);

  const handleKey = (key: string) => {
    updateQueryParams({ menuKey: key });
  };

  return (
    <Menu
      mode="horizontal"
      onClick={({ key }) => handleKey(key)}
      selectedKeys={[menuKey || 'country-1']}
    >
      {sortedCountries &&
        sortedCountries.map((country) => (
          <Menu.Item key={`country-${country.id}`} title="country">
            {COUNTRIES_FLAGS[country.slug as 'FR' | 'GB']} &nbsp; {country.name}
          </Menu.Item>
        ))}
      {clubs &&
        clubs.map((club) => (
          <Menu.Item
            key={`club-${club.id}`}
            title="club"
            className="deprecated"
          >
            {club.name}
          </Menu.Item>
        ))}
    </Menu>
  );
};

export default HeaderFooter;
