import { CheckCircleOutlined, DisconnectOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback, useState } from 'react';

import Pic from 'app/components/commons/Pic';
import StaycationLogo from 'app/components/commons/icons/StaycationLogo';
import { useRoomsScrapping } from 'app/hooks/data/autoInventory/useScrapping';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { RoomScrapped, SyncStatus } from 'app/typings/autoInventory';
import { parseHtml } from 'app/utils/string';

import './AutoInventoryExplanation.scss';

type Props = {
  setInitConfiguration: (val: boolean) => void;
  onClose: () => void;
  setScrappingRooms: (val: RoomScrapped[]) => void;
  setSyncStatus: (syncStatus: SyncStatus) => void;
  syncStatus: SyncStatus;
  hotelId: number;
  setAlertModalOpen: (alertModalOpen: boolean) => void;
  setIsAlertForDisconnect: (isAlertForDisconnect: boolean) => void;
};

export const AutoInventoryExplanation = ({
  setInitConfiguration,
  onClose,
  hotelId,
  setScrappingRooms,
  setSyncStatus,
  syncStatus,
  setAlertModalOpen,
  setIsAlertForDisconnect,
}: Props) => {
  const { track } = useAmplitude();
  const [buttonLoading, setButtonLoading] = useState(false);

  const { getRoomsFromScrapper } = useRoomsScrapping();

  const startSync = useCallback(async () => {
    track('Click Start configuration');
    setButtonLoading(true);

    try {
      const fetchRooms = await getRoomsFromScrapper(hotelId);

      setSyncStatus(SyncStatus.Done);
      setScrappingRooms(
        fetchRooms.rooms.map((room) => ({
          ...room,
          roomName: parseHtml(room.roomName),
        })) ?? []
      );
      setInitConfiguration(false);
      setSyncStatus(SyncStatus.InProgress);
    } catch (error) {
      setSyncStatus(SyncStatus.Failed);
    }
  }, [
    getRoomsFromScrapper,
    hotelId,
    setInitConfiguration,
    setScrappingRooms,
    setSyncStatus,
    track,
  ]);

  const contactAdmin = useCallback(() => {
    track('Click Contact AM');
    setAlertModalOpen(true);
    setIsAlertForDisconnect(false);
  }, [setAlertModalOpen, setIsAlertForDisconnect, track]);

  const renderExplanation = () => {
    return (
      <>
        <div className="header">
          <div className="images">
            <div className="booking-icon">
              <Pic pictureId="static/icons/booking.png" className="icon" />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="10"
              viewBox="0 0 8 10"
              fill="none"
            >
              <path
                d="M0.000107502 1.53576C0.000107382 0.765962 0.833441 0.284836 1.50011 0.669737L7.50011 4.13384C8.16677 4.51874 8.16677 5.48099 7.50011 5.86589L1.50011 9.32999C0.833441 9.71489 0.000107834 9.23377 0.000107697 8.46396L0.000107502 1.53576Z"
                fill="#B3B3B3"
              />
            </svg>
            <div className="staycation-icon">
              <StaycationLogo />
            </div>
          </div>
          <div className="title-subtitle">
            <div className="head-title">Auto inventory</div>
            <div className="title">
              Simplify inventory management with{' '}
              <span className="bold">Booking.com</span>
            </div>
          </div>
        </div>
        <div className="explanation">
          <div className="argument-list">
            <div>The Booking.com connector will only give us access to:</div>
            <div className="list">
              <div>
                <CheckCircleOutlined /> Room categories published on Booking.com
              </div>
              <div>
                <CheckCircleOutlined /> Available stock for sale on Booking.com
              </div>
              <div>
                <CheckCircleOutlined /> OTA rate published on Booking.com
              </div>
            </div>
          </div>
          <div className="argument-list">
            <div>It will take less than 3 minutes:</div>
            <div className="list">
              Map your rooms, set your BAR, and define your discount.
            </div>
          </div>
        </div>
        <div className="start-sync">
          <Button loading={buttonLoading} onClick={startSync} type="primary">
            Start configuration
          </Button>
          {buttonLoading && (
            <p>This action could take a few seconds, please wait...</p>
          )}
        </div>
      </>
    );
  };

  const renderSyncResult = () => {
    return (
      <>
        <div className="header">
          <div className="icon">
            <DisconnectOutlined />
          </div>
          <div className="title-subtitle">
            <div className="title">Configuration failed...</div>
            <div className="subtitle">
              We were unable to fetch your Booking.com information, please
              contact your AM for more details.
            </div>
          </div>
        </div>
        <div className="buttons">
          <>
            <Button
              type="default"
              onClick={() => {
                track('Click Close');
                onClose();
              }}
            >
              Close
            </Button>
            <Button type="primary" onClick={contactAdmin}>
              Contact AM
            </Button>
          </>
        </div>
      </>
    );
  };

  return (
    <div className="auto-inventory-explanation">
      {syncStatus === SyncStatus.Idle || syncStatus === SyncStatus.InProgress
        ? renderExplanation()
        : renderSyncResult()}
    </div>
  );
};
