import { Button, Popconfirm } from 'antd';

import { StatusCell } from 'app/components/lists/cells';
import Line from 'app/components/pages/GiftCards/details/Line/Line';
import { type BookingSummary } from 'app/typings/adminBookings/bookings';
import { dateAndHour } from 'app/utils/dates';

type Props = {
  resendModalVisible: boolean;
  resendConfirmationMail: () => void;
  setResendModalVisible: (bool: boolean) => void;
  resendHotelModalVisible: boolean;
  resendHotelConfirmationMail: () => void;
  setResendHotelModalVisible: (bool: boolean) => void;
  bookingSummary: BookingSummary;
};

const Reservation = ({
  resendModalVisible,
  resendConfirmationMail,
  setResendModalVisible,
  resendHotelModalVisible,
  resendHotelConfirmationMail,
  setResendHotelModalVisible,
  bookingSummary,
}: Props) => {
  const {
    bookingId,
    checkin,
    checkout,
    comment,
    status,
    paymentAdvanced,
    code,
    customer: { email },
  } = bookingSummary;

  return (
    <div className="bloc">
      <div className="title-with-button2">
        <div className="label">Reservation</div>
        <div>
          <Popconfirm
            title={`You're going to resend the confirmation to ${email}`}
            onConfirm={resendConfirmationMail}
            okText="Send"
            cancelText="Cancel"
            placement="bottom"
            getPopupContainer={(trigger) =>
              trigger.parentElement as HTMLElement
            }
          >
            <Button
              className="button"
              onClick={() => setResendModalVisible(!resendModalVisible)}
            >
              Send confirmation
            </Button>
          </Popconfirm>
        </div>
        <div>
          <Popconfirm
            title={`You're going to resend the confirmation to the hotel`}
            onConfirm={resendHotelConfirmationMail}
            okText="Send"
            cancelText="Cancel"
            placement="bottom"
            getPopupContainer={(trigger) =>
              trigger.parentElement as HTMLElement
            }
          >
            <Button
              className="button"
              onClick={() =>
                setResendHotelModalVisible(!resendHotelModalVisible)
              }
            >
              Send confirmation to the hotel
            </Button>
          </Popconfirm>
        </div>
      </div>
      <div className="generic-content">
        <Line title="ID" value={bookingId} />
        <Line title="Code" value={code} />
        <Line title="Status" value={StatusCell(status)} />
        <Line
          title="Created at"
          value={dateAndHour(paymentAdvanced.createdAt)}
        />
        <Line title="Check in" value={dateAndHour(checkin)} />
        <Line title="Check out" value={dateAndHour(checkout)} />
        {!!comment && <Line title="Reason" value={comment} />}
      </div>
    </div>
  );
};

export default Reservation;
