import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const HeaderExtra = () => {
  return (
    <Link to="collections/new">
      <Button icon={<PlusOutlined />} type="primary">
        New
      </Button>
    </Link>
  );
};

export default HeaderExtra;
