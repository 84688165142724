import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { GestureListItem } from 'app/typings/CommercialGestures/CommercialGestureListItem';
import { apiRequest } from 'app/utils/request/api';
import { APISortOrder } from 'app/utils/sort';

const path = '/admin/commercial-gestures' as const;

export function useGetCommercialGestures(
  queryParams: GetCommercialGestureQueryParams = {}
) {
  const key = useMemo(() => {
    return [
      path,
      {
        queryParams,
      },
    ];
  }, [queryParams]);

  return useQuery({
    queryKey: [key],
    queryFn: () =>
      apiRequest({ path, queryParams }) as Promise<GestureListItem[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}

export type GetCommercialGestureQueryParams = {
  offset?: number;
  limit?: number;
  sortingColumn?: string;
  search?: string;
  sortingOrder?: APISortOrder;
};
