import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { HOURS_IN_DAY } from '@stdlib/constants-time';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Form,
  Modal,
  Radio,
  Select,
  Switch,
  TimePicker,
  Tooltip,
  message,
} from 'antd';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';

import AlertModal from 'app/components/commons/AlertModal/AlertModal';
import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import {
  AvailablityRestrictions,
  DEFAULT_CHECKIN,
  DEFAULT_CHECKOUT,
  EARLY_CHECKIN,
  LATE_CHECKOUT,
  WeekDays,
} from 'app/components/commons/Experience/Availability/utils';
import CustomInput from 'app/components/fields/Input';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useAddOn } from 'app/hooks/data/useAddOn';
import { IHotel } from 'app/typings';
import { IAddOnPayload } from 'app/typings/addOn';
import { parseInputNumber } from 'app/utils/typing';

import { Task } from '../TaskCenter/Types';

import './ExtraAddOn.scss';

const { Option } = Select;

const TIME_UNIT = ['hours', 'days'];

const WEEKDAYS = [
  { label: 'Monday', value: WeekDays.MONDAY },
  { label: 'Tuesday', value: WeekDays.TUESDAY },
  { label: 'Wednesday', value: WeekDays.WEDNESDAY },
  { label: 'Thursday', value: WeekDays.THURSDAY },
  { label: 'Friday', value: WeekDays.FRIDAY },
  { label: 'Saturday', value: WeekDays.SATURDAY },
  { label: 'Sunday', value: WeekDays.SUNDAY },
] as const satisfies readonly { value: WeekDays; label: string }[];

const RULES = [{ required: true, message: 'Required' }];
const ALARMCLOCK_EMOJIID = 841;
const ALARMCLOCK_ID = 15230;
const MAX_QUANTITY = 1;

type FormAddOn = {
  time?: string;
  price?: number;
  priceDescription: string;
  availableDays: WeekDays[];
  closedHoursBeforeCheckin?: number;
  availableDaysRadio:
    | AvailablityRestrictions.EveryDay
    | AvailablityRestrictions.SpecificDays;
  addOnLimitAvailabilityUnit: 'hours' | 'days';
};

type ExtraAddOnType = {
  hotel: IHotel;
  task: Task;
  isOpen?: boolean;
  onCompleteTask?: () => void;
};

export const ExtraAddOn = ({
  hotel,
  task,
  isOpen,
  onCompleteTask,
}: ExtraAddOnType) => {
  const [form] = Form.useForm();
  const formValues: FormAddOn = Form.useWatch([], form);

  const { clearOpenTask } = useContext(TaskCenterContext);
  const { currencyInput } = useContext(CurrencyContext);
  const queryClient = useQueryClient();

  const { mutateAsync: saveAddOn } = useAddOn(hotel.id);

  const isSpecificDay =
    form.getFieldValue('availableDaysRadio') ===
    AvailablityRestrictions.SpecificDays;

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isSuccessfullySave, setIsSuccessfullySave] = useState(false);
  const [alertClose, setAlertClose] = useState(true);

  const onFinish = async (values: FormAddOn) => {
    const weekDayArray = Object.values(WeekDays);

    const hasEveryDay =
      values.availableDays &&
      weekDayArray.every((day) => values.availableDays.includes(day));

    const formattedValues: IAddOnPayload = {
      name: task.taskName,
      categoryId:
        task.taskName === 'Early Check-in' ? EARLY_CHECKIN : LATE_CHECKOUT,
      emojiId: ALARMCLOCK_EMOJIID,
      coverPictureId: ALARMCLOCK_ID,
      time: (values.time && format(new Date(values.time), 'HH:mm')) ?? '',
      price: values.price ?? 0,
      priceDescription: values.priceDescription,
      availableDays:
        values.availableDaysRadio === 'everyDay' || hasEveryDay
          ? []
          : values.availableDays,
      availableDayLimits:
        task.taskName === 'Early Check-in' ? DEFAULT_CHECKIN : DEFAULT_CHECKOUT,
      closedHoursBeforeCheckin:
        values.closedHoursBeforeCheckin &&
        values.addOnLimitAvailabilityUnit === 'hours'
          ? values.closedHoursBeforeCheckin
          : (values.closedHoursBeforeCheckin ?? 0) * HOURS_IN_DAY,
      maxQuantity: MAX_QUANTITY,
    };

    try {
      await saveAddOn(formattedValues);

      setIsSuccessfullySave(true);
      setAlertClose(false);
    } catch (err) {
      message.error('Error saving add on');
    }

    onCompleteTask?.();

    clearOpenTask();
  };

  const handleClose = () => {
    clearOpenTask();
  };

  const handleSwitchChange = (day: string, checked: boolean) => {
    const selectedDays = form.getFieldValue('availableDays') || [];
    const newSelectedDays = checked
      ? [...selectedDays, day]
      : selectedDays.filter((d: string) => d !== day);

    form.setFieldsValue({ availableDays: newSelectedDays });
  };

  const lastMinuteRestrictionClassName = classNames(
    'bodySection extraSection',
    {
      disabled: !isSwitchOn,
    }
  );

  const initialValues: FormAddOn = useMemo(
    () => ({
      priceDescription: 'per stay',
      availableDays: [],
      availableDaysRadio: AvailablityRestrictions.EveryDay,
      addOnLimitAvailabilityUnit: 'hours',
    }),
    []
  );

  useEffect(() => {
    if (
      form.isFieldsTouched() &&
      formValues.price &&
      formValues.time &&
      form.getFieldsError().every((item) => item.errors.length === 0)
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [form, setCanSubmit, formValues]);

  const onCloseValidation = () => {
    setAlertClose(true);
    setIsSuccessfullySave(false);
    queryClient.invalidateQueries();
  };

  if (isSuccessfullySave && !alertClose) {
    return (
      <AlertModal
        type="success"
        icon={<CheckOutlined />}
        message={'Request successfully sent!'}
        description={
          'Your Account Manager will now review and publish your Add-on.'
        }
        closable
        onClose={onCloseValidation}
        isOpen={!alertClose}
      />
    );
  }

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      preserve={false}
      initialValues={initialValues}
    >
      {(values) => (
        <Modal
          width={684}
          className="extraAddOnModal"
          open={isOpen}
          title={
            <div className="extraAddOnModal__header">
              <h1>{task.title}</h1>
              <p> {task.description} </p>
            </div>
          }
          onCancel={handleClose}
          wrapClassName="extraAddOnModalContainer"
          centered
          footer={[
            <Button size="large" type="ghost" onClick={handleClose}>
              Close
            </Button>,
            <Button
              key="save"
              size="large"
              type="primary"
              htmlType="submit"
              onClick={form.submit}
              className="saveBtn"
              disabled={!canSubmit}
            >
              Save
            </Button>,
          ]}
          destroyOnClose
        >
          <div className="extraAddOnModal__body">
            <div className="bodySection timeCost">
              <div className="time">
                <div className="title">
                  Time of {task.taskName.toLocaleLowerCase()}
                </div>
                <Form.Item name={'time'} rules={RULES}>
                  <TimePicker
                    minuteStep={30}
                    showSecond={false}
                    format="HH:mm"
                  />
                </Form.Item>
              </div>
              <div className="cost">
                <div className="title">
                  Extra cost
                  <Tooltip
                    title={`Add the public price of your ${task.taskName}, when booked on site.`}
                    className="extra-cost-tooltip"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
                <div className="input">
                  <Form.Item
                    style={{ width: '100%' }}
                    name={'price'}
                    rules={RULES}
                  >
                    {currencyInput({
                      min: 0,
                      onWheel: (e) => e.currentTarget.blur(),
                    })}
                  </Form.Item>
                  <Form.Item name={'priceDescription'}>
                    <Select disabled={true}>
                      <Option key="per stay" value="per stay">
                        per stay
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="bodySection extraSection">
              <div className="titleSubtitle">
                <div className="title">Available days</div>
                <div className="subTitle">
                  Configure days when the experience is available for guests
                </div>
              </div>

              <div>
                <Form.Item name={'availableDaysRadio'} noStyle rules={RULES}>
                  <Radio.Group className="available_days__radios">
                    <Radio value={AvailablityRestrictions.EveryDay}>
                      Every day
                    </Radio>
                    <Radio value={AvailablityRestrictions.SpecificDays}>
                      Only on specific days
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              {isSpecificDay && (
                <div className="selectDaysAvailable">
                  <Form.Item name={'availableDays'} noStyle>
                    {WEEKDAYS.map((day) => {
                      const selectedDays =
                        form.getFieldValue('availableDays') || [];

                      return (
                        <Form.Item>
                          <div key={day.value} className="weekdaySwitch">
                            <Switch
                              className="switch"
                              checked={selectedDays.includes(day.value)}
                              onChange={(checked) =>
                                handleSwitchChange(day.value, checked)
                              }
                            />
                            <div className="day">{day.label}</div>
                          </div>
                        </Form.Item>
                      );
                    })}
                  </Form.Item>
                </div>
              )}
            </div>
            <div className={lastMinuteRestrictionClassName}>
              <div className="sectionTitle">
                <div className="titleSubtitle">
                  <div className="title">Last minute restriction</div>
                  <div className="subTitle">
                    Set a rule to remove last minute availability of your{' '}
                    {task.taskName} add-on
                  </div>
                </div>
                <Form.Item>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={setIsSwitchOn}
                    checked={isSwitchOn}
                  />
                </Form.Item>
              </div>

              <div className="sectionText">
                Remove availability
                <div className="input">
                  <Form.Item
                    name="closedHoursBeforeCheckin"
                    rules={isSwitchOn ? RULES : undefined}
                  >
                    <CustomInput
                      disabled={!isSwitchOn}
                      onWheel={(e) => e.currentTarget.blur()}
                      min={0}
                      parser={parseInputNumber}
                    />
                  </Form.Item>
                  <Form.Item name="addOnLimitAvailabilityUnit" rules={RULES}>
                    <Select defaultValue="hours" disabled={!isSwitchOn}>
                      {TIME_UNIT.map((timeUnit) => (
                        <Option key={timeUnit} value={timeUnit}>
                          {timeUnit}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                before check-in
              </div>
            </div>
          </div>
        </Modal>
      )}
    </FormLegacyRenderProp>
  );
};
