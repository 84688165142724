import { SortOrder as AntdSortOrder } from 'antd/lib/table/interface';

export type SortOrder = 'descend' | 'ascend' | 'none';
export type APISortOrder = 'DESC' | 'ASC' | '';

export const formatSortingForAPI = (
  sorting: SortOrder | undefined
): APISortOrder => {
  if (sorting === 'descend') {
    return 'DESC';
  }

  if (sorting === 'ascend') {
    return 'ASC';
  }

  return '';
};

export function isSortOrder(
  maybeSortOrder: string
): maybeSortOrder is SortOrder {
  return (
    maybeSortOrder === 'descend' ||
    maybeSortOrder === 'ascend' ||
    maybeSortOrder === 'none'
  );
}

export function getSortOrder(maybeSortOrder: string): SortOrder | undefined {
  if (isSortOrder(maybeSortOrder)) {
    return maybeSortOrder;
  }

  return undefined;
}

export function isValidSortOrder(sortOrder: SortOrder | undefined): boolean {
  return !!(sortOrder && sortOrder !== 'none');
}

export function convertToAntdSortOrder(
  sortOrder: SortOrder | undefined
): AntdSortOrder | undefined {
  return sortOrder === 'none' ? null : sortOrder;
}

export function convertFromAntdSortOrder(
  sortOrder: AntdSortOrder | undefined
): SortOrder {
  return sortOrder ? sortOrder : 'none';
}

export const sortPrimitive = (
  valueA: string | number | boolean,
  valueB: string | number | boolean,
  order: SortOrder
) => {
  if (valueB < valueA) {
    return order === 'descend' ? -1 : 1;
  }

  if (valueB > valueA) {
    return order === 'descend' ? 1 : -1;
  }

  return 0;
};

export const sortDate = (valueA: string, valueB: string, order: SortOrder) => {
  const valueOfA = new Date(valueA).valueOf();
  const valueOfB = new Date(valueB).valueOf();

  return order === 'descend' ? valueOfB - valueOfA : valueOfA - valueOfB;
};
