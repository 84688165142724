import { Form, FormItemProps } from 'antd';
import Select, { DefaultOptionType } from 'antd/lib/select';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { fetchPlacePredictions } from 'app/utils/googlePlace/googlePlace';
import {
  AllPlacesTypes,
  AutocompletePrediction,
} from 'app/utils/googlePlace/typings';

interface Props {
  minCharacters?: number;
  onSelectPlaceId?: (placeId: string) => void;
  placePredictionTypes: Array<AllPlacesTypes>;
  formItemProps?: FormItemProps;
  initialValue?: string;
  renderLocationLabel: (location: Location) => React.ReactNode;
  placeholder?: string;
}

export const LocationSelect = ({
  onSelectPlaceId,
  minCharacters,
  placePredictionTypes,
  formItemProps,
  initialValue,
  placeholder,
  renderLocationLabel,
}: Props) => {
  const [locations, setLocations] = useState<Array<Location>>([]);

  const setPlacePredictionsLocations = (
    predictions: Array<AutocompletePrediction>
  ): void => {
    if (!predictions) {
      return;
    }

    const formattedPredictions = predictions
      .map((pred) => ({
        id: pred.place_id,
        text: pred.structured_formatting.main_text,
        subtext: pred.structured_formatting.secondary_text,
      }))
      .filter((d) => d.id);

    setLocations(formattedPredictions);
  };

  const findPlaces = useCallback(
    (value: string) => {
      if ((minCharacters && value.length < minCharacters) || !value.length) {
        setLocations([]);

        return;
      }

      try {
        fetchPlacePredictions(
          {
            input: value,
            types: placePredictionTypes,
          },
          setPlacePredictionsLocations
        );
      } catch (e) {
        console.log('Error while fetching google maps locations: ', e);
      }
    },
    [minCharacters, placePredictionTypes]
  );

  useEffect(() => {
    if (!!initialValue) {
      findPlaces(initialValue);
    }
  }, [findPlaces, initialValue]);

  const options: DefaultOptionType[] = useMemo(
    () =>
      locations.map((location) => ({
        value: location.id,
        label: renderLocationLabel(location),
      })),
    [locations, renderLocationLabel]
  );

  return (
    <Form.Item {...formItemProps}>
      <Select
        showSearch
        placeholder={placeholder}
        showArrow={false}
        filterOption={false}
        onSearch={_.debounce(findPlaces, 200)}
        onChange={onSelectPlaceId}
        options={options}
        notFoundContent={
          minCharacters
            ? `No results. Please type at least ${minCharacters} characters.`
            : 'No results'
        }
      />
    </Form.Item>
  );
};

type Location = {
  id: number;
  text: string;
  subtext: string;
};
