import { differenceInCalendarDays } from 'date-fns';
import type { AnyAction } from 'redux';

import type { Inventory } from 'app/components/pages/Extranet/ExtranetInventory/Types';
import {
  computePackageValue,
  validateOpening,
} from 'app/components/pages/Extranet/ExtranetInventory/Utils';
import * as actions from 'app/redux/actions/hotelAdmin';
import type { Bill } from 'app/redux/models/Bill/Bill';
import { createBill } from 'app/redux/models/Bill/Bill';
import type { ExtranetBookingWithNbNight } from 'app/redux/models/ExtranetBooking/ExtranetBooking';
import type { HotelAdminContact } from 'app/redux/models/Hotel/Hotel';
import type { OpeningForm } from 'app/redux/models/RoomOpening/RoomOpening';
import { IHotel } from 'app/typings';
import { Booking } from 'app/typings/adminBookings/bookings';

export type HotelAdmin = {
  hotelId: number | undefined;
  hotels: IHotel[];
  inventory: Inventory | undefined;
  dirtyOpenings: Array<OpeningForm>;
  isLoading: boolean;
  isEditing: boolean;
  isValidationFailed: boolean;
  isViewValidationFailed: boolean;
  bookings: ExtranetBookingWithNbNight[];
  booking: Booking | undefined;
  bookingsCSV: { isLoading: boolean; data: any };
  feedbacks: Array<any>;
  hotelMarks: any;
  contacts: {
    isLoading: boolean;
    data: HotelAdminContact | undefined;
  };
  billsPage: {
    bills: Bill[];
    billPdfObject: {
      pdf: Blob;
      filename: string;
    } | null;
    isDownloading: boolean;
    isLoading: boolean;
  };
};

export const initialState: HotelAdmin = {
  hotelId: undefined,
  hotels: [],
  inventory: undefined,
  dirtyOpenings: [],
  isLoading: false,
  isEditing: false,
  isValidationFailed: false,
  isViewValidationFailed: false,
  bookings: [],
  booking: undefined,
  bookingsCSV: { isLoading: false, data: undefined },
  feedbacks: [],
  hotelMarks: undefined,
  billsPage: {
    bills: [],
    billPdfObject: null,
    isDownloading: false,
    isLoading: false,
  },
  contacts: {
    isLoading: false,
    data: undefined,
  },
};

function hotelAdmin(state = initialState, action: AnyAction): HotelAdmin {
  if (actions.updateHotelId.match(action)) {
    return {
      ...state,
      hotelId: action.payload.hotelId,
      dirtyOpenings: [],
      isEditing: false,
      isValidationFailed: false,
      isViewValidationFailed: false,
    };
  }

  if (actions.toggleIsEditing.match(action)) {
    return {
      ...state,
      isEditing: action.payload.value,
    };
  }

  if (actions.updateDirtyOpening.match(action)) {
    const { opening, isForced } = action.payload;
    const { inventory, dirtyOpenings, hotelId, hotels } = state;
    const hotel = hotels.find((h) => h.id === hotelId);
    const existingDirtyIndex = dirtyOpenings.findIndex(
      (o) => o.date === opening.date && o.roomId === opening.roomId
    );
    const packageValue = computePackageValue(opening, inventory, opening.date);
    const errors = validateOpening(
      { ...opening, price: packageValue },
      inventory?.pkg.dayPackage || false,
      hotel?.roomCount || 0
    );
    const newOpening = {
      ...opening,
      isForced,
      price: packageValue,
      errorStatus: errors,
      hasWarning: errors.length > 0,
      published: isForced ? opening.published : errors.length === 0,
    };

    if (existingDirtyIndex > -1) {
      return {
        ...state,
        dirtyOpenings: [
          ...dirtyOpenings.slice(0, existingDirtyIndex),
          {
            ...newOpening,
          },
          ...dirtyOpenings.slice(existingDirtyIndex + 1),
        ],
      };
    }

    return {
      ...state,
      dirtyOpenings: [
        ...dirtyOpenings,
        {
          ...newOpening,
        },
      ],
    };
  }

  if (actions.resetDirtyOpenings.match(action)) {
    return {
      ...state,
      dirtyOpenings: [],
    };
  }

  if (actions.setDirtyOpenings.match(action)) {
    return {
      ...state,
      dirtyOpenings: action.payload.openings,
    };
  }

  if (actions.fetchInventorySuccess.match(action)) {
    return {
      ...state,
      inventory: action.payload.result,
    };
  }

  if (actions.fetchInventoryError.match(action)) {
    return {
      ...state,
      inventory: undefined,
      isLoading: false,
    };
  }

  if (actions.startLoading.match(action)) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (actions.stopLoading.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (actions.toggleValidationFailed.match(action)) {
    return {
      ...state,
      isValidationFailed: action.payload.value,
    };
  }

  if (actions.toggleViewValidationFailed.match(action)) {
    return {
      ...state,
      isViewValidationFailed: action.payload.value,
    };
  }

  if (actions.fetchBookingsSuccess.match(action)) {
    const bookings = action.payload.result.map((booking) => ({
      ...booking,
      nbNight: booking.dayPackage
        ? 0
        : differenceInCalendarDays(
            new Date(booking.checkout),
            new Date(booking.checkin)
          ),
    }));

    return {
      ...state,
      bookings,
    };
  }

  if (actions.tryFetchBookingsCSV.match(action)) {
    return {
      ...state,
      bookingsCSV: { data: undefined, isLoading: true },
    };
  }

  if (actions.fetchBookingsCSVSuccess.match(action)) {
    return {
      ...state,
      bookingsCSV: { isLoading: false, data: action.payload.result },
    };
  }

  if (actions.fetchBookingSuccess.match(action)) {
    return {
      ...state,
      booking: action.payload.result,
    };
  }

  if (actions.fetchFeedbacksSuccess.match(action)) {
    return {
      ...state,
      feedbacks: action.payload.result,
    };
  }

  if (actions.fetchFeedbacksFailed.match(action)) {
    return {
      ...state,
      feedbacks: [],
      isLoading: false,
    };
  }

  if (actions.fetchMarksSuccess.match(action)) {
    return {
      ...state,
      hotelMarks: action.payload.result,
    };
  }

  if (actions.fetchHotelsSuccess.match(action)) {
    return {
      ...state,
      hotels: action.payload.result,
    };
  }

  if (actions.tryFetchBills.match(action)) {
    return {
      ...state,
      billsPage: {
        ...state.billsPage,
        isLoading: true,
      },
    };
  }

  if (actions.fetchBillsSuccess.match(action)) {
    const newBills = action.payload.bills.map(createBill);

    return {
      ...state,
      billsPage: {
        ...state.billsPage,
        isLoading: false,
        bills: newBills,
      },
    };
  }

  if (actions.fetchBillsFailure.match(action)) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (actions.downloadBill.match(action)) {
    return {
      ...state,
      billsPage: {
        ...state.billsPage,
        isDownloading: true,
      },
    };
  }

  if (actions.downloadBillSuccess.match(action)) {
    const { pdf, filename } = action.payload;

    return {
      ...state,
      billsPage: {
        ...state.billsPage,
        billPdfObject: {
          pdf,
          filename,
        },
      },
    };
  }

  if (actions.downloadBillFailure.match(action)) {
    return {
      ...state,
      billsPage: {
        ...state.billsPage,
        isDownloading: false,
      },
    };
  }

  if (actions.resetBillObjectPdf.match(action)) {
    return {
      ...state,
      billsPage: {
        ...state.billsPage,
        isDownloading: false,
        billPdfObject: initialState.billsPage.billPdfObject,
      },
    };
  }

  if (actions.fetchContacts.match(action)) {
    return {
      ...state,
      contacts: {
        isLoading: true,
        data: undefined,
      },
    };
  }

  if (
    actions.fetchContactsSuccess.match(action) ||
    actions.saveContactsSuccess.match(action)
  ) {
    return {
      ...state,
      contacts: {
        isLoading: false,
        data: { ...action.payload.res },
      },
    };
  }

  return state;
}

export default hotelAdmin;
