import { DeleteOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { FC, ReactNode, useState } from 'react';

import { SCard } from 'app/components/StaycationUI';

type SpaceItemProps = {
  title: string;
  expanded?: boolean;
  disabled?: boolean;
  onRemove?: () => void;
  children?: ReactNode;
};

export const SpaceItem: FC<SpaceItemProps> = ({
  title,
  expanded = false,
  disabled = false,
  onRemove,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  return (
    <SCard
      title={title}
      isExpanded={isExpanded}
      leftHeader={
        !disabled ? (
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            className="card-caret"
          >
            {isExpanded ? <DownOutlined /> : <RightOutlined />}
          </div>
        ) : undefined
      }
      rightHeader={
        onRemove && !disabled ? (
          <div onClick={onRemove} className="card-delete">
            <DeleteOutlined />
          </div>
        ) : undefined
      }
    >
      {children}
    </SCard>
  );
};

export default SpaceItem;
