import { Form, FormListFieldData, Select } from 'antd';

import { HeaderWrapper } from 'app/components/pages/Edito/commons/Detail/HeaderWrapper/HeaderWrapper';
import { requiredRule } from 'app/utils/rules';

import './PaxConfigurationForm.scss';

type Props = {
  field: FormListFieldData;
};

export const PaxConfigurationForm = ({ field }: Props) => {
  const { name } = field;

  return (
    <div className="paxConfigurationFormContainer">
      <HeaderWrapper isBold={false} title="Adults">
        <Form.Item
          name={[name, 'searchFilters', 'pax', 'adults']}
          rules={[requiredRule]}
        >
          <Select
            options={[
              { label: 1, value: 1 },
              { label: 2, value: 2 },
            ]}
          />
        </Form.Item>
      </HeaderWrapper>
      <HeaderWrapper isBold={false} title="Children">
        <Form.Item
          name={[name, 'searchFilters', 'pax', 'children']}
          rules={[requiredRule]}
        >
          <Select
            options={[
              { label: 0, value: 0 },
              { label: 1, value: 1 },
              { label: 2, value: 2 },
            ]}
          />
        </Form.Item>
      </HeaderWrapper>
      <HeaderWrapper isBold={false} title="Babies">
        <Form.Item
          name={[name, 'searchFilters', 'pax', 'babies']}
          rules={[requiredRule]}
        >
          <Select
            options={[
              { label: 0, value: 0 },
              { label: 1, value: 1 },
              { label: 2, value: 2 },
            ]}
          />
        </Form.Item>
      </HeaderWrapper>
    </div>
  );
};
