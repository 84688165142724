import { FormInstance } from 'antd';

import SCard from 'app/components/StaycationUI/SCard/SCard';
import HotelDescriptions from 'app/components/commons/Hotel/MarketingBrief/HotelDescriptions/HotelDescriptions';
import HotelLinks from 'app/components/commons/Hotel/MarketingBrief/HotelLinks/HotelLinks';

const FourthStep = ({ prefix, form }: Props) => (
  <>
    <SCard title="Hotel links" className="third-step">
      <HotelLinks prefix={prefix} form={form} />
    </SCard>
    <SCard
      title="Hotel descriptions"
      subtitle="All useful information on this hotel to help marketers develop their package(s). Can be completed later."
      className="scard-with-pictures"
    >
      <HotelDescriptions prefix={prefix} />
    </SCard>
  </>
);

type Props = {
  prefix: string;
  form: FormInstance<unknown>;
};

export default FourthStep;
