import { Form, FormListFieldData, Select } from 'antd';

import { HeaderWrapper } from 'app/components/pages/Edito/commons/Detail/HeaderWrapper/HeaderWrapper';
import {
  BABY_SITTER_TAG_ID,
  PARKING_TAG_ID,
  PETS_TAG_ID,
} from 'app/utils/filters/filters';
import { filterLabelOption } from 'app/utils/form';
import { requiredRule } from 'app/utils/rules';

type Props = {
  field: FormListFieldData;
};

const options: { label: string; value: string }[] = [
  {
    label: 'Pets',
    value: `${PETS_TAG_ID}`,
  },
  {
    label: 'Parking',
    value: `${PARKING_TAG_ID}`,
  },
  {
    label: 'Baby-sitter',
    value: `${BABY_SITTER_TAG_ID}`,
  },
  {
    label: 'PRM',
    value: 'pmr',
  },
];

export const AccessibilityForm = ({ field }: Props) => {
  const { name } = field;

  return (
    <HeaderWrapper isBold={false} title={`Accessibility filter`}>
      <Form.Item
        name={[name, 'searchFilters', 'accessibility']}
        rules={[requiredRule]}
      >
        <Select
          options={options}
          mode="multiple"
          allowClear
          filterOption={filterLabelOption}
          placeholder={`Search a filter for accessibility`}
        />
      </Form.Item>
    </HeaderWrapper>
  );
};
