import './FormLink.scss';

type Props = {
  width?: number;
};

export const FormLink = ({ width = 24 }: Props) => {
  return (
    <div
      className="formlink"
      style={{
        width,
        left: -width,
      }}
    />
  );
};
