import { InfoCircleOutlined } from '@ant-design/icons';
import { Empty, Form, Radio } from 'antd';
import { ValidateFields } from 'rc-field-form/lib/interface';
import { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { OpeningHours } from 'app/components/commons/Hotel/Types';
import TimeRangeSection, {
  PresetOpeningHours,
} from 'app/components/commons/TimeRangeSection/TimeRangeSection';
import { Option, Select } from 'app/components/fields/Select';
import { useAppSelector } from 'app/redux/hooks';
import type { Experience } from 'app/redux/models/Experience/Experience';
import type { ExperienceCategory } from 'app/redux/models/ExperienceCategory/ExperienceCategory';
import { selectSpaceTypes } from 'app/redux/selectors/conf';
import { Space } from 'app/typings/hotels/Space/Space';

import { parseOpeningHoursFromAPI } from '../../TimeRangeSection/utils';
import CustomSwitch from '../CustomSwitch/CustomSwitch';

import SpacesSelect from './SpacesSelect/SpacesSelect';

const RULES = [{ required: true, message: 'Required' }];

const PracticalInfo = ({
  mode = 'edit',
  values,
  setFieldsValue,
  hotelSpaces = [],
  category,
  validateFields,
  isBuilder,
}: Props) => {
  const { currencyFormatter, currencyInput } = useContext(CurrencyContext);
  const spaceTypes = useAppSelector(selectSpaceTypes);

  if (
    !category.spaces &&
    !category.accessMode &&
    category.openingHours === 'NOT_APPLICABLE' &&
    !category.roomService &&
    isBuilder
  ) {
    return <Empty />;
  }

  const filterSpaceOpeningHours = (hs: Space) =>
    hs.id && values.spaceIds.includes(hs.id);
  const filterUndefined = (o: {
    name: string | undefined;
    openingHours: OpeningHours | undefined;
  }): o is PresetOpeningHours =>
    o.name !== undefined && o.openingHours !== undefined;
  const getName = (hs: Space) =>
    hs.name && hs.name?.trim.length > 0
      ? hs.name
      : spaceTypes.find((s) => s.id === hs.spaceTypeId)?.name;

  const presetOpeningHours = hotelSpaces
    .filter(filterSpaceOpeningHours)
    .map((hs) => ({
      name: getName(hs),
      openingHours: parseOpeningHoursFromAPI(hs.openingHours),
    }))
    .filter(filterUndefined);

  return (
    <>
      {!!category.spaces && (mode !== 'view' || !!values.spaceIds?.length) && (
        <div className="input-row">
          {hotelSpaces && (
            <Form.Item label="Related space(s)" name="spaceIds" rules={RULES}>
              <SpacesSelect
                hotelSpaces={hotelSpaces}
                spaceTypes={spaceTypes}
                mode={mode}
                value={values.spaceIds}
                setFieldsValue={setFieldsValue}
                notApplicableOption
              />
            </Form.Item>
          )}
          {mode !== 'view' && (
            <div className="description">
              <InfoCircleOutlined />
              <div>
                Link this experience to the space(s) where it takes place
              </div>
            </div>
          )}
        </div>
      )}
      {!!category.accessMode && (
        <div className="input-row">
          <Form.Item label="Access mode" name="accessMode" rules={RULES}>
            {mode !== 'view' ? (
              <Select showSearch>
                <Option key="walk-in" value="walk-in">
                  walk-in
                </Option>
                <Option key="book on site" value="book on site">
                  book on site
                </Option>
                <Option key="book ahead" value="book ahead">
                  book ahead
                </Option>
              </Select>
            ) : (
              <div>{values.accessMode}</div>
            )}
          </Form.Item>
        </div>
      )}
      {category.openingHours !== 'NOT_APPLICABLE' && (
        <div className="opening-hours">
          <TimeRangeSection
            mode={mode}
            openingHours={values.openingHours}
            setFieldsValue={(openingHours) => {
              setFieldsValue({
                ...values,
                openingHours,
              });
            }}
            nonApplicableOption={category.openingHours === 'OPTIONAL'}
            validateFields={validateFields}
            presetHours={presetOpeningHours}
          />
        </div>
      )}
      {!!category.roomService && (
        <div className="input-row">
          <Form.Item label="Room service" name="roomServiceAvailable">
            <CustomSwitch mode={mode} />
          </Form.Item>
          {!!values.roomServiceAvailable && (
            <>
              <Form.Item label=" " name="roomServiceIncluded">
                <Radio.Group disabled={mode === 'view'}>
                  <Radio value>Free of charge</Radio>
                  <Radio value={false}>Applicable fees</Radio>
                </Radio.Group>
              </Form.Item>
              {!values.roomServiceIncluded && (
                <Form.Item label=" " name="roomServicePrice">
                  {mode !== 'view' ? (
                    currencyInput({ min: 0 })
                  ) : (
                    <div>{currencyFormatter(values.roomServicePrice || 0)}</div>
                  )}
                </Form.Item>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

type Props = {
  mode?: string;
  values: Experience;
  setFieldsValue: (newValues: Partial<Experience>) => void;
  hotelSpaces?: Space[];
  category: ExperienceCategory;
  validateFields: ValidateFields;
  isBuilder?: boolean;
};

export default PracticalInfo;
