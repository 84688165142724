import SCard from 'app/components/StaycationUI/SCard/SCard';
import ChannelManagerSelect from 'app/components/commons/Hotel/ChannelManagerSelect/ChannelManagerSelect';
import NotifEmails from 'app/components/commons/Hotel/NotifEmails/NotifEmails';
import { NewHotel } from 'app/typings/hotels/NewHotel/NewHotel';

type Props = {
  onValuesChange: (_: any, values: Partial<NewHotel>) => void;
  values?: any;
};

const SecondStep = ({ onValuesChange, values }: Props) => {
  return (
    <SCard
      title="Extranet access & notifications"
      subtitle="Main contact for the partnership. Add all the emails you want to give access to the extranet to update inventory and hotel information. They will also receive notifications about reservations, inventory etc"
      className="second-step"
    >
      <ChannelManagerSelect onValuesChange={onValuesChange} values={values} />
      <NotifEmails />
    </SCard>
  );
};

export default SecondStep;
