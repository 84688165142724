import { Switch } from 'antd';
import { FC } from 'react';

import { SCard } from 'app/components/StaycationUI';
import {
  HotelServiceForm,
  IHotelsServicesType,
  Mode,
} from 'app/components/commons/Hotel/Types';

import OpeningHours from './OpeningHours/OpeningHours';
import PriceKinds from './PriceKinds/PriceKinds';
import ServiceKinds from './ServiceKinds/ServiceKinds';

const Service: FC<Props> = ({
  services,
  hotelsServicesType,
  mode,
  add,
  update,
  remove,
  form,
}) => {
  const service = services.find(
    (obj) => obj.serviceTypeId === hotelsServicesType.id && !obj.isDeleted
  );

  if (!service && mode === 'view') {
    return null;
  }

  const isExpanded =
    service &&
    !service.isDeleted &&
    (hotelsServicesType.openingHoursRequired ||
      hotelsServicesType.priceRequired ||
      hotelsServicesType.serviceKinds);

  return (
    <SCard
      title={hotelsServicesType.name}
      isExpanded={!!isExpanded}
      leftHeader={
        mode !== 'view' ? (
          <Switch
            size="small"
            checked={!!service}
            onChange={(checked) => {
              if (checked) {
                add({
                  serviceTypeId: hotelsServicesType.id,
                });
              } else if (service) {
                remove(service);
              }
            }}
          />
        ) : undefined
      }
      key={hotelsServicesType.id}
    >
      {!!service && (
        <>
          <OpeningHours
            service={service}
            hotelsServicesType={hotelsServicesType}
            mode={mode}
            form={form}
          />
          <ServiceKinds
            service={service}
            hotelsServicesType={hotelsServicesType}
            update={update}
            mode={mode}
          />
          <PriceKinds
            service={service}
            hotelsServicesType={hotelsServicesType}
            update={update}
            mode={mode}
          />
        </>
      )}
    </SCard>
  );
};

type Props = {
  services: HotelServiceForm[];
  hotelsServicesType: IHotelsServicesType;
  mode?: Mode;
  add: (service: HotelServiceForm) => void;
  update: (service: HotelServiceForm) => void;
  remove: (service: HotelServiceForm) => void;
  form: any;
};

export default Service;
