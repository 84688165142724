import { useMutation } from '@tanstack/react-query';

import { IAutoInventoryPayload } from 'app/typings/autoInventory';
import { apiMutation } from 'app/utils/request/api';

const path = '/hotel-admin/:hotelId/stock-scrapper' as const;

export function useSave(hotelId: number) {
  return useMutation({
    mutationFn: async (payload: IAutoInventoryPayload) =>
      apiMutation(
        'POST',
        {
          path,
          params: { hotelId: hotelId.toString() },
        },
        payload
      ) as Promise<void>,
    onSuccess: (_, payload) => {},
  });
}
