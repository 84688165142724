import { Modal } from 'antd';
import { differenceInCalendarDays } from 'date-fns';
import formatDate from 'date-fns/format';
import { useContext } from 'react';
import { Col, Row } from 'smooth-ui';
import styled from 'styled-components';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { useBookingDetail } from 'app/hooks/data/bookings/useBookings';
import { formatPaxAsString } from 'app/utils/pax';

import Info from '../Info';

import BookingPrice, { BookingPriceBold, BookingPriceLG } from './BookingPrice';
import { SectionTitleNoMargin, SectionTitleSM } from './SectionTitle';

const Card = styled.div`
  border-radius: 4px;
`;

const Container = Card.extend`
  display: flex;
  flex-direction: column;
  padding: 24px;
  color: #101010;
`;

const SubTitle = styled.div`
  font-size: 14px;
  margin-top: 8px;
`;

const SecondaryText = styled.div`
  font-size: 14px;
  color: #a2a2a2;
`;

const BorderTopTr = styled.tr`
  border-top: solid 1px #e0e0e0;
`;

const Td = styled.td`
  text-align: left;
`;

const TdExtra = styled.td`
  text-align: left;
  font-size: 16px;
`;

const RightAlignTd = styled.td`
  vertical-align: top;
  text-align: right;
  width: 56;
  color: #2d2d2d;
`;

const RightAlignTdCancelled = styled.td`
  vertical-align: top;
  text-align: right;
  width: 56;
  color: #911034;
`;

const UniqTh = styled.th`
  padding: 15px 0;
  text-align: left;
`;

const UniqThRightAlign = styled.th`
  padding: 15px 0;
  text-align: right;
`;

const Th = styled.th`
  text-align: left;
`;

const BookingDetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr:last-child td {
    padding-bottom: 16px;
  }

  td:nth-last-child(-n + 2) {
    padding-bottom: 16px;
  }

  td {
    padding: 0;
  }
`;

const BookingMessage = styled.div`
  background-color: rgba(35, 122, 114, 0.05);
  border-radius: 8px;
  font-size: 15px;
  color: #247a74;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  margin: 16px 0 5px;
  font-size: 16px;
`;

const DEFAULT_ADULTS_PAX = 2;
const DEFAULT_SOLO_ADULT = 1;

type BookingDetails = {
  onClose: () => void;
  bookingCode?: string;
};

const Index = ({ onClose, bookingCode }: BookingDetails) => {
  const { currencyFormatter } = useContext(CurrencyContext);

  const { data: booking } = useBookingDetail(bookingCode);

  const nbNight =
    !!booking && booking.room.category !== 'Day Experience'
      ? differenceInCalendarDays(
          new Date(booking.checkout),
          new Date(booking.checkin)
        )
      : 1;

  if (booking === undefined) {
    return null;
  }

  const payedAt = ` ${formatDate(
    new Date(booking.payedAt),
    'dd/MM/yyy '
  )} at ${formatDate(new Date(booking.payedAt), 'HH:mm')}`;
  const checkin = `${formatDate(
    new Date(booking.checkin),
    'dd/MM'
  )}, ${formatDate(new Date(booking.checkin), 'HH:mm')}`;
  const checkout = `${formatDate(
    new Date(booking.checkout),
    'dd/MM'
  )}, ${formatDate(new Date(booking.checkout), 'HH:mm')}`;
  const extras = booking.experiences.filter(
    (exp: any) => !exp.included && exp.quantity > 0 && exp.hotelId
  );
  const staycationExperiences = booking.experiences.filter(
    (exp: any) => !exp.hotelId
  );
  const includedExperiences = booking.experiences
    .filter((exp: any) => exp.included && exp.hotelId)
    .map((exp: any) => exp.category.name)
    .join(' + ');

  const bedRoomPriceForAllNights =
    (booking.room.bedPricePerNight ?? 0) * nbNight;

  const singleOrExtraGuest =
    booking.pax.adults === DEFAULT_ADULTS_PAX
      ? 0
      : booking.pax.adults > DEFAULT_ADULTS_PAX
      ? booking.room.additionalAdultPricePerNight ?? 0
      : booking.room.singleCustomerDiscountPerNight ?? 0;

  const paxForAllNights =
    singleOrExtraGuest * nbNight * (booking.pax.adults - DEFAULT_ADULTS_PAX);

  const newDiscountprice =
    booking.room.discountPrice - bedRoomPriceForAllNights - paxForAllNights;

  const renderCancellationConditions = () => {
    if (booking.pkg.dayPackage) {
      return <span>Free cancellation up to 24 hours in advance</span>;
    }

    return <span>No cancellations or refunds</span>;
  };

  return (
    <Modal open={!!bookingCode} onCancel={onClose} footer={null}>
      <Container>
        <SubTitle style={{ paddingRight: 50 }}>
          <SectionTitleNoMargin>
            {[
              booking.customer.gender,
              booking.customer.firstName,
              booking.customer.lastName,
            ].join(' ')}
          </SectionTitleNoMargin>
          <SubTitle>
            <span>Booking date :</span>
            {payedAt}
          </SubTitle>
        </SubTitle>
        <Row style={{ marginTop: 26 }}>
          <Col xs={12} md={4}>
            <SecondaryText>
              <span>Guests</span>
            </SecondaryText>
            <span>{formatPaxAsString(booking.pax)}</span>
          </Col>
          <Col xs={12} md={4}>
            <SecondaryText>
              <span>Check in</span>
            </SecondaryText>
            {checkin}
          </Col>
          <Col xs={12} md={4}>
            <SecondaryText>
              <span>Check out</span>
            </SecondaryText>
            {checkout}
          </Col>
        </Row>

        <Row style={{ marginTop: 16, marginBottom: 13 }}>
          <Col xs={12} md={4}>
            <SecondaryText>
              <span>Booking ID</span>
            </SecondaryText>
            {booking.code}
          </Col>
          <Col xs={12} md={4}>
            <SecondaryText>
              <span>Phone number</span>
            </SecondaryText>
            {booking.customer.phoneNumber}
          </Col>
        </Row>

        <BookingDetailTable>
          <tbody>
            <tr>
              <Th colSpan={2}>
                <SectionTitleSM>
                  <span>Room</span>
                </SectionTitleSM>
              </Th>
            </tr>
            <tr>
              <TdExtra>
                {booking.room.name ? booking.room.name : booking.room.category}
              </TdExtra>
              <RightAlignTd>
                <BookingPrice>
                  {currencyFormatter(newDiscountprice || 0)}
                </BookingPrice>
              </RightAlignTd>
            </tr>
          </tbody>
        </BookingDetailTable>

        {(booking.room.additionalAdultPricePerNight &&
          booking.pax.adults > DEFAULT_ADULTS_PAX) ||
        (booking.room.singleCustomerDiscountPerNight &&
          booking.pax.adults === DEFAULT_SOLO_ADULT) ||
        (booking.room.bedPricePerNight && booking.room.bedPricePerNight > 0) ? (
          <BookingDetailTable>
            <tbody>
              <BorderTopTr>
                <Th colSpan={2}>
                  <SectionTitleSM>
                    <span>PAX Options</span>
                  </SectionTitleSM>
                </Th>
              </BorderTopTr>
              <>
                {booking.room.bedPricePerNight &&
                booking.room.bedPricePerNight > 0 ? (
                  <tr>
                    <TdExtra>Fee for additional bed in room</TdExtra>
                    <RightAlignTd>
                      <BookingPrice>
                        {currencyFormatter(
                          booking.room.bedPricePerNight * nbNight || 0
                        )}
                      </BookingPrice>
                    </RightAlignTd>
                  </tr>
                ) : (
                  <></>
                )}
                {booking.room.additionalAdultPricePerNight &&
                  booking.pax.adults > DEFAULT_ADULTS_PAX && (
                    <tr>
                      <TdExtra>
                        {booking.pax.adults - DEFAULT_ADULTS_PAX} x Package for
                        additional guest (
                        {currencyFormatter(
                          booking.room.additionalAdultPricePerNight * nbNight ||
                            0
                        )}
                        )
                      </TdExtra>
                      <RightAlignTd>
                        <BookingPrice>
                          {currencyFormatter(
                            booking.room.additionalAdultPricePerNight *
                              nbNight *
                              (booking.pax.adults - DEFAULT_ADULTS_PAX) || 0
                          )}
                        </BookingPrice>
                      </RightAlignTd>
                    </tr>
                  )}
              </>

              {booking.room.singleCustomerDiscountPerNight &&
                booking.pax.adults === DEFAULT_SOLO_ADULT && (
                  <tr>
                    <TdExtra>Price discount for single guest</TdExtra>
                    <RightAlignTd>
                      <BookingPrice>
                        {currencyFormatter(
                          -1 *
                            (booking.room.singleCustomerDiscountPerNight *
                              nbNight) || 0
                        )}
                      </BookingPrice>
                    </RightAlignTd>
                  </tr>
                )}
            </tbody>
          </BookingDetailTable>
        ) : (
          <></>
        )}

        {(extras.length > 0 || staycationExperiences.length > 0) && (
          <BookingDetailTable>
            <tbody>
              <BorderTopTr>
                <Th colSpan={2}>
                  <SectionTitleSM>
                    <span>Extras</span>
                  </SectionTitleSM>
                </Th>
              </BorderTopTr>
              {staycationExperiences.map((bExp: any) => (
                <tr>
                  <TdExtra>{`${bExp.included ? '1' : bExp.quantity} x ${
                    bExp.category.name
                  }`}</TdExtra>
                  <RightAlignTd>
                    <BookingPrice>Included</BookingPrice>
                  </RightAlignTd>
                </tr>
              ))}
              {extras.map((bExp: any) => (
                <tr>
                  <TdExtra>
                    {`${bExp.quantity} x ${bExp.category.name}`}
                  </TdExtra>
                  <RightAlignTd>
                    <BookingPrice>
                      {currencyFormatter(bExp.price || 0)}
                    </BookingPrice>
                  </RightAlignTd>
                </tr>
              ))}
            </tbody>
          </BookingDetailTable>
        )}

        <BookingDetailTable>
          <tbody>
            <BorderTopTr>
              <Th colSpan={2}>
                <SectionTitleSM>
                  <span>Package reminder</span>
                </SectionTitleSM>
              </Th>
            </BorderTopTr>
            <tr>
              <TdExtra>{includedExperiences}</TdExtra>
              <RightAlignTd>
                <BookingPrice>Included</BookingPrice>
              </RightAlignTd>
            </tr>
          </tbody>
        </BookingDetailTable>

        <BookingDetailTable>
          <tbody>
            <BorderTopTr>
              <UniqTh>
                <BookingPriceBold>
                  <span>Total paid incl. VAT</span>
                </BookingPriceBold>
              </UniqTh>
              <UniqThRightAlign>
                <BookingPriceBold>
                  {currencyFormatter(booking.totalAmount || 0)}
                </BookingPriceBold>
              </UniqThRightAlign>
            </BorderTopTr>
            <BorderTopTr>
              <Td style={{ padding: '16px 0 8px' }}>
                <BookingPriceLG>Net commission amount incl. VAT</BookingPriceLG>
              </Td>
              <RightAlignTd style={{ padding: '16px 0 8px' }}>
                <BookingPriceLG>
                  {currencyFormatter(booking.hotelAmount)}
                </BookingPriceLG>
              </RightAlignTd>
            </BorderTopTr>
            <BorderTopTr>
              <Td>
                <SectionTitleSM>
                  <span>Payment</span>
                </SectionTitleSM>
              </Td>
              {booking.status !== 'cancelled' ? (
                <RightAlignTd style={{ padding: '16px 0 8px' }}>
                  {booking.giftCard && (
                    <span>
                      Paid with gift card
                      <br />
                    </span>
                  )}
                  <span>
                    {
                      booking.cardInfo
                        ? `Paid with ${booking.cardInfo.brand} ****${booking.cardInfo.last4}`
                        : booking.giftCard
                        ? ''
                        : 'No information on payment' // i18nMark('No information on payment')
                    }
                  </span>
                </RightAlignTd>
              ) : (
                <RightAlignTdCancelled style={{ padding: '16px 0 8px' }}>
                  <span>Booking cancelled</span>
                </RightAlignTdCancelled>
              )}
            </BorderTopTr>
            <tr>
              <RightAlignTd colSpan={2} style={{ padding: 0 }}>
                <SecondaryText>{renderCancellationConditions()}</SecondaryText>
              </RightAlignTd>
            </tr>
          </tbody>
        </BookingDetailTable>

        <BookingMessage>
          <Info width="32" height="32" style={{ marginRight: 16 }} />
          <span>Please save an imprint of the guest's credit card.</span>
        </BookingMessage>
      </Container>
    </Modal>
  );
};

export default Index;
