import { Checkbox, Divider, Form, Radio, RadioChangeEvent, Switch } from 'antd';
import classNames from 'classnames';

import MultipleDatePicker from 'app/components/pages/Collections/CollectionDetail/CollectionSections/MultipleDatePicker/MultipleDatePicker';
import type { Experience } from 'app/redux/models/Experience/Experience';

import DateRangeSection from '../../DateRangeSection/DateRangeSection';

import './Availability.scss';
import {
  AvailableOn,
  AvailablityRestrictions,
  EARLY_CHECKIN,
  LATE_CHECKOUT,
  WeekDays,
  getDisableLimitation,
} from './utils';

const AVAILABLE_ON = [
  {
    label: 'Day of check-in',
    value: AvailableOn.CHECK_IN,
  },
  { label: 'Mid-stay', value: AvailableOn.MID_STAY },
  { label: 'Day of check-out', value: AvailableOn.CHECK_OUT },
] as const satisfies readonly { value: AvailableOn; label: string }[];

const WEEKDAYS = [
  { label: 'Monday', value: WeekDays.MONDAY },
  { label: 'Tuesday', value: WeekDays.TUESDAY },
  { label: 'Wednesday', value: WeekDays.WEDNESDAY },
  { label: 'Thursday', value: WeekDays.THURSDAY },
  { label: 'Friday', value: WeekDays.FRIDAY },
  { label: 'Saturday', value: WeekDays.SATURDAY },
  { label: 'Sunday', value: WeekDays.SUNDAY },
] as const satisfies readonly { value: WeekDays; label: string }[];

type Props = {
  mode?: 'edit' | 'view';
  isBuilder?: boolean;
  values: Experience;
};

export const Availability = ({ mode = 'edit', values }: Props) => {
  const form = Form.useFormInstance();
  const isSpecificDay =
    form.getFieldValue('availableDaysRadio') ===
    AvailablityRestrictions.SpecificDays;
  const isSpecificDates =
    form.getFieldValue('availableDaysRadio') ===
    AvailablityRestrictions.SpecificDates;
  const isViewMode = mode === 'view';
  const isCheckInOrCheckOutExp =
    values.categoryId === EARLY_CHECKIN || values.categoryId === LATE_CHECKOUT;

  const availableDayLimits = AVAILABLE_ON.map((item) => ({
    ...item,
    disabled: getDisableLimitation(values.categoryId, item.value),
  }));

  const handleSwitchChange = (day: string, checked: boolean) => {
    const selectedDays = form.getFieldValue('availableDays') || [];
    const newSelectedDays = checked
      ? [...selectedDays, day]
      : selectedDays.filter((d: string) => d !== day);

    form.setFieldsValue({ availableDays: newSelectedDays });
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({
      availableDays: [],
      availableDates: [],
    });
  };

  return (
    <div className="availability_container">
      <div className="available_days">
        <div className="title">Available days</div>
        <div>
          <Form.Item name={'availableDaysRadio'} noStyle>
            <Radio.Group
              onChange={handleRadioChange}
              className="available_days__radios"
              disabled={isViewMode}
            >
              <Radio value={AvailablityRestrictions.EveryDay}>Every day</Radio>
              <Radio value={AvailablityRestrictions.SpecificDays}>
                Only on specific days
              </Radio>
              <Radio value={AvailablityRestrictions.SpecificDates}>
                Only on specific dates
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      {isSpecificDates && (
        <div className="select_days_available">
          <Form.Item name={'availableDates'}>
            <MultipleDatePicker disabled={isViewMode} />
          </Form.Item>
        </div>
      )}
      {isSpecificDay && (
        <div className="select_days_available">
          <Form.Item noStyle>
            {WEEKDAYS.map((day) => {
              const selectedDays = form.getFieldValue('availableDays') || [];

              return (
                <Form.Item key={day.value} name={'availableDays'} noStyle>
                  <div className="weekday_switch">
                    <Switch
                      className="switch"
                      checked={selectedDays.includes(day.value)}
                      onChange={(checked) =>
                        handleSwitchChange(day.value, checked)
                      }
                      disabled={isViewMode}
                    />
                    <div
                      className={classNames([
                        'day',
                        { day_disabled: isViewMode },
                      ])}
                    >
                      {day.label}
                    </div>
                  </div>
                </Form.Item>
              );
            })}
          </Form.Item>
        </div>
      )}
      <Divider />
      <div className="available_on">
        <div className="title">Available on</div>
        <Form.Item name="availableOn" noStyle>
          <Checkbox.Group disabled={isViewMode}>
            {availableDayLimits.map((limit) => (
              <Checkbox
                key={limit.value}
                value={limit.value}
                name={limit.value}
                disabled={
                  isViewMode || limit.disabled || isCheckInOrCheckOutExp
                }
                className={classNames([
                  {
                    checkedAndDisabled:
                      !limit.disabled && isCheckInOrCheckOutExp && !isViewMode,
                  },
                ])}
              >
                {limit.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </div>
      <Divider />
      <div className="closing_period">
        <DateRangeSection
          mode={mode}
          field="closingPeriods"
          title={'Closing periods'}
          buttonText={'Add period'}
        />
      </div>
    </div>
  );
};
