import { Button, Form, Input, Modal } from 'antd';
import * as emailValidator from 'email-validator';

import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useUpdateBookingEmail } from 'app/hooks/data/adminBookingDetails/useAdminBooking';

const RULES = [{ required: true, message: 'Required' }];

type Props = {
  bookingCode: string;
  email: string;
  visible: boolean;
  onClose: () => void;
};

export const EditEmailModal = ({
  visible,
  onClose,
  email,
  bookingCode,
}: Props) => {
  const { mutateAsync: updateBookingEmail } =
    useUpdateBookingEmail(bookingCode);

  const [form] = Form.useForm();
  const isFormInvalid =
    !form.isFieldsTouched() ||
    !emailValidator.validate(form.getFieldValue('email'));
  const formValues = { email };

  const onSubmit = async () => {
    await updateBookingEmail({ email: form.getFieldValue('email') });
    onClose();
  };

  return (
    <Modal
      width={660}
      onCancel={onClose}
      okText="Save"
      visible={visible}
      title="Edit email"
      centered
      footer={[
        <Button
          type="primary"
          onClick={() => form.submit()}
          key="save"
          disabled={isFormInvalid}
        >
          Save
        </Button>,
      ]}
    >
      {visible && (
        <FormLegacyRenderProp
          form={form}
          onFinish={onSubmit}
          initialValues={formValues}
        >
          {
            // Some of our forms rely on the render prop syntax to get access to updated form values during render.
            // TODO try to remove this render prop syntax and see if it still works.
            () => (
              <div className="input-row">
                <Form.Item name="email" label="Email" rules={RULES}>
                  <Input />
                </Form.Item>
              </div>
            )
          }
        </FormLegacyRenderProp>
      )}
    </Modal>
  );
};

export default EditEmailModal;
