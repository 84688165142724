import { AutocompleteService, GeocoderService, PlaceService } from './typings';

// @ts-ignore
const Google = window.google;

export const autoCompleteService: AutocompleteService =
  new Google.maps.places.AutocompleteService();

export const placesService: PlaceService = new Google.maps.places.PlacesService(
  document.createElement('div')
);

export const geocoderService: GeocoderService = new Google.maps.Geocoder();
