import { stringify } from 'querystring';
import {
  NumberParam,
  QueryParamConfig,
  createEnumParam,
  decodeQueryParams,
  encodeDelimitedArray,
  encodeQueryParams,
  searchStringToObject,
} from 'use-query-params';

import { Coordinates } from 'app/redux/models/Coordinates/Coordinates';
import {
  NightCount,
  Range,
  SORT,
  SearchFiltersParams,
  SortFilter,
} from 'app/typings/filters';

import { isDefined } from '../typing';

type EncodedParam = string | (string | null)[] | null | undefined;
type EncodedBoolean = 'true' | 'false';

const CommaArrayParam = {
  encode: (array: string[] | null | undefined): string | undefined => {
    return encodeDelimitedArray(array, ',') || undefined;
  },
  decode: (input: EncodedParam): string[] | undefined => {
    const arrayString = Array.isArray(input) ? input[0] : input;

    if (!arrayString) {
      return undefined;
    }

    return arrayString.split(',');
  },
};
const CommaArrayParamNumber = {
  encode: (array: number[] | null | undefined): string | undefined => {
    if (!array) {
      return;
    }

    const formatted = array.map((el) => `${el}`);

    return encodeDelimitedArray(formatted, ',') || undefined;
  },
  decode: (input: EncodedParam): number[] | undefined => {
    const arrayString = Array.isArray(input) ? input[0] : input;

    if (!arrayString) {
      return undefined;
    }

    return arrayString
      .split(',')
      .map((el) => parseInt(el, 10))
      .filter((el) => !Number.isNaN(el));
  },
};
const RangeArrayParam = {
  encode: (array: Range[] | null | undefined): string | undefined => {
    if (!array) {
      return;
    }

    const mapped = array.map(({ start, end }) => `${start ?? ''}-${end ?? ''}`);

    return mapped.join(',');
  },
  decode: (input: EncodedParam): Range[] | undefined => {
    const arrayString = Array.isArray(input) ? input[0] : input;

    if (!arrayString) {
      return undefined;
    }

    const splitted = arrayString.split(',');

    const formatted = splitted.map((el) => {
      const [rawStart, rawEnd] = el.split('-');

      const start = parseInt(rawStart, 10);
      const end = parseInt(rawEnd, 10);

      return {
        start: !Number.isNaN(start) ? start : undefined,
        end: !Number.isNaN(end) ? end : undefined,
      };
    });

    return formatted;
  },
};
const BooleanParam = {
  encode: (input: boolean | undefined): EncodedBoolean | undefined => {
    if (!isDefined(input)) {
      return undefined;
    }

    return input ? 'true' : 'false';
  },
  decode: (input: EncodedParam): boolean | undefined => {
    if (!isDefined(input)) {
      return undefined;
    }

    const arrayString = Array.isArray(input) ? input[0] : input;

    return arrayString === 'true' || arrayString === '1';
  },
};
const CoordsObjectParam = {
  encode: (coords: Coordinates | null | undefined): string | undefined => {
    if (!coords) {
      return;
    }

    const formatted = `${coords.lat},${coords.lng}`;

    return formatted;
  },
  decode: (input: EncodedParam): Coordinates | undefined => {
    if (!input || Array.isArray(input)) {
      return;
    }

    const [rawLat, rawLgt] = input.split(',');

    const lat = parseFloat(rawLat);
    const lng = parseFloat(rawLgt);

    return !Number.isNaN(lat) && !Number.isNaN(lng)
      ? {
          lat,
          lng,
        }
      : undefined;
  },
};

const SortByParam = createEnumParam<SortFilter>(
  Object.keys(SORT) as SortFilter[]
) as QueryParamConfig<SortFilter | undefined, SortFilter | undefined>;
const DistanceParam = RangeArrayParam;
const PriceParam = RangeArrayParam;
const AdultsParam = NumberParam;
const ChildrenParam = NumberParam;
const BabiesParam = NumberParam;
const TagIdsParam = CommaArrayParamNumber;
const PMRParam = BooleanParam;
const NightCountParam = NumberParam;
const HotelServicesParam = CommaArrayParamNumber;

const DatesParam = CommaArrayParam;
const CoordsParam = CoordsObjectParam;

const QUERY_PARAMS_CONFIG = {
  coords: CoordsParam,
  sort: SortByParam,
  adults: AdultsParam,
  children: ChildrenParam,
  babies: BabiesParam,
  prices: PriceParam,
  tagIds: TagIdsParam,
  distances: DistanceParam,
  hotelServices: HotelServicesParam,
  pmr: PMRParam,
  dates: DatesParam,
  nightCount: NightCountParam,
  around: BooleanParam,
};

const QUERY_PARAMS_REGEX = /[^?]+(\?.+)/;
const ONE_MATCH = 2;

export const decodeSearchString = (
  search: string
): SearchFiltersParams | undefined => {
  const result = search.match(QUERY_PARAMS_REGEX);

  if (!result || result.length !== ONE_MATCH) {
    return;
  }

  const queryParamsString = result[1];

  const queryParams = searchStringToObject(queryParamsString);
  const decodedQueryParams = decodeQueryParams(
    QUERY_PARAMS_CONFIG,
    queryParams
  );

  return {
    coords: decodedQueryParams.coords,
    sort: decodedQueryParams.sort,
    adults: decodedQueryParams.adults ? decodedQueryParams.adults : undefined,
    children: decodedQueryParams.children
      ? decodedQueryParams.children
      : undefined,
    babies: decodedQueryParams.babies ? decodedQueryParams.babies : undefined,
    prices: decodedQueryParams.prices,
    tagIds: decodedQueryParams.tagIds,
    distances: decodedQueryParams.distances,
    hotelServices: decodedQueryParams.hotelServices,
    pmr: decodedQueryParams.pmr,
    dates: decodedQueryParams.dates,
    nightCount: decodedQueryParams.nightCount as NightCount,
  };
};

export const encodeToSearchString = (searchFilters: SearchFiltersParams) => {
  const encodedSearch = encodeQueryParams(QUERY_PARAMS_CONFIG, searchFilters);

  const compatibleSearch: Record<string, string | string[]> = {};

  for (const [key, searchValue] of Object.entries(encodedSearch)) {
    if (Array.isArray(searchValue)) {
      const values: string[] = [];

      searchValue.forEach((search) => {
        if (search) {
          values.push(search);
        }
      });

      compatibleSearch[key] = values;
    } else if (searchValue) {
      compatibleSearch[key] = searchValue;
    }
  }

  return stringify(compatibleSearch);
};
