import { Collapse } from 'antd';
import { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import Line from 'app/components/pages/GiftCards/details/Line/Line';
import type { Refund } from 'app/redux/models/GiftCard/GiftCard';
import { dateAndHour } from 'app/utils/dates';

import './Refunds.scss';

const { Panel } = Collapse;

type Props = {
  refunds: Array<Refund>;
};

export const Refunds = ({ refunds }: Props) => {
  const { currencyFormatter } = useContext(CurrencyContext);

  const renderHeader = (refund: Refund) =>
    `Refund - ${dateAndHour(refund.createdAt)} - ${currencyFormatter(
      refund.amountCb
    )}`;

  if (refunds.length === 0) {
    return null;
  }

  return (
    <div className="bloc refunds">
      <Collapse accordion>
        {refunds.map((refund, index) => (
          <Panel header={renderHeader(refund)} key={index}>
            <Line
              title="Credit card refund"
              value={currencyFormatter(refund.amountCb)}
            />

            <Line title="Created at" value={dateAndHour(refund.createdAt)} />
            <Line title="Created by" value={refund.userName} />
            <Line title="Reason type" value={refund.reason} />
            <Line title="Comment" value={refund.comment} />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};
