import { Card, Form, Layout } from 'antd';
import { useState } from 'react';

import {
  GetCommercialGestureQueryParams,
  useGetCommercialGestures,
} from 'app/hooks/data/commercialGestures/useGetGesture';
import { useSaveCommercialGesture } from 'app/hooks/data/commercialGestures/useSaveGesture';
import { useHotels } from 'app/hooks/data/hotels/useHotel';
import { useCountries } from 'app/hooks/data/useCountries';
import { IHotel } from 'app/typings';
import { CommercialGesture } from 'app/typings/CommercialGestures/CommercialGesture';
import { Country } from 'app/typings/countries';
import { useQueryParamHistory } from 'app/utils/queryParamHistory';
import {
  SortOrder,
  formatSortingForAPI,
  getSortOrder,
  isValidSortOrder,
} from 'app/utils/sort';

import CommercialGestureForm from './CommercialGestureForm/CommercialGestureForm';
import CommercialGestureList from './CommercialGestureList/CommercialGestureList';
import './CommercialGestures.scss';
import ConfirmModal from './ConfirmModal/ConfirmModal';

const { Content, Header } = Layout;

export const PAGE_SIZE = 10;

export type GesturesQueryParam = {
  search: string;
  pageNumber: number;
  dateSorting: SortOrder;
};

const queryParamInit = {
  pageNumber: 1,
  dateSorting: 'descend',
} as GesturesQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  dateSorting: 'ds',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  dateSorting: getSortOrder,
};

const makePayload = (
  queryParam: Partial<GesturesQueryParam>
): GetCommercialGestureQueryParams => {
  const validSortOrder = isValidSortOrder(queryParam.dateSorting);

  return {
    search: queryParam.search,
    sortingColumn: validSortOrder ? 'createdAt' : undefined,
    sortingOrder: validSortOrder
      ? formatSortingForAPI(queryParam.dateSorting)
      : undefined,
    offset: ((queryParam.pageNumber || 1) - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
  };
};

export const getCurrency = (
  countries: Country[] | undefined,
  countryId: number
) => countries?.find((c) => c.id === countryId)?.currency;

const CommercialGestures = () => {
  const [form] = Form.useForm();
  const [selectedHotel, setSelectedHotel] = useState<IHotel>();
  const { data: hotels } = useHotels();
  const { data: countries } = useCountries();
  const { mutateAsync: saveCommercialGesture } = useSaveCommercialGesture();

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<GesturesQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const {
    data: commercialGestures,
    isFetching: loading,
    refetch: reloadCommercialGestures,
  } = useGetCommercialGestures(makePayload(queryParam));

  const handleSubmit = async (values: CommercialGesture) => {
    await saveCommercialGesture(values);
    await reloadCommercialGestures();
    form.resetFields();
  };

  const handleHotelChange = (hotelId: number) => {
    const selectedAccount = hotels?.find((a) => a.id === hotelId);

    if (selectedAccount) {
      setSelectedHotel(selectedAccount);
    }
  };

  return (
    <Layout className="page__container commercial-gestures">
      <Header className="header">
        <h1>{'Commercial Gestures'}</h1>
      </Header>
      <Content className="body">
        <Card
          title="Make a commercial gesture"
          actions={[
            <ConfirmModal
              key="confirm"
              account={{
                name: selectedHotel?.name || '',
                currency: selectedHotel
                  ? getCurrency(countries, selectedHotel.countryId) || ''
                  : '',
              }}
              onSubmit={() => form.submit()}
              form={form}
            />,
          ]}
        >
          <Form form={form} onFinish={handleSubmit}>
            <CommercialGestureForm
              hotels={hotels || []}
              countries={countries || []}
              onHotelChange={handleHotelChange}
            />
          </Form>
        </Card>
        <CommercialGestureList
          hotels={hotels || []}
          countries={countries || []}
          queryParam={queryParam}
          updateQueryParam={updateQueryParam}
          commercialGestures={commercialGestures}
          loading={loading}
        />
      </Content>
    </Layout>
  );
};

export default CommercialGestures;
