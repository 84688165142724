import type { CollectionItem } from 'app/redux/models/CollectionItem/CollectionItem';
import { SortOrder, formatSortingForAPI } from 'app/utils/sort';

export const filterByRegion = (
  collections: CollectionItem[],
  menuKey: string
): CollectionItem[] => {
  const [selectionType, selectionKey] = menuKey.split('-');
  const selectedId = parseInt(selectionKey, 10);

  return collections.filter((collection) =>
    selectionType === 'country'
      ? selectedId === collection.countryId && !collection.clubId
      : selectedId === collection.clubId
  );
};

export const filterByText = (
  collections: CollectionItem[],
  text: string
): CollectionItem[] =>
  collections.filter((collection) =>
    collection.name.toLowerCase().includes(text.toLowerCase())
  );

export const filterByStatus = (
  collections: CollectionItem[],
  publishedFilter: string[]
): CollectionItem[] =>
  collections.filter((collection) =>
    collection.published
      ? publishedFilter.includes('published')
      : publishedFilter.includes('unpublished')
  );

export const sortCollections = (
  collections: CollectionItem[],
  nameSorting: SortOrder,
  updatedAtSorting: SortOrder
) =>
  collections.sort((collectionA, collectionB) => {
    if (updatedAtSorting !== 'none') {
      const valueOfA = new Date(collectionA.updatedAt).valueOf();
      const valueOfB = new Date(collectionB.updatedAt).valueOf();

      return updatedAtSorting === 'descend'
        ? valueOfB - valueOfA
        : valueOfA - valueOfB;
    }

    if (nameSorting !== 'none') {
      const nameOfA = collectionA.name.toLowerCase();
      const nameOfB = collectionB.name.toLowerCase();

      if (nameOfB < nameOfA) {
        return nameSorting === 'descend' ? -1 : 1;
      }

      if (nameOfB > nameOfA) {
        return nameSorting === 'descend' ? 1 : -1;
      }
    }

    return 0;
  });

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  filterByRegion,
  filterByText,
  filterByStatus,
  sortCollections,
};

export const formatPublished = (publishedFilter?: boolean[]) => {
  if (publishedFilter?.length === 1) {
    return publishedFilter[0];
  }

  return undefined;
};

export const formatSorting = (
  nameSorting: SortOrder,
  updatedAtSorting: SortOrder
) => {
  if (nameSorting && nameSorting !== 'none') {
    return {
      sortingColumn: 'name',
      sortingOrder: formatSortingForAPI(nameSorting),
    };
  }

  if (updatedAtSorting && updatedAtSorting !== 'none') {
    return {
      sortingColumn: 'updatedAt',
      sortingOrder: formatSortingForAPI(updatedAtSorting),
    };
  }

  return null;
};
