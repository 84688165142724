import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { ISalesResponse } from 'app/typings/sales';
import { apiMutation, apiRequest } from 'app/utils/request/api';

const path = '/sales' as const;
const toggleSaleOpenPath = '/sales/:id/open' as const;

export function useSales() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<ISalesResponse[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}

export function useSaveSales() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: ISalesResponse) =>
      apiMutation(
        'POST',
        {
          path,
        },
        payload
      ) as Promise<[]>,
    onSuccess: (_, payload) => {
      message.success(`Saved sale #${payload.id}!`);
      queryClient.invalidateQueries({ queryKey: [path] });
    },
    onError: (err) => {
      message.error('Error saving add on');
    },
  });
}

export function useToggleSaleOpen(saleId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      apiMutation('PATCH', {
        path: toggleSaleOpenPath,
        params: { id: saleId.toString() },
      }) as Promise<{ isOpen: boolean }>,
    onSuccess: (result) => {
      message.success(`${result.isOpen ? 'Opened' : 'Closed'} sale #${saleId}`);
      queryClient.invalidateQueries({ queryKey: [path] });
    },
    onError: (err) => {
      message.error(`Failed to toggle sale #${saleId} opening!`);
    },
  });
}
