import { Form, Layout } from 'antd';
import { useCallback, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import {
  InputRow,
  InputTextAreaRow,
  PictureRow,
  SelectRow,
} from 'app/components/commons/CustomFields/CustomFields';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useGuideCategories } from 'app/hooks/data/guides/useGuideCategories';
import { useGuide } from 'app/hooks/data/guides/useGuides';
import { useSaveGuide } from 'app/hooks/data/guides/useGuidesSave';
import { useClubs } from 'app/hooks/data/useClubs';
import { Guide } from 'app/typings/guides';
import { slugify } from 'app/utils/strings';

import './Guide.scss';
import GuideSections from './GuideSections/GuideSections';

const { Content } = Layout;

const DEFAULT_VALUES = {
  sections: [{ packages: [{}] }],
};

const GuidePage = () => {
  const location = useLocation();
  const history = useHistory();

  const { id: paramId } = useParams<{ id: string }>();

  const searchParams = new URLSearchParams(location.search);
  const duplicateId = searchParams.get('duplicateId');

  const parsedParamId = parseInt((duplicateId || paramId) ?? '');
  const guideId = isNaN(parsedParamId) ? undefined : parsedParamId;

  const [form] = Form.useForm();

  const { data: guide, isFetching: guideFetching } = useGuide(guideId);
  const { data: guideCategories } = useGuideCategories();
  const { data: clubs } = useClubs();
  const { mutateAsync: saveGuide } = useSaveGuide();

  const isNew = duplicateId || !guide;

  useEffect(() => {
    if (!guideFetching) {
      form.resetFields();
      // This part (really) reinitialize the form after a re-fetch of the guide,
      // otherwise values like section.id would not appear when submitting
    }
  }, [guideFetching, form]);

  const onSubmit = useCallback(
    async (values: Guide) => {
      const formattedValues = {
        ...values,
        id: !isNew ? guide?.id : undefined,
        featured: (guide?.featured && !isNew) ?? false,
        isHybrid: (guide?.isHybrid && !isNew) ?? false,
        isDuplicate: guide?.isDuplicate || false,
      };

      await saveGuide(formattedValues);
      history.push('/guides');
    },
    [
      guide?.featured,
      guide?.id,
      guide?.isDuplicate,
      guide?.isHybrid,
      history,
      isNew,
      saveGuide,
    ]
  );

  const onChange = (newValues: Partial<Guide>) => {
    if (newValues.name) {
      form.setFieldsValue({
        slug: slugify(newValues.name),
      });
    }
  };

  const duplicatedGuide = !!duplicateId
    ? {
        ...guide,
        id: undefined,
        slug: undefined,
        clubId: undefined,
        sections: guide?.sections.map((section) => {
          return {
            ...section,
            id: undefined,
            packages: [],
          };
        }),
        isDuplicate: true,
      }
    : undefined;

  const initialValues = duplicatedGuide ?? guide;

  if (guideFetching || (!isNew && !initialValues)) {
    return null;
  }

  return (
    <Layout className="guide-layout">
      <FormLegacyRenderProp
        form={form}
        onFinish={onSubmit}
        initialValues={initialValues || DEFAULT_VALUES}
        onValuesChange={onChange}
      >
        {(values: Partial<Guide>, { isFieldsTouched, submit }) => (
          <SDetailLayout
            title={initialValues?.name || 'New guide'}
            mode="edit"
            onSave={submit}
            isDirty={isFieldsTouched()}
          >
            <Content className="page-content">
              <SCard title="Basic information">
                <h3>Header</h3>
                <InputRow
                  label="Title"
                  name="name"
                  placeholder="Valentine's day, Detox, Couple Anniversary..."
                  required
                />
                <InputRow
                  label="Slug"
                  name="slug"
                  placeholder="valentine-day"
                  required
                  disabled={!isNew}
                />
                <InputRow
                  label="Qualifier"
                  name="qualifier"
                  placeholder="(XX staycations) to celebrate your couple's anniversary"
                  required
                />
                <InputRow
                  label="Page Title"
                  name="pageTitle"
                  placeholder="Valentine's day: a weekend with your carnal"
                  required
                />
                <PictureRow label="Cover Picture" name="coverPicture" />
                <h3>Meta</h3>
                <SelectRow
                  label="Club"
                  name="clubId"
                  placeholder="Paris, Riviera"
                  required
                  showSearch
                  dataSource={clubs ?? []}
                />
                <SelectRow
                  label="Category"
                  name="categoryId"
                  placeholder="Mood, Experiences"
                  required
                  showSearch
                  dataSource={guideCategories ?? []}
                />
              </SCard>
              <SCard title="Introduction">
                <InputRow
                  label="Title"
                  name="mainTitle"
                  placeholder="Valentine's day: a weekend with your Rod"
                  required
                />
                <InputTextAreaRow
                  label="Introduction"
                  name="introduction"
                  placeholder="Enjoy your Valentine's day like never with the best selection of hotels in your city"
                  required
                />
              </SCard>
              <Form.List name="sections">
                {(fields, { add, remove, move }) => (
                  <GuideSections
                    fields={fields}
                    add={add}
                    remove={remove}
                    move={move}
                    values={values}
                  />
                )}
              </Form.List>
              <SCard title="Conclusion">
                <InputTextAreaRow
                  label="Conclusion"
                  name="conclusion"
                  placeholder="Like Rod used to say: Enjoy your staycation!"
                  required
                />
              </SCard>
            </Content>
          </SDetailLayout>
        )}
      </FormLegacyRenderProp>
    </Layout>
  );
};

export default GuidePage;
