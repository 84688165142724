import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQueryClient } from '@tanstack/react-query';
import { addDays, eachDayOfInterval, format } from 'date-fns';

import {
  InventoryScrapped,
  RoomScrapped,
  RoomsWithInventory,
} from 'app/typings/autoInventory';
import { apiRequest } from 'app/utils/request/api';

const roomsPath = '/hotel-admin/:hotelId/stock-scrapper/rooms' as const;

export const inventoryPath =
  '/hotel-admin/:hotelId/stock-scrapper/inventory' as const;

export function useRoomsScrapping() {
  const queryClient = useQueryClient();

  const getRoomsFromScrapper = (hotelId: number) => {
    return queryClient.fetchQuery<
      { rooms: RoomScrapped[] },
      unknown,
      { rooms: RoomScrapped[] },
      (
        | typeof roomsPath
        | {
            hotelId: number;
          }
      )[]
    >({
      queryKey: [roomsPath, { hotelId }],
      queryFn: () =>
        apiRequest({
          path: roomsPath,
          params: { hotelId: hotelId.toString() },
        }),
      staleTime: 5 * MILLISECONDS_IN_MINUTE,
    });
  };

  return {
    getRoomsFromScrapper,
  };
}

export function useInventoryScrapping() {
  const queryClient = useQueryClient();

  const getInventoryFromScrapper = (hotelId: number) => {
    return queryClient.fetchQuery<
      { inventory: RoomsWithInventory[] },
      unknown,
      { inventory: RoomsWithInventory[] },
      (
        | typeof inventoryPath
        | {
            hotelId: number;
          }
      )[]
    >({
      queryKey: [inventoryPath, { hotelId }],
      queryFn: () =>
        apiRequest({
          path: inventoryPath,
          params: { hotelId: hotelId.toString() },
        }),
      staleTime: 5 * MILLISECONDS_IN_MINUTE,
    });
  };

  return {
    getInventoryFromScrapper,
  };
}

export const addMissingDates = (data: { inventory: RoomsWithInventory[] }) => {
  return data.inventory.map((room) => {
    const allDates = eachDayOfInterval({
      start: new Date(),
      end: addDays(new Date(), 6),
    }).map((date) => format(date, 'yyyy-MM-dd'));

    const existingInventory: { [key: string]: InventoryScrapped } =
      room.inventory.reduce((acc, item) => {
        acc[item.date] = item;

        return acc;
      }, {} as { [key: string]: InventoryScrapped });

    const filledInventory = allDates.map((date) => {
      if (existingInventory[date]) {
        return existingInventory[date];
      }

      return {
        date: date,
        stock: 0,
        price: 0,
      };
    });

    return { ...room, inventory: filledInventory };
  });
};
