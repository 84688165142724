import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { apiMutation } from 'app/utils/request/api';

const generateBookingRecieptPath =
  'bookings/:bookingCode/generate-receipt' as const;

export function useGenerateBookingReceipt(bookingCode: string) {
  return useMutation({
    mutationFn: async () =>
      apiMutation('POST', {
        path: generateBookingRecieptPath,
        params: { bookingCode },
      }) as Promise<void>,
    onSuccess: () => {
      message.success('New receipt successfully generated');
    },
    onError: () => {
      message.error('Failed to start receipt generation', 10);
    },
  });
}
