import { Layout } from 'antd';

import Card from 'app/components/layout/Card';
import CountryListPage from 'app/components/lists/CountryListPage';
import { useSales } from 'app/hooks/data/useSales';

import SaleInfo from './SaleInfo/SaleInfo';

const { Content } = Layout;

const SalesList = () => {
  const { data: sales } = useSales();

  return (
    <CountryListPage title="Sale times">
      {({ countryId }) => {
        const sale = sales?.find((sale) => sale.countryId === countryId);

        return (
          <Layout>
            <Content className="page__content sales-infos">
              {sale ? (
                <SaleInfo sale={sale} />
              ) : (
                <Card title="No current or upcoming sale." />
              )}
            </Content>
          </Layout>
        );
      }}
    </CountryListPage>
  );
};

export default SalesList;
