import { useMutation, useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { IHotel } from 'app/typings';
import { ChannelManager } from 'app/typings/channelManagers';
import { Hotel } from 'app/typings/hotels/Hotel/Hotel';
import { NewHotel } from 'app/typings/hotels/NewHotel/NewHotel';
import { RateMode } from 'app/typings/rateModes';
import { apiMutation, apiRequest } from 'app/utils/request/api';

const duplicatePath = '/hotels/:hotelId/duplicate' as const;
const hotelPath = '/hotels/:hotelId' as const;
const hotelsPath = '/hotels' as const;

export function useGetHotel(hotelId?: number) {
  return useQuery<Hotel>({
    queryKey: [hotelPath, { hotelId }],
    queryFn: !!hotelId
      ? () =>
          apiRequest({
            path: hotelPath,
            params: { hotelId: hotelId?.toString() },
          })
            .catch((err) => message.error('Error fetching hotel', err))
            .then((hotel) => ({
              ...hotel,
              channelManager: hotel.channelManager || ChannelManager.NONE,
              rateMode: hotel.rateMode || RateMode.MULTI_RATE,
            })) as Promise<Hotel>
      : undefined,
    enabled: !!hotelId,
  });
}

export function useSaveHotel() {
  return useMutation({
    mutationFn: async (payload: NewHotel) =>
      apiMutation(
        'POST',
        {
          path: hotelsPath,
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      message.success('Hotel successfully saved!');
    },
    onError: () => {
      message.error('Error saving Hotel');
    },
  });
}

export function useUpdateHotel() {
  return useMutation({
    mutationFn: async (payload: Partial<Hotel>) =>
      apiMutation(
        'POST',
        {
          path: hotelsPath,
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      message.success('Hotel successfully updated!');
    },
    onError: () => {
      message.error('Error updating Hotel');
    },
  });
}

export function useDeleteHotel(hotelId: string) {
  return useMutation({
    mutationFn: async () =>
      apiMutation('DELETE', {
        path: hotelPath,
        params: { hotelId },
      }) as Promise<void>,
    onSuccess: () => {
      message.success(`Hotel ${hotelId} deleted`);
    },
    onError: () => {
      message.error('Not possible: this hotel is used somewhere else');
    },
  });
}

export function useDuplicateHotel(hotelId: string) {
  return useMutation({
    mutationFn: async () =>
      apiMutation('POST', {
        path: duplicatePath,
        params: { hotelId },
      }) as Promise<{ hotelId: string }>,
    onSuccess: () => {
      message.success(`Hotel ${hotelId} duplicated, redirecting to copy`);
    },
    onError: () => {
      message.error('Failed to duplicate hotel');
    },
  });
}

export function useHotels() {
  return useQuery<IHotel[]>({
    queryKey: [hotelsPath],
    queryFn: () => apiRequest({ path: hotelsPath }),
    staleTime: Infinity,
  });
}
