import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { useMemo } from 'react';

import { BookingListItem } from 'app/typings/adminBookings/bookings';
import { apiRequest } from 'app/utils/request/api';

const path = 'admin/bookings' as const;

export function useBookings(queryParams: BookingsQueryParams = { offset: 0 }) {
  const key = useMemo(() => [path, { queryParams }], [queryParams]);

  return useQuery({
    queryKey: [key],
    queryFn: () =>
      apiRequest({
        path: `${path}?${queryString.stringify(queryParams)}`,
      }) as Promise<BookingListItem[]>,
  });
}

export type BookingsQueryParams = {
  search?: string;
  sorting?: string;
  beginning?: string;
  ending?: string;
  offset: number;
  status?: string[];
  psp?: string[];
};
