import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Tooltip } from 'antd';
import { isFuture } from 'date-fns';
import { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import Line from 'app/components/pages/GiftCards/details/Line/Line';
import { UserContext } from 'app/context/UserContext/UserContext';
import { useGenerateBookingReceipt } from 'app/hooks/data/adminBookingDetails/useAdminBookingReceipt';
import { config } from 'app/redux/store';
import { type BookingDetails } from 'app/typings/adminBookings/bookings';

import './Payment.scss';

type Props = {
  setIsVoucherModalVisible: (bool: boolean) => void;
  bookingDetails: BookingDetails;
  setRefundModalVisible: (bool: boolean) => void;
};

const Payment = ({
  setIsVoucherModalVisible,
  bookingDetails,
  setRefundModalVisible,
}: Props) => {
  const { currencyFormatter } = useContext(CurrencyContext);
  const {
    chargedAmount,
    cardInfo,
    paymentAdvanced,
    creditAmount,
    psp,
    status,
  } = bookingDetails.bookingSummary;
  const { voucher, bookingSummary } = bookingDetails;
  const { couponCode, couponAmount, refusalReason } = paymentAdvanced;

  const { token, user } = useContext(UserContext);

  const isDownloadDisabled =
    !bookingSummary.paymentReceiptPath ||
    isFuture(new Date(bookingSummary.checkout));
  const isGenerateDisabled = isFuture(new Date(bookingSummary.checkout));

  const { mutateAsync: generateBookingReceipt } = useGenerateBookingReceipt(
    bookingSummary.code
  );

  const buttonDownloadReceipt = () => {
    const renderButton = () => (
      <Button
        className="button"
        disabled={isDownloadDisabled}
        onClick={() => {
          window.location.replace(
            isDownloadDisabled
              ? '#'
              : `${config.apiUrl}/bookings/${bookingSummary.code}/payment-receipt?token=${token}`
          );
        }}
      >
        Download receipt
      </Button>
    );

    return isDownloadDisabled ? (
      <Tooltip
        placement="top"
        title={
          isGenerateDisabled
            ? 'The payment receipt will be accessible after the user has checked out.'
            : 'You have to manually generate receipt for booking before 2024'
        }
      >
        {renderButton()}
      </Tooltip>
    ) : (
      renderButton()
    );
  };

  const onGenerateClick: MenuProps['onClick'] = async () => {
    await generateBookingReceipt();
  };

  const items = [
    {
      key: 'generate',
      label: 'Generate new receipt',
    },
  ];

  const actionButtonDownloadReceipt = () => (
    <>
      {buttonDownloadReceipt()}
      <Dropdown
        disabled={isGenerateDisabled}
        trigger={['click']}
        menu={{ items, onClick: onGenerateClick }}
      >
        <Button className="icon-btn">
          <EllipsisOutlined className="icon" size={16} />
        </Button>
      </Dropdown>
    </>
  );

  const renderReceiptButton = () =>
    user?.role === 'superadmin' || user?.role === 'admin'
      ? actionButtonDownloadReceipt()
      : buttonDownloadReceipt();

  return (
    <div className="bloc">
      <div className="title-with-button2">
        <div className="label">Payment</div>
        <div className="header-buttons">
          {!voucher && (
            <Button
              className="button"
              onClick={() => setIsVoucherModalVisible(true)}
            >
              Voucher
            </Button>
          )}
          {['accepted', 'cancelled'].includes(status) && (
            <Button
              className="button"
              onClick={() => setRefundModalVisible(true)}
            >
              Refund
            </Button>
          )}
          {token && status === 'accepted' && renderReceiptButton()}
        </div>
      </div>
      <div className="generic-content">
        {psp !== undefined && (
          <Line
            title="Payment provider"
            value={`${psp.charAt(0) + psp.slice(1).toLowerCase()}`}
          />
        )}
        {!!cardInfo && (
          <Line title="Card info" value={`•••• ${cardInfo.last4}`} />
        )}
        <Line
          title="Credit card amount"
          value={currencyFormatter(chargedAmount)}
        />
        {couponCode !== undefined && (
          <Line title="Coupon code" value={couponCode} />
        )}
        {couponAmount !== undefined && couponAmount !== 0 && (
          <Line title="Coupon amount" value={currencyFormatter(couponAmount)} />
        )}
        {creditAmount !== 0 && (
          <Line title="Credit amount" value={currencyFormatter(creditAmount)} />
        )}
        {refusalReason !== undefined && (
          <Line title="Refusal reason" value={refusalReason} />
        )}
      </div>
    </div>
  );
};

export default Payment;
