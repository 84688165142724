import { RATE_MODE_LABELS, RateMode } from 'app/typings/rateModes';

export const getRateModeLabel = (rateMode?: RateMode): string => {
  return (
    RATE_MODE_LABELS.find(({ mode }) => mode === rateMode)?.label ||
    'BAR + Staycation rate'
  );
};

export const RateModesDataSource = (
  Object.keys(RateMode) as Array<keyof typeof RateMode>
).map((key) => ({
  id: RateMode[key] as RateMode,
  name:
    RATE_MODE_LABELS.find((cml) => cml.mode === RateMode[key])?.label ||
    'Unknown',
}));
