import { Inventory } from '../Types';
import { packageHasOptionalExperiences } from '../Utils';

import './Tooltip.scss';

type BarRowTooltipProps = {
  detail: string;
  inventory: Inventory;
  dayPackage?: boolean;
};

export const BarRowTooltip = ({
  detail,
  inventory,
  dayPackage,
}: BarRowTooltipProps) => {
  return (
    <div className="extranet-inventory-tooltip__container">
      {!dayPackage && (
        <div className="extranet-inventory-tooltip__title">
          {!!inventory.channelManager
            ? 'Public rate can be updated from your channel manager interface when the staycation sales are closed.'
            : 'BAR is the Best Available Rate (Non Amendable/Non Refundable) on your website on each day, for each room category.'}
        </div>
      )}
      <div className="extranet-inventory-tooltip__subtitle">
        {dayPackage
          ? `Public price /2 pers. =${detail}`
          : `Crossed rate = BAR +${detail}`}
      </div>
      {packageHasOptionalExperiences(inventory.pkg) && (
        <div className="extranet-inventory-tooltip__subtitle">
          *Depending on the configured availability of the experience
        </div>
      )}
    </div>
  );
};
