import { Alert as AntdAlert } from 'antd';
import type { AlertProps } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import './AlertModal.scss';

export const AlertModal = ({
  type,
  closable,
  closeText,
  message,
  description,
  onClose,
  afterClose,
  showIcon = true,
  role,
  style,
  prefixCls,
  className,
  banner,
  icon,
  closeIcon,
  action,
  onMouseEnter,
  onMouseLeave,
  onClick,
  isOpen,
}: AlertProps & { isOpen: boolean }) => {
  return (
    <Modal
      open={isOpen}
      footer={null}
      centered
      closable={false}
      bodyStyle={{ padding: 0 }}
      className="alertModal"
    >
      <AntdAlert
        type={type}
        closable={closable}
        closeText={closeText}
        message={message}
        description={description}
        onClose={onClose}
        afterClose={afterClose}
        showIcon={showIcon}
        role={role}
        style={style}
        prefixCls={prefixCls}
        className={className}
        banner={banner}
        icon={icon}
        closeIcon={closeIcon}
        action={action}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      />
    </Modal>
  );
};

export default AlertModal;
