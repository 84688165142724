import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { apiMutation } from 'app/utils/request/api';

const resendBookingConfirmationPath =
  'bookings/:bookingCode/mails?customerOnly=true' as const;
const resendHotelConfirmationPath =
  'bookings/:bookingCode/mails?hotelOnly=true' as const;

export function useResendBookingConfirmation(bookingCode: string) {
  return useMutation({
    mutationFn: async () =>
      apiMutation('POST', {
        path: resendBookingConfirmationPath,
        params: { bookingCode },
      }) as Promise<void>,
    onSuccess: () => {
      message.success('Mail has been resent');
    },
    onError: () => {
      message.error('Failed to resend message', 10);
    },
  });
}

export function useResendBookingHotelConfirmation(bookingCode: string) {
  return useMutation({
    mutationFn: async () =>
      apiMutation('POST', {
        path: resendHotelConfirmationPath,
        params: { bookingCode },
      }) as Promise<void>,
    onSuccess: () => {
      message.success('Mail has been resent');
    },
    onError: () => {
      message.error('Failed to resend message', 10);
    },
  });
}
