import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { CommercialGesture } from 'app/typings/CommercialGestures/CommercialGesture';
import { apiMutation } from 'app/utils/request/api';

const path = 'admin/commercial-gestures' as const;

export function useSaveCommercialGesture() {
  return useMutation({
    mutationFn: async (payload: CommercialGesture) =>
      apiMutation(
        'POST',
        {
          path,
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      message.success('Gesture succesfully created');
    },
    onError: () => {
      message.error('Failed to create gesture');
    },
  });
}
