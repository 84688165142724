import { Col, Form, Input, Row, Tooltip } from 'antd';
import { RuleObject } from 'antd/lib/form';
import React, { useContext } from 'react';

import { Option, Select } from 'app/components/fields/Select';
import FormSection from 'app/components/forms/FormSection';
import Card from 'app/components/layout/Card';
import { UserContext } from 'app/context/UserContext/UserContext';
import { BillingInterval } from 'app/typings/billingIntervals';
import { validateIban } from 'app/utils/iban';
import { isUserSuperAdmin } from 'app/utils/user/user';

interface FinancialProps {
  mode: 'view' | 'edit';
  displayStripe?: boolean;
  isIbanRequired?: boolean;
  isBuilder?: boolean;
}

const RULES = [{ required: true, message: 'Required' }];

const BILLING_INTERVAL_LIST = [
  { value: BillingInterval.DAILY, label: 'Daily', isDisabled: true },
  { value: BillingInterval.WEEKLY, label: 'Weekly', isDisabled: false },
  { value: BillingInterval.MONTHLY, label: 'Monthly', isDisabled: false },
] as const satisfies readonly {
  value: BillingInterval;
  label: string;
  isDisabled: boolean;
}[];

const Financial: React.FC<FinancialProps> = ({
  mode,
  displayStripe = true,
  isIbanRequired = false,
  isBuilder = false,
}) => {
  const { user } = useContext(UserContext);

  const isSuperAdmin = isUserSuperAdmin(user);

  const isDisabled = mode === 'view';

  const ibanValidator = (_: RuleObject, value: string) => {
    const isValidIban = validateIban(value);

    if (isValidIban || (!isIbanRequired && value.length === 0)) {
      return Promise.resolve();
    }

    return Promise.reject('Must be a valid IBAN');
  };

  return (
    <Card title="Financial information">
      <FormSection title="Payout account">
        <Form.Item label="Bank" name={['finance', 'bank']} rules={RULES}>
          <Input disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          label="IBAN"
          name={['finance', 'iban']}
          rules={
            isIbanRequired
              ? [
                  { message: 'Must be a valid IBAN', validator: ibanValidator },
                  ...RULES,
                ]
              : [{ message: 'Must be a valid IBAN', validator: ibanValidator }]
          }
          getValueFromEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
            return e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
          }}
        >
          <Input disabled={isDisabled} />
        </Form.Item>
      </FormSection>
      <FormSection title="Invoicing contact">
        <Row>
          <Col span={8}>
            <Form.Item
              label="Gender"
              name={['finance', 'invoicing', 'gender']}
              rules={RULES}
            >
              <Select disabled={isDisabled}>
                <Option value="MALE">Mr</Option>
                <Option value="FEMALE">Mrs</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="First name"
              name={['finance', 'invoicing', 'firstName']}
              rules={RULES}
            >
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last name"
              name={['finance', 'invoicing', 'lastName']}
              rules={RULES}
            >
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Email"
          name={['finance', 'invoicing', 'email']}
          rules={[
            {
              message: 'Must be a valid email',
              type: 'email',
            },
            { required: true, message: 'Required' },
          ]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>
      </FormSection>
      <FormSection title="Invoicing information">
        <Form.Item
          label="Company name"
          name={['finance', 'invoicing', 'hotelName']}
          rules={RULES}
        >
          <Input disabled={isDisabled} />
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label="Street" name={['finance', 'invoicing', 'street']}>
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Zip" name={['finance', 'invoicing', 'zipCode']}>
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="City" name={['finance', 'invoicing', 'city']}>
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Country"
              name={['finance', 'invoicing', 'country']}
            >
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="VAT number" name={['finance', 'vat']} rules={RULES}>
          <Input disabled={isDisabled} />
        </Form.Item>
      </FormSection>
      {displayStripe && (
        <FormSection title="Stripe">
          <Form.Item label="Onboarding done" name="pspOnboardingDone">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Ready for payment" name="paymentReady">
            <Input disabled />
          </Form.Item>
        </FormSection>
      )}
      {!isBuilder && (
        <FormSection title="Invoicing frequency">
          <Tooltip
            title={
              !isSuperAdmin &&
              !isDisabled &&
              'Please contact a Super Admin to edit the payment frequency'
            }
          >
            <Form.Item label="Billing interval" name="billingInterval">
              <Select disabled={isDisabled || !isSuperAdmin}>
                {BILLING_INTERVAL_LIST.map((interval, idx) => (
                  <Option
                    value={interval.value}
                    disabled={interval.isDisabled}
                    key={idx}
                  >
                    {interval.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Tooltip>
        </FormSection>
      )}
    </Card>
  );
};

export default Financial;
