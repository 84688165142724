import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { HotelBrief } from 'app/typings/hotels/HotelBrief/HotelBrief';
import { apiMutation } from 'app/utils/request/api';

const saveHotelBriefPath = 'hotels/:hotelId/brief' as const;

export function useSaveHotelBrief(hotelId: string) {
  return useMutation({
    mutationFn: async (payload: HotelBrief) =>
      apiMutation(
        'PUT',
        {
          path: saveHotelBriefPath,
          params: { hotelId },
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      message.success('Hotel successfully saved!');
    },
    onError: () => {
      message.error('Error posting brief');
    },
  });
}
