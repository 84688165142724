import React from 'react';

type Props = {
  children: React.ReactNode;
};

type Config = {
  env: string;
  appUrl: string;
  apiUrl: string;
  amplitudeKey?: string;
};

export const config: Config = {
  env: process.env.REACT_APP_ENV as string,
  appUrl: process.env.REACT_APP_APP_URL as string,
  apiUrl: process.env.REACT_APP_API_URL as string,
  amplitudeKey: process.env.REACT_APP_AMPLITUDE_KEY as string | undefined,
};

export const ConfigContext = React.createContext<Config | undefined>(undefined);

const ConfigContextProvider = ({ children }: Props) => {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export default ConfigContextProvider;
