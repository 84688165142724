import { BABY_COT_BED_CATEGORY_ID, Bed, BedCategory } from 'app/typings';

export const maxRoomCapacity = (
  beds: Bed[] | undefined,
  bedCategories: BedCategory[]
) => {
  if (beds && beds.length > 0) {
    const maxCapacity = beds
      .map((b) => {
        if (b.categoryId !== BABY_COT_BED_CATEGORY_ID) {
          return (
            bedCategories.find((bc) => bc.id === b.categoryId)?.capacity || 0
          );
        }

        return 0;
      })
      .reduce((l: number, r: number) => l + r);

    return isNaN(maxCapacity) ? 0 : maxCapacity;
  }

  return 0;
};

export const hasBabyBed = (beds?: Bed[]) => {
  if (beds) {
    const babyBed = beds.find(
      (bed) => bed.categoryId === BABY_COT_BED_CATEGORY_ID
    );

    return !!babyBed;
  }

  return false;
};
