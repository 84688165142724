import { Col, Progress, Row, Tag } from 'antd';
import Title from 'antd/lib/typography/Title';

type HotelScoreHeaderProps = {
  stockOptimisationScore: number;
};

const getStrokeColor = (score: number) =>
  score < 40 ? '#FF382C' : score < 80 ? '#ff9500' : '#28A745';

export const HotelScoreHeader = ({
  stockOptimisationScore,
}: HotelScoreHeaderProps) => {
  const roundedScore = Math.round(stockOptimisationScore * 100);
  const strokeColor = getStrokeColor(roundedScore);

  return (
    <Row>
      <Progress
        width={80}
        type="circle"
        strokeColor={strokeColor}
        percent={roundedScore}
        format={(percent) => (
          <>
            <div className="hotel-score__percent">{percent}</div>
            <div className="hotel-score__percent__100">/100</div>
          </>
        )}
      />
      <Col className="hotel-score__title__container">
        <Title className="hotel-score__title">
          <span>Optimisation score</span>
          <Tag className="hotel-score__title__tag" color="#1A1A1A">
            New
          </Tag>
        </Title>
        <p className="hotel-score__subtitle">
          Improve your visibility to users by optimising your inventory
        </p>
      </Col>
    </Row>
  );
};
