import { BulbOutlined } from '@ant-design/icons';
import { Badge, Button } from 'antd';
import { useContext } from 'react';

import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

export const TaskCenterButton = () => {
  const { openTaskCenter, missingTasks, doneTasks, page } =
    useContext(TaskCenterContext);
  const { track } = useAmplitude();

  const clickButtonTaskCenter = () => {
    openTaskCenter();
    track('Open Task center', {
      from: page,
      undoneTasks: missingTasks.length,
      doneTasks: doneTasks.length,
    });
  };

  return (
    <Badge count={missingTasks.length} color="#FF2E63">
      <Button icon={<BulbOutlined />} onClick={clickButtonTaskCenter}>
        Task center
      </Button>
    </Badge>
  );
};
