import { Divider, FormInstance, FormListFieldData } from 'antd';

import { IEditoEventForm } from 'app/typings/edito';

import { ActionContainer } from '../ActionContainer/ActionContainer';

import { ExperienceTypeForm } from './ExperienceTypeForm/ExperienceTypeForm';
import { SearchCriteriaForm } from './SearchCriteriaForm/SearchCriteriaForm';

interface Props {
  field: FormListFieldData;
  form: FormInstance<IEditoEventForm>;
}

export const SearchRedirectionForm = ({ field, form }: Props) => {
  return (
    <ActionContainer field={field}>
      <ExperienceTypeForm field={field} form={form} />
      <Divider />
      <SearchCriteriaForm field={field} form={form} />
    </ActionContainer>
  );
};
