import { Col, Row } from 'antd';
import classNames from 'classnames';
import { eachDayOfInterval, format } from 'date-fns';

import { useAppSelector } from 'app/redux/hooks';

import { Room } from '../../Types';
import { computeRowClassNames } from '../../Utils';

const BookedRow = ({ currentRange, room }: Props) => {
  const bookableDays = useAppSelector(
    (s) => s.hotelAdmin.inventory?.saleDate.bookableDays ?? []
  );

  const renderBooked = (date: any) => {
    const opening = room.openings.find(
      (o) => o.date === format(date, 'yyyy-MM-dd')
    );

    return (
      <Col
        className={classNames(computeRowClassNames(date, bookableDays))}
        flex="1"
        key={`${format(date, 'yyyy-MM-dd')}-${
          opening ? opening.roomId : ''
        }-booked`}
      >
        {!!opening && (opening.booked || 0)}
      </Col>
    );
  };

  return (
    <Row>
      <Col className="extranet-inventory__property-cell extranet-inventory__head">
        Bookings
      </Col>
      {eachDayOfInterval(currentRange).map(renderBooked)}
    </Row>
  );
};

type Props = {
  currentRange: Interval;
  room: Room;
};

export default BookedRow;
