import { Form, FormListFieldData, Select } from 'antd';

import { HeaderWrapper } from 'app/components/pages/Edito/commons/Detail/HeaderWrapper/HeaderWrapper';
import { DEFAULT_PRICE_FILTER } from 'app/utils/filters/filters';
import { requiredRule } from 'app/utils/rules';

type Props = {
  field: FormListFieldData;
};

const options: { label: string; value: string }[] = DEFAULT_PRICE_FILTER.map(
  (price) => {
    const priceWithStart = price.end
      ? `${price.start}-${price.end}`
      : `>${price.start}`;
    const priceWithoutStart = `<${price.end}`;

    return {
      label: `${price.start ? priceWithStart : priceWithoutStart}`,
      value: `${price.start ?? ''}-${price.end ?? ''}`,
    };
  }
);

export const BudgetForm = ({ field }: Props) => {
  const { name } = field;

  return (
    <HeaderWrapper isBold={false} title="Budget filter">
      <Form.Item
        name={[name, 'searchFilters', 'prices']}
        rules={[requiredRule]}
      >
        <Select
          options={options}
          mode="multiple"
          allowClear
          placeholder={`Search a filter for budget`}
        />
      </Form.Item>
    </HeaderWrapper>
  );
};
