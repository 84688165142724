import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { CustomTag } from 'app/typings/customTags';
import { apiMutation, apiRequest } from 'app/utils/request/api';

const path = '/tags' as const;

export function useCustomTags() {
  return useQuery({
    queryKey: [path],
    queryFn: () =>
      apiRequest({
        path,
        queryParams: { tagType: 'package_custom' },
      }) as Promise<CustomTag[]>,
    onError: () => {
      message.error('Error fetching CustomTags');
    },
    staleTime: Infinity,
  });
}

export function useCreateCustomTag() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: string) =>
      apiMutation('POST', { path }, { name: payload }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [path],
      });
    },
  });
}
