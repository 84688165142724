import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import { Form, Switch } from 'antd';
import React from 'react';

import CustomInput from 'app/components/fields/Input';
import { assertNever, parseInputNumber } from 'app/utils/typing';

import { Inventory } from '../../Types';

import './Rules.scss';
import {
  RuleKeys,
  RuleType,
  disableableLabel,
  renderPrincingRulerError,
} from './Utils';

type RulesProps = {
  ruleInfo: RuleType;
  isRuleActive: boolean;
  inventory: Inventory;
  toggleSwitch: (switchValue: boolean) => void;
  isSingleRate: boolean;
  isRuleInvalid: boolean;
};

export const Rules = ({
  ruleInfo,
  isRuleActive,
  inventory,
  toggleSwitch,
  isSingleRate,
  isRuleInvalid,
}: RulesProps) => {
  const form = Form.useFormInstance();
  const formValues = Form.useWatch([], form);

  const getRuleForm = (key: RuleKeys) => {
    switch (key) {
      case 'standardDiscount':
        return (
          <Form.Item
            name="discount"
            label={disableableLabel(
              'Anytime before arrival, set a default discount rate to',
              isRuleActive
            )}
            noStyle={false}
          >
            <CustomInput
              className="discountInput"
              suffix="%"
              onWheel={(e) => e.currentTarget.blur()}
              min={0}
              id="discountInput"
              disabled={!isRuleActive}
              parser={parseInputNumber}
            />
          </Form.Item>
        );

      case 'lastMinuteDiscount':
        return (
          <div className="section-input">
            <Form.Item
              name="lastMinuteDays"
              label={disableableLabel('From', isRuleActive)}
            >
              <CustomInput
                className="discountInput"
                onWheel={(e) => e.currentTarget.blur()}
                min={0}
                id="discountInput"
                disabled={!isRuleActive}
                parser={parseInputNumber}
              />
            </Form.Item>
            <Form.Item
              name="lastMinuteDiscountValue"
              label={disableableLabel(
                'days before arrival, set a default discount rate to',
                isRuleActive
              )}
            >
              <CustomInput
                className="discountInput"
                suffix="%"
                onWheel={(e) => e.currentTarget.blur()}
                min={0}
                id="lastMinuteAutoDiscountRate"
                disabled={!isRuleActive}
                parser={parseInputNumber}
              />
            </Form.Item>
          </div>
        );

      default:
        assertNever(key);
    }
  };

  const getFormValue = () => {
    switch (ruleInfo.key) {
      case 'standardDiscount':
        return formValues?.discount;
      case 'lastMinuteDiscount':
        return formValues?.lastMinuteDiscountValue;

      default:
        assertNever(ruleInfo.key);
    }
  };

  return (
    <div className={`section ${isRuleActive ? '' : 'disabled'}`}>
      <div className="section-header">
        <div className="section-title">
          <div className="section-icon">{ruleInfo.icon}</div>
          <p className="title">{ruleInfo.title}</p>
        </div>
        <Form.Item
          name={
            ruleInfo.key === 'standardDiscount'
              ? 'discountIsActivated'
              : 'lastMinuteIsActivated'
          }
          noStyle
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={!!isRuleActive}
            onChange={toggleSwitch}
          />
        </Form.Item>
      </div>
      {getRuleForm(ruleInfo.key)}
      {renderPrincingRulerError(
        ruleInfo.key,
        isRuleActive,
        formValues,
        inventory,
        isRuleInvalid
      )}
      <p className="explanation_discount">
        Staycation price = (BAR + package content){' '}
        <span
          className={`explanation_discount__percent ${
            isRuleActive ? '' : 'disabled'
          }`}
        >
          - {Math.trunc(getFormValue()) || '0'}%
        </span>
      </p>
      {!isSingleRate && (
        <div className="explanation_multiRate_information">
          <InfoCircleFilled />
          {ruleInfo.information}
        </div>
      )}
    </div>
  );
};
