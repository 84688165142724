import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { Tag } from 'app/typings/tags';
import { apiRequest } from 'app/utils/request/api';

const path = '/tags' as const;

export function useTags() {
  return useQuery({
    queryKey: [path],
    queryFn: () =>
      apiRequest({ path }).catch((err) =>
        message.error('Error fetching Tags', err)
      ) as Promise<Tag[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
