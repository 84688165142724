import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { HotelGroup } from 'app/redux/models/HotelGroup/HotelGroup';
import { apiRequest } from 'app/utils/request/api';

const path = '/hotels/groups' as const;

export function useHotelGroups() {
  return useQuery({
    queryKey: [path],
    queryFn: () =>
      apiRequest({ path }).catch((err) =>
        message.error('Error fetching hotel groups', err)
      ) as Promise<HotelGroup[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
