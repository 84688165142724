import { ThunderboltFilled, ThunderboltOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

type Props = {
  hasAutoInventoryActivated: boolean;
  setIsAutoInventoryModalOpen: (value: boolean) => void;
};

export const ButtonAutoInventory = ({
  hasAutoInventoryActivated,
  setIsAutoInventoryModalOpen,
}: Props) => {
  const { track } = useAmplitude();

  const clickButtonAutoInventory = () => {
    track('Open Auto inventory modal');
    setIsAutoInventoryModalOpen(true);
  };

  const iconAutoInventory = hasAutoInventoryActivated ? (
    <ThunderboltFilled style={{ color: '#0C3B7C' }} />
  ) : (
    <ThunderboltOutlined />
  );

  return (
    <Button
      icon={iconAutoInventory}
      onClick={clickButtonAutoInventory}
      className="AutoInventoryButton"
    >
      {!hasAutoInventoryActivated && 'Configure'} Auto inventory
    </Button>
  );
};
