import { Divider, StepProps, Steps } from 'antd';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import {
  RoomScrapped,
  RoomsWithInventory,
  SyncStatus,
} from 'app/typings/autoInventory';

import { Inventory } from '../../../../Types';
import { FormValues } from '../../Utils';
import { BAR } from '../Steps/BAR/BAR';
import { Discount } from '../Steps/Discount/Discount';
import { Preview } from '../Steps/Preview/Preview';
import { RoomMapping } from '../Steps/RoomMapping/RoomMapping';

import './AutoInventoryConfiguration.scss';

type ConfigurationProps = {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  setFormData: React.Dispatch<React.SetStateAction<FormValues>>;
  items: StepProps[];
  hotelId: number;
  values: FormValues;
  inventory: Inventory;
  scrappingRooms: RoomScrapped[];
  scrappingInventory: RoomsWithInventory[];
  setDiscountStepHasError: (val: boolean) => void;
  setScrappingRooms: (val: RoomScrapped[]) => void;
  setSyncStatus: (syncStatus: SyncStatus) => void;
  setInitConfiguration: (init: boolean) => void;
};

export const AutoInventoryConfiguration = ({
  currentStep,
  setCurrentStep,
  setFormData,
  items,
  hotelId,
  values,
  inventory,
  scrappingRooms,
  scrappingInventory,
  setDiscountStepHasError,
  setScrappingRooms,
  setSyncStatus,
  setInitConfiguration,
}: ConfigurationProps) => {
  const { track } = useAmplitude();

  const onChange = (value: number) => {
    setCurrentStep(value);

    setFormData((prevState) => ({
      ...prevState,
      ...values,
    }));

    track('Click on next step', { step: items[value].title });
  };

  const renderSteps = () => {
    switch (currentStep) {
      case 0:
        return (
          <RoomMapping
            hotelId={hotelId}
            scrappingRooms={scrappingRooms}
            setScrappingRooms={setScrappingRooms}
            setSyncStatus={setSyncStatus}
            setInitConfiguration={setInitConfiguration}
          />
        );
      case 1:
        return <BAR values={values} />;
      case 2:
        return (
          <Discount
            values={values}
            inventory={inventory}
            setDiscountStepHasError={setDiscountStepHasError}
          />
        );
      case 3:
        return (
          <Preview
            inventory={inventory}
            scrappingInventory={scrappingInventory}
            formData={values}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <Steps
        type="navigation"
        size="small"
        current={currentStep}
        onChange={onChange}
        className="site-navigation-steps"
        items={items}
      />
      <Divider />
      <div className="container-modal">{renderSteps()}</div>
    </>
  );
};
