import { Form } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import HotelService from 'app/components/commons/Hotel/Services/Service/Service';
import 'app/components/commons/Hotel/Services/Services.scss';
import {
  addService,
  formatServicesForAPI,
  isServicesValid,
  removeService,
  updateService,
} from 'app/components/commons/Hotel/Services/utils';
import {
  HotelServiceForm,
  IHotelsServicesType,
} from 'app/components/commons/Hotel/Types';
import type { Service } from 'app/redux/models/Hotel/Hotel';

import HeaderWrapper from '../HeaderWrapper/HeaderWrapper';

import './ServicesForm.scss';

export const ServicesForm: FC<Props> = ({
  handleSubmit,
  initialValue,
  hotelsServicesTypes,
}) => {
  const [form] = Form.useForm();
  const [services, setServices] = useState<Service[]>(initialValue || []);
  const add = (service: any) => addService(service, setServices);
  const update = (service: any) => updateService(service, setServices);
  const remove = (service: any) => removeService(service, setServices);

  useEffect(() => {
    if (initialValue) {
      setServices(initialValue);
    }
  }, [initialValue]);

  const formServices = form.getFieldsValue()?.services;

  const onFinish = useCallback(() => {
    const formattedServices = formatServicesForAPI(
      services,
      hotelsServicesTypes,
      formServices
    );

    handleSubmit(formattedServices as HotelServiceForm[]);
  }, [formServices, handleSubmit, services, hotelsServicesTypes]);

  const disabled = !isServicesValid(
    hotelsServicesTypes,
    services,
    formServices
  );

  return (
    <HeaderWrapper nextDisabled={disabled} handleNext={onFinish}>
      <div className="services-form">
        <div className="services-container">
          {hotelsServicesTypes.map((hotelsServicesType) => (
            <HotelService
              services={services}
              hotelsServicesType={hotelsServicesType}
              add={add}
              update={update}
              remove={remove}
              form={form}
              key={hotelsServicesType.id}
            />
          ))}
        </div>
      </div>
    </HeaderWrapper>
  );
};

type Props = {
  handleSubmit: (payload: HotelServiceForm[]) => void;
  initialValue: Service[];
  hotelsServicesTypes: IHotelsServicesType[];
};

const mapStateToProps = (state: any) => ({
  hotelsServicesTypes: state.conf.hotelsServicesTypes,
});

export default connect(mapStateToProps)(ServicesForm);
