import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';

import './HotelScore.scss';
import { HotelScoreBody } from './HotelScoreBody';
import { HotelScoreHeader } from './HotelScoreHeader';

const { Panel } = Collapse;

type HotelScoreProps = {
  isActive: boolean;
  isManualHotel: boolean;
  openBulkEdit: (clickEvent: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  openAutoInventory: (
    clickEvent: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  stockOptimisationScore: number;
};

export const HotelScore = ({
  isActive,
  isManualHotel,
  openBulkEdit,
  openAutoInventory,
  stockOptimisationScore,
}: HotelScoreProps) => {
  const { track } = useAmplitude();

  return (
    <Collapse
      className="hotel-score__content"
      bordered={false}
      expandIconPosition="right"
      expandIcon={(panelProps) =>
        panelProps.isActive ? (
          <UpOutlined style={{ fontSize: '16px' }} />
        ) : (
          <DownOutlined style={{ fontSize: '16px' }} />
        )
      }
      defaultActiveKey={isActive ? 1 : undefined}
      onChange={(activePanels) =>
        track(
          activePanels.length
            ? 'Collapse stock optimisation score'
            : 'Expand stock optimisation score'
        )
      }
    >
      <Panel
        key={1}
        header={
          <HotelScoreHeader stockOptimisationScore={stockOptimisationScore} />
        }
      >
        <HotelScoreBody
          openBulkEdit={openBulkEdit}
          openAutoInventory={openAutoInventory}
          isManualHotel={isManualHotel}
        />
      </Panel>
    </Collapse>
  );
};
