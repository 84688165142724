import { UserOutlined } from '@ant-design/icons';

import BabyLogo from 'app/components/commons/icons/BabyLogo';
import { BABY_COT_BED_CATEGORY_ID } from 'app/typings';
import { pluralize } from 'app/utils/strings';

export const RoomCapacity = ({
  capacity,
  categoryId,
}: {
  capacity?: number;
  categoryId?: number;
}) => {
  if (!capacity) {
    return <></>;
  }

  return (
    <div className="input-row bedCapacity">
      <p className="bedCapacityTitle">Capacity</p>
      <div className="bedCapacityDescription">
        {categoryId === BABY_COT_BED_CATEGORY_ID ? (
          <BabyLogo color="#B3B3B3" />
        ) : (
          <UserOutlined className="grey-icon" />
        )}{' '}
        {pluralize(capacity, 'guest', 'guests')}
      </div>
    </div>
  );
};
