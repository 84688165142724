import { Form, Radio } from 'antd';
import { FC, useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import {
  HotelServiceForm,
  IHotelsServicesType,
} from 'app/components/commons/Hotel/Types';
import { Option, Select } from 'app/components/fields/Select';
import { parseNumber } from 'app/utils/typing';

const PriceKinds: FC<Props> = ({
  service,
  hotelsServicesType,
  update,
  mode,
}) => {
  const { currencyFormatter, currencyInput } = useContext(CurrencyContext);

  if (!hotelsServicesType.priceRequired) {
    return null;
  }

  if (hotelsServicesType.serviceKinds && !service.serviceKind) {
    return null;
  }

  if (
    hotelsServicesType.serviceKinds &&
    service.serviceKind &&
    hotelsServicesType.name === 'Pets' &&
    (service.serviceKind === 'All pets' || service.serviceKind === 'Dogs') &&
    !service.customData
  ) {
    return null;
  }

  return (
    <>
      <Form.Item label="Cost" className="ant-form-item-subfield">
        <Radio.Group
          onChange={(event) => {
            update({
              ...service,
              price: event.target.value === 'paying' ? undefined : 0,
            });
          }}
          value={service.price === 0 ? 'free' : 'paying'}
          disabled={mode === 'view'}
        >
          <Radio value="free">Free of charge</Radio>
          <Radio value="paying">Applicable fees</Radio>
        </Radio.Group>
      </Form.Item>
      {service.price !== 0 && !!hotelsServicesType.priceKinds && (
        <Form.Item label=" ">
          <div className="FIELD">
            {mode !== 'view' ? (
              <div className="price-kind">
                {currencyInput({
                  min: 0,
                  value: service.price,
                  onChange: (newPrice) => {
                    update({
                      ...service,
                      price: parseNumber(newPrice),
                    });
                  },
                })}
                <Select
                  value={service.priceKind}
                  showSearch
                  onChange={(newPriceKind) => {
                    update({
                      ...service,
                      priceKind: newPriceKind,
                    });
                  }}
                  relativePopupContainer={(node) => node.parentNode.parentNode}
                >
                  {hotelsServicesType.priceKinds.map((priceKind) => (
                    <Option value={priceKind} key={priceKind}>
                      {priceKind}
                    </Option>
                  ))}
                </Select>
              </div>
            ) : (
              <div>
                {`${currencyFormatter(service.price || 0)} ${
                  service.priceKind || ''
                }`}
              </div>
            )}
          </div>
        </Form.Item>
      )}
    </>
  );
};

type Props = {
  service: HotelServiceForm;
  hotelsServicesType: IHotelsServicesType;
  update: (newService: HotelServiceForm) => void;
  mode?: string;
};

export default PriceKinds;
