import { Form, Input, Layout } from 'antd';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import Pictures from 'app/components/commons/Package/Pictures/Pictures';
import { ICover, IPackage } from 'app/components/commons/Package/Types';
import UploadList from 'app/components/commons/Uploader/UploadList/UploadList';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { trySaveCover } from 'app/redux/actions/packages';

import './Cover.scss';

const { Content } = Layout;

export const Cover: FC<Props> = ({
  pkg,
  headerMenu,
  headerExtra,
  cover,
  packageName,
  submitCover,
}) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values: any) => {
      if (pkg) {
        const formattedValues = {
          coverLabel1: values.coverLabel1,
          coverLabel2: values.coverLabel2,
          horizontalCoverPicture: values.horizontalCoverPicture[0],
          verticalCoverPicture: values.verticalCoverPicture[0],
          coverPicture: values.coverPicture[0],
          appPicture: values.appPicture[0],
          sliderPicture: values.sliderPicture[0],
        };

        submitCover(pkg.id, formattedValues);
        setMode('view');
      }
    },
    [pkg, submitCover]
  );

  const onCancel = useCallback(() => {
    setMode('view');
  }, []);

  if (!pkg || !cover) {
    return null;
  }

  const formattedValues = {
    coverLabel1: cover.coverLabel1,
    coverLabel2: cover.coverLabel2,
    horizontalCoverPicture: cover.horizontalCoverPicture
      ? [cover.horizontalCoverPicture]
      : [],
    verticalCoverPicture: cover.verticalCoverPicture
      ? [cover.verticalCoverPicture]
      : [],
    coverPicture: cover.coverPicture ? [cover.coverPicture] : [],
    appPicture: cover.appPicture ? [cover.appPicture] : [],
    sliderPicture: cover.sliderPicture ? [cover.sliderPicture] : [],
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={formattedValues}
      className="cover-form"
    >
      {(values, { resetFields, isFieldsTouched, submit }) => (
        <SDetailLayout
          title={
            packageName && packageName.length
              ? packageName
              : `Package #${pkg.id}`
          }
          mode={mode}
          onEdit={() => setMode('edit')}
          onSave={submit}
          onCancel={onCancel}
          reset={resetFields}
          isDirty={isFieldsTouched()}
          headerMenu={headerMenu}
          headerExtra={headerExtra}
          isValid={form
            .getFieldsError()
            .every((item) => item.errors.length > 0)}
        >
          <Content className="package-detail__content">
            <SCard
              title="Label"
              subtitle={
                'Text displayed over the cover images (formerly called "Package name")'
              }
              className="scard-with-pictures"
            >
              <Form.Item label="First line" name={['coverLabel1']}>
                {mode === 'edit' || !values ? (
                  <Input />
                ) : (
                  <div className="view-text">{values?.coverLabel1}</div>
                )}
              </Form.Item>
              <Form.Item label="Second line" name={['coverLabel2']}>
                {mode === 'edit' || !values ? (
                  <Input />
                ) : (
                  <div className="view-text">{values?.coverLabel2}</div>
                )}
              </Form.Item>
            </SCard>
            <SCard
              title="Horizontal format"
              subtitle="Image used to display the cover in most cases, notably the package cards and the cover on the package page on desktop"
              className="scard-with-pictures"
            >
              <Form.Item name="horizontalCoverPicture">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Cover"
                    hotelId={pkg.hotelId}
                    picturesCategories={[{ name: 'Cover', type: 'cover' }]}
                    kind="cover"
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the cover"
                    maxPictures={1}
                  />
                ) : (
                  <UploadList
                    pictures={
                      values.horizontalCoverPicture.length
                        ? values.horizontalCoverPicture
                        : formattedValues.horizontalCoverPicture
                    }
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
            <SCard
              title="Vertical format"
              subtitle="Image used to display the cover in some cases, notably for daytime packages and the cover on the package page on mobile"
              className="scard-with-pictures"
            >
              <Form.Item name="verticalCoverPicture">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Cover"
                    hotelId={pkg.hotelId}
                    picturesCategories={[{ name: 'Cover', type: 'cover' }]}
                    kind="cover"
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the cover"
                    maxPictures={1}
                  />
                ) : (
                  <UploadList
                    pictures={
                      values.verticalCoverPicture.length
                        ? values.verticalCoverPicture
                        : formattedValues.verticalCoverPicture
                    }
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
            <SCard
              title="Cover (deprecated)"
              subtitle="Former main cover, now used only for backwards compatibility"
              className="scard-with-pictures deprecated"
            >
              <Form.Item name="coverPicture">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Cover"
                    hotelId={pkg.hotelId}
                    picturesCategories={[{ name: 'Cover', type: 'cover' }]}
                    kind="cover"
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the cover"
                    maxPictures={1}
                  />
                ) : (
                  <UploadList
                    pictures={
                      values.coverPicture.length
                        ? values.coverPicture
                        : formattedValues.coverPicture
                    }
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
            <SCard
              title="App (deprecated)"
              subtitle="Former cover for mobile, now used only for backwards compatibility"
              className="scard-with-pictures deprecated"
            >
              <Form.Item name="appPicture">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="App"
                    hotelId={pkg.hotelId}
                    picturesCategories={[{ name: 'Cover', type: 'cover' }]}
                    kind="cover"
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the app"
                    maxPictures={1}
                  />
                ) : (
                  <UploadList
                    pictures={
                      values.appPicture.length
                        ? values.appPicture
                        : formattedValues.appPicture
                    }
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
            <SCard
              title="Slider (deprecated)"
              subtitle="Former cover used for the (now removed) slider, now used only for backwards compatibility"
              className="scard-with-pictures staycation-card-last deprecated"
            >
              <Form.Item name="sliderPicture">
                {mode === 'edit' ? (
                  <Pictures
                    sectionTitle="Slider"
                    hotelId={pkg.hotelId}
                    picturesCategories={[{ name: 'Cover', type: 'cover' }]}
                    kind="cover"
                    editable
                    removable
                    modalSubtitle="Select photos or upload new ones to illustrate the slider"
                    maxPictures={1}
                  />
                ) : (
                  <UploadList
                    pictures={
                      values.sliderPicture.length
                        ? values.sliderPicture
                        : formattedValues.sliderPicture
                    }
                    uploadingItems={{}}
                    previewable
                  />
                )}
              </Form.Item>
            </SCard>
          </Content>
        </SDetailLayout>
      )}
    </FormLegacyRenderProp>
  );
};

type Props = {
  headerMenu: ReactNode;
  headerExtra: Array<ReactNode>;
  packageId: number | undefined;
  pkg: IPackage | null;
  cover: ICover | null;
  packageName: string | undefined;
  submitCover: (packageId: number, values: any) => void;
};

const mapStateToProps = (state: any, props: any) => {
  const { packageId } = props;
  const pkg = state.packages.package;
  const { cover } = state.packages;
  const packageName =
    cover && cover.coverLabel1?.length > 0 && cover.coverLabel2?.length > 0
      ? `${cover.coverLabel1} ${cover.coverLabel2}`
      : undefined;

  return {
    pkg: packageId === pkg?.id ? pkg : null,
    cover: packageId === cover?.id ? cover : null,
    packageName,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  submitCover: (packageId: any, values: any) =>
    dispatch(trySaveCover({ packageId, cover: values })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cover);
