import { Menu, Modal, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { useEffect, useMemo, useState } from 'react';

import { CoverCell, TagsCell } from 'app/components/lists/cells';
import { PreviewResult } from 'app/redux/models/PreviewResult/PreviewResult';
import { Tag } from 'app/redux/models/Tag/Tag';

type PreviewModalProps = {
  visible: boolean;
  previews: PreviewResult[];
  onCancel: () => void;
  tags: Tag[];
  isPreviewLoading: boolean;
};

export const PreviewModal = ({
  previews,
  visible,
  onCancel,
  tags,
  isPreviewLoading,
}: PreviewModalProps) => {
  const [menuKey, setMenuKey] = useState('');

  const menuItems = useMemo(() => {
    const formattedClubs = previews.reduce(
      (acc: any, preview) => ({
        ...acc,
        [preview.clubId]: {
          name: preview.clubName,
          count: (acc[preview.clubId]?.count || 0) + 1,
        },
      }),
      {}
    );

    return Object.keys(formattedClubs).map((clubId) => {
      const formattedClub = formattedClubs[clubId];

      return {
        key: clubId,
        name: `${formattedClub.name} (${formattedClub.count})`,
      };
    });
  }, [previews]);

  const previewsToDisplay = useMemo(
    () =>
      previews
        .filter((preview) => preview.clubId === parseInt(menuKey, 10))
        .sort(
          (previewA, previewB) => previewB.popularity - previewA.popularity
        ),
    [previews, menuKey]
  );

  useEffect(() => {
    setMenuKey(`${menuItems[0]?.key}`);
  }, [menuItems]);

  return (
    <Modal
      width={1000}
      open={visible}
      okText="Ok"
      onCancel={onCancel}
      onOk={onCancel}
      centered
    >
      <Menu
        mode="horizontal"
        onClick={({ key }) => setMenuKey(key)}
        selectedKeys={[menuKey]}
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.key}>{item.name}</Menu.Item>
        ))}
      </Menu>
      <Table
        rowKey="id"
        loading={isPreviewLoading}
        dataSource={previewsToDisplay}
        pagination={{
          showSizeChanger: false,
          pageSize: 8,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} packages`,
        }}
        bordered={false}
      >
        <Column
          key="name"
          title="Package"
          dataIndex="name"
          render={CoverCell}
        />
        <Column
          key="tagIds"
          title="Tags"
          dataIndex="tagIds"
          render={TagsCell(tags)}
        />
        <Column key="popularity" title="Popularity" dataIndex="popularity" />
      </Table>
    </Modal>
  );
};

export default PreviewModal;
