import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Switch } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import formatDate from 'date-fns/format';
import { AlignType } from 'rc-table/lib/interface';
import { useCallback, useContext, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  DragHandle,
  DraggableTable,
} from 'app/components/commons/DraggableTable/DraggableTable';
import PictureComponent from 'app/components/commons/PictureComponent/PictureComponent';
import { ConfigContext } from 'app/context/ConfigContext/ConfigContext';
import { useGuideCategories } from 'app/hooks/data/guides/useGuideCategories';
import { useGuides } from 'app/hooks/data/guides/useGuides';
import {
  useUpdateFeaturedGuide,
  useUpdateHybridGuide,
  useUpdatePublished,
  useUpdateSortGuides,
} from 'app/hooks/data/guides/useGuidesUpdate';
import type { Club } from 'app/typings/clubs';
import type { ListGuide } from 'app/typings/guides';

type GuideListProps = {
  club: Club;
};

const ALIGN_CENTER = 'center' as AlignType;

const GuideList = ({ club }: GuideListProps) => {
  const history = useHistory();
  const config = useContext(ConfigContext);

  const { data: guides, isLoading } = useGuides();
  const { data: guideCategories, isLoading: isCategoriesLoading } =
    useGuideCategories();

  const { mutate: updatePublished } = useUpdatePublished();
  const { mutate: updateFeatured } = useUpdateFeaturedGuide();
  const { mutate: updateHybrid } = useUpdateHybridGuide();
  const { mutate: updateSort } = useUpdateSortGuides();

  const guidesList = guides?.filter((guide) => guide.clubId === club.id);

  const header = () => (
    <div className="page__actions">
      <div className="page__actions-buttons">
        <Link to={'guides/new'}>
          <Button icon={<PlusOutlined />} type="primary">
            New
          </Button>
        </Link>
      </div>
    </div>
  );

  const onClickPublish = useCallback(
    async (guide: ListGuide, value: boolean) => {
      updatePublished({ id: guide.id, published: value });
    },
    [updatePublished]
  );

  const getCategoryName = useCallback(
    (categoryId: number) => {
      return guideCategories?.find(
        (guideCategories) => guideCategories.id === categoryId
      )?.name;
    },
    [guideCategories]
  );

  const updateFeaturedDisplay = useCallback(
    (guideId: number, value: boolean) => {
      updateFeatured({ id: guideId, featured: value });
    },
    [updateFeatured]
  );

  const updateHybridDisplay = useCallback(
    (guideId: number, value: boolean) => {
      updateHybrid({ id: guideId, isHybrid: value });
    },
    [updateHybrid]
  );

  const duplicateGuide = useCallback(
    (guide: ListGuide) => {
      const queryParams = new URLSearchParams({
        duplicateId: guide.id.toString(),
      });

      history.push(`guides/new?${queryParams.toString()}`);
    },
    [history]
  );

  const stopPropagation = () => {
    return {
      onClick: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
        e.stopPropagation();
      },
    };
  };

  const columns: ColumnsType<ListGuide> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'label',
        className: 'drag-visible',
        width: '30%',
        render: (_, guide: ListGuide) => (
          <div className="cover-cell-emoji">
            <div className="cover-cell__thumbnail">
              <PictureComponent pictureId={guide.coverPicture.pictureId} />
            </div>
            <div className="cover-cell">{guide.name}</div>
          </div>
        ),
      },
      {
        title: 'Categorie',
        dataIndex: 'categoryId',
        render: (categoryId) => getCategoryName(categoryId),
      },
      {
        title: 'Last Modified',
        dataIndex: 'updatedAt',
        render: (updatedAt: Date) =>
          formatDate(new Date(updatedAt), 'yyyy-MM-dd HH:mm:ss'),
      },
      {
        title: 'Featured',
        dataIndex: 'featured',
        render: (value, guide) => (
          <Switch
            checked={value}
            onChange={(value) => updateFeaturedDisplay(guide.id, value)}
            size="small"
          />
        ),
        onCell: stopPropagation,
        align: ALIGN_CENTER,
      },
      {
        title: 'Hybrid',
        dataIndex: 'isHybrid',
        render: (value, guide) => (
          <Switch
            checked={value}
            onChange={(value) => updateHybridDisplay(guide.id, value)}
            size="small"
          />
        ),
        onCell: stopPropagation,
        align: ALIGN_CENTER,
      },
      {
        width: 100,
        className: 'drag-visible',
        onCell: () => ({ onClick: (e) => e.stopPropagation() }),
        render: (guide: ListGuide) => (
          <div className="sortOption">
            <DragHandle />
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'preview',
                    label: 'Preview',
                    onClick: () =>
                      window.open(`${config?.appUrl}/${guide.url}`),
                  },
                  {
                    key: 'edit',
                    label: <Link to={`guides/${guide.id}/edit`}>Edit</Link>,
                  },
                  {
                    key: 'published',
                    label: (
                      <div
                        onClick={() => onClickPublish(guide, !guide.published)}
                      >
                        {guide.published ? 'Unpublish' : 'Publish'}
                      </div>
                    ),
                  },
                  {
                    key: 'duplicate',
                    label: (
                      <Button
                        onClick={() => duplicateGuide(guide)}
                        type="ghost"
                      >
                        Duplicate
                      </Button>
                    ),
                  },
                ],
              }}
              trigger={['click']}
            >
              <EllipsisOutlined rotate={90} />
            </Dropdown>
          </div>
        ),
      },
    ],
    [
      config?.appUrl,
      duplicateGuide,
      getCategoryName,
      onClickPublish,
      updateFeaturedDisplay,
      updateHybridDisplay,
    ]
  );

  const onRowClick = (guide: ListGuide) => ({
    onClick: () => history.push(`guides/${guide.id}/edit`),
  });

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (guidesList && oldIndex !== newIndex) {
      const movingElement = guidesList?.[oldIndex];

      guidesList.splice(oldIndex, 1);
      guidesList.splice(newIndex, 0, movingElement);

      updateSort(guidesList.map((item) => item.id));
    }
  };

  return (
    <DraggableTable<ListGuide>
      className="guideTable"
      onSortEnd={onSortEnd}
      title={header}
      pagination={false}
      dataSource={guidesList ?? []}
      loading={isLoading || isCategoriesLoading}
      columns={columns}
      onRow={onRowClick}
    />
  );
};

export default GuideList;
