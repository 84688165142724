import { message } from 'antd';
import queryString from 'query-string';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { computePackageValue } from 'app/components/pages/Extranet/ExtranetInventory/Utils';
import * as entitiesActions from 'app/redux/actions/entities';
import * as hotelAdminActions from 'app/redux/actions/hotelAdmin';
import { get, patch, post, requestGenerator } from 'app/redux/requests';

const BILLS_URL = 'admin/bills';

export function* getInventory(
  action: ReturnType<typeof hotelAdminActions.tryFetchInventory>
) {
  const { hotelId, start } = action.payload;
  const stringifiedParams = queryString.stringify({ beginning: start });

  yield requestGenerator(
    () => put(hotelAdminActions.startLoading()),
    () => get(`hotel-admin/inventory/${hotelId}?${stringifiedParams}`),
    (result: any) =>
      all([
        put(hotelAdminActions.fetchInventorySuccess({ result })),
        put(hotelAdminActions.stopLoading()),
      ]),
    (error: any) =>
      all([
        put(hotelAdminActions.fetchInventoryError()),
        call(message.error('Error fetching Openings', error)),
      ])
  );
}

export function* saveOpenings(
  action: ReturnType<typeof hotelAdminActions.trySaveOpenings>
) {
  const { hotelId, openings, start, inventory } = action.payload;
  const formattedOpenings = openings.map((opening: any) => {
    const date: Date = new Date(opening.date);
    const computedPackagePrice = computePackageValue(opening, inventory, date);

    return {
      ...opening,
      price: computedPackagePrice,
      discountPrice: opening.discountPrice || null,
    };
  });

  yield requestGenerator(
    () => put(hotelAdminActions.startLoading()),
    () => post(`hotel-admin/openings/${hotelId}`, formattedOpenings),
    () =>
      all([
        put(hotelAdminActions.tryFetchInventory({ hotelId, start })),
        put(hotelAdminActions.resetDirtyOpenings()),
        put(hotelAdminActions.toggleIsEditing({ value: false })),
        put(hotelAdminActions.toggleValidationFailed({ value: false })),
      ]),
    (error: any) => message.error('Error saving Openings', error)
  );
}

export function* validateOpenings(
  action: ReturnType<typeof hotelAdminActions.tryValidateOpenings>
) {
  const { hotelId, openings, start, mode, inventory } = action.payload;

  yield requestGenerator(
    () => put(hotelAdminActions.startLoading()),
    () => post(`hotel-admin/validate-openings/${hotelId}`, openings),
    (result: any) => {
      const errors = result
        .filter((op: any) => !op.published)
        .find((op: any) => !!op.errorStatus.length || op.isForced);

      if (!errors) {
        return put(
          hotelAdminActions.trySaveOpenings({
            hotelId,
            openings,
            start,
            inventory,
          })
        );
      }

      return all([
        put(hotelAdminActions.stopLoading()),
        put(hotelAdminActions.setDirtyOpenings({ openings: result })),
        mode === 'edit'
          ? put(hotelAdminActions.toggleValidationFailed({ value: true }))
          : put(hotelAdminActions.toggleViewValidationFailed({ value: true })),
      ]);
    },
    (error: any) => message.error('Error saving Openings', error)
  );
}

export function* getBookings(
  action: ReturnType<typeof hotelAdminActions.tryFetchBookings>
) {
  const { hotelId, offset, search, sorting, beginning, ending, status } =
    action.payload;

  yield requestGenerator(
    () => put(hotelAdminActions.startLoading()),
    () => {
      const stringifiedParams = queryString.stringify({
        offset,
        search,
        sorting,
        beginning,
        ending,
        status,
      });

      return get(`hotel-admin/${hotelId}/bookings?${stringifiedParams}`);
    },
    (result: any) =>
      all([
        put(hotelAdminActions.fetchBookingsSuccess({ result })),
        put(hotelAdminActions.stopLoading()),
      ]),
    (error: any) =>
      all([
        put(hotelAdminActions.stopLoading()),
        call(
          message.error(
            `Error fetching Bookings for hotelId: ${hotelId}`,
            error
          )
        ),
      ])
  );
}

export function* getBookingsCSV(
  action: ReturnType<typeof hotelAdminActions.tryFetchBookingsCSV>
) {
  const { hotelId, search, sorting, beginning, ending, status } =
    action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => {
      const stringifiedParams = queryString.stringify({
        hotelId,
        search,
        sorting,
        beginning,
        ending,
        status,
      });

      return get(`hotel-admin/downloadBookings?${stringifiedParams}`);
    },
    (result: any) => {
      if (result === null) {
        message.info('No payout found for this hotel');
      }

      return put(hotelAdminActions.fetchBookingsCSVSuccess({ result }));
    },
    (error: any) =>
      message.error(
        `Error fetching BookingsCSV for hotelId: ${hotelId}`,
        error
      ),
    { expectFile: true }
  );
}

export function* getBooking(
  action: ReturnType<typeof hotelAdminActions.tryFetchBooking>
) {
  const { bookingCode } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => get(`hotel-admin/bookings/${bookingCode}`),
    (result: any) => put(hotelAdminActions.fetchBookingSuccess({ result })),
    (error: any) =>
      message.error(
        `Error fetching Booking for bookingCode: ${bookingCode}`,
        error
      )
  );
}

export function* getFeedbacks(
  action: ReturnType<typeof hotelAdminActions.tryFetchFeedbacks>
) {
  const { hotelId, offset, search, sorting, beginning, ending } =
    action.payload;

  yield requestGenerator(
    () => put(hotelAdminActions.startLoading()),
    () => {
      const stringifiedParams = queryString.stringify({
        offset,
        search,
        sorting,
        beginning,
        ending,
      });

      return get(`hotel-admin/${hotelId}/feedbacks?${stringifiedParams}`);
    },
    (result: any) =>
      all([
        put(hotelAdminActions.fetchFeedbacksSuccess({ result })),
        put(hotelAdminActions.stopLoading()),
      ]),
    (error: any) =>
      all([
        put(hotelAdminActions.fetchFeedbacksFailed()),
        call(
          message.error(
            `Error fetching Feedbacks for hotelId: ${hotelId}`,
            error
          )
        ),
      ])
  );
}

export function* getHotelMarks(
  action: ReturnType<typeof hotelAdminActions.tryFetchMarks>
) {
  const { hotelId } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => post(`hotel-admin/marks/${hotelId}`),
    (result: any) => put(hotelAdminActions.fetchMarksSuccess({ result })),
    (error: any) =>
      message.error(`Error fetching Marks for hotelId: ${hotelId}`, error)
  );
}

export function* getHotels() {
  yield requestGenerator(
    () => put(hotelAdminActions.startLoading()),
    () => get('hotels'),
    (result: any) => put(hotelAdminActions.fetchHotelsSuccess({ result })),
    (error: any) =>
      all([
        put(hotelAdminActions.stopLoading()),
        call(message.error('Error fetching hotels', error)),
      ])
  );
}

export function* tryFetchBills({
  payload,
}: ReturnType<typeof hotelAdminActions.tryFetchBills>): any {
  return yield requestGenerator(
    () => put(hotelAdminActions.startLoadingBills()),
    () => {
      const { sortingColumn, sortingOrder, offset, limit, hotelId } = payload;

      const stringifiedParams = queryString.stringify({
        hotelId,
        sortingColumn,
        sortingOrder,
        offset,
        limit,
      });

      return get(`${BILLS_URL}?${stringifiedParams}`);
    },
    (result: any) =>
      put(hotelAdminActions.fetchBillsSuccess({ bills: result })),
    (error: any) =>
      all([
        put(hotelAdminActions.fetchBillsFailure()),
        message.error('Error fetching Bills', error),
      ])
  );
}

export function* downloadBill({
  payload,
}: ReturnType<typeof hotelAdminActions.downloadBill>): any {
  const { billId, hotelId } = payload;

  const stringifiedParams = queryString.stringify({
    hotelId,
  });

  return yield requestGenerator(
    () => Promise.resolve(),
    () => get(`${BILLS_URL}/${billId}/pdf?${stringifiedParams}`),
    (result: any, response: any) => {
      const headers = response.headers.get('Content-Disposition').split('; ');

      const filename =
        headers.length === 3
          ? // Header Format: inline; filename="Staycation - Invoice F-202210-1 - VIP Paris Yacht H?tel.pdf"; filename*=utf-8''Staycation%20-%20Invoice%20F-202210-1%20-%20VIP%20Paris%20Yacht%20H%c3%b4tel.pdf
            headers[2].split("filename*=utf-8''")[1]
          : // Header Format: inline; filename="Staycation - Invoice F-202210-2 - Couvent des Minimes.pdf"
            headers[1].split('filename=')[1].replaceAll('"', '');

      return put(
        hotelAdminActions.downloadBillSuccess({
          pdf: result,
          filename: decodeURIComponent(filename),
        })
      );
    },
    (error: any) =>
      all([
        put(hotelAdminActions.downloadBillFailure()),
        message.error('Error downloading Bill', error),
      ]),
    { expectFile: true }
  );
}

export function* getContacts(
  action: ReturnType<typeof hotelAdminActions.fetchContacts>
) {
  const { hotelId } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => get(`hotel-admin/${hotelId}/contacts`),
    (res: any) => put(hotelAdminActions.fetchContactsSuccess({ res })),
    (error: any) => {
      put(hotelAdminActions.fetchContactsFailed());

      message.error(`Error fetching contacts for hotelId: ${hotelId}`, error);
    }
  );
}

export function* saveContacts(
  action: ReturnType<typeof hotelAdminActions.saveContacts>
) {
  const { hotelId, form } = action.payload;

  yield requestGenerator(
    () => Promise.resolve,
    () => post(`hotel-admin/${hotelId}/contacts`, form),
    (res: any) => {
      return all([put(hotelAdminActions.saveContactsSuccess({ res }))]);
    },
    (error: any) => {
      put(hotelAdminActions.saveContactsFailed());

      message.error(`Error saving contacts for hotelId: ${hotelId}`, error);
    }
  );
}

export function* setUnavailable(
  action: ReturnType<typeof hotelAdminActions.trySetHotelUnavailable>
) {
  const { hotelId } = action.payload;

  yield requestGenerator(
    () => put(entitiesActions.startPatchEntity('hotels')),
    () => patch(`hotels/${hotelId}/unavailable`),
    () => {
      message.success(`No more stock for hotel #${hotelId}!`);

      return put(entitiesActions.patchEntitySuccess('hotels'));
    },
    (error: any) => {
      message.error(`Failed to set hotel #${hotelId} unavailable!`);

      return put(entitiesActions.patchEntityFailure('hotels', error));
    },
    { expectJson: false }
  );
}

export default function* hotelAdminSagas() {
  yield all([
    takeEvery(hotelAdminActions.TRY_FETCH_SALE_DATE, getInventory),
    takeEvery(hotelAdminActions.TRY_SAVE_OPENINGS, saveOpenings),
    takeEvery(hotelAdminActions.TRY_VALIDATE_OPENINGS, validateOpenings),
    takeEvery(hotelAdminActions.TRY_FETCH_BOOKINGS, getBookings),
    takeEvery(hotelAdminActions.TRY_FETCH_BOOKINGS_CSV, getBookingsCSV),
    takeEvery(hotelAdminActions.TRY_FETCH_FEEDBACKS, getFeedbacks),
    takeEvery(hotelAdminActions.TRY_FETCH_HOTEL_MARKS, getHotelMarks),
    takeEvery(hotelAdminActions.TRY_FETCH_HOTELS, getHotels),
    takeEvery(
      hotelAdminActions.HOTEL_ADMIN_BILLS_TRY_FETCH_BILLS,
      tryFetchBills
    ),
    takeEvery(hotelAdminActions.HOTEL_ADMIN_BILLS_DOWNLOAD_BILL, downloadBill),
    takeEvery(hotelAdminActions.HOTEL_ADMIN_FETCH_CONTACTS, getContacts),
    takeEvery(hotelAdminActions.HOTEL_ADMIN_SAVE_CONTACTS, saveContacts),
  ]);
}
