const removeAccentRegex = /[\u0300-\u036f]/g;
const removePrefixSuffixDashRegex = /^-+|-+$/g;

export const capitalizeFirstLetter = (text: string) => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export function formatSlugTitle(title: string): string {
  title = title.normalize('NFD').replace(removeAccentRegex, '');
  title = title.toLowerCase();
  title = title.replace(/'/g, '');
  title = title.replace(/[^a-z0-9_]/g, '-');
  title = title.replace(removePrefixSuffixDashRegex, '');
  title = title.replace(/--+/g, '-');

  return title;
}

const parser = new DOMParser();

export const parseHtml = (htmlText: string) =>
  parser.parseFromString(htmlText, 'text/html').body.textContent || '';
