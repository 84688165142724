import { formatOpeningHoursForAPI } from 'app/components/commons/TimeRangeSection/utils';
import { HotelServiceType } from 'app/typings/hotels/HotelServiceType/HotelServiceType';

import { HotelServiceForm } from '../Types';

export const addService = (service: HotelServiceForm, setServices: any) =>
  setServices((previousServices: HotelServiceForm[]) => {
    const newServices = [...previousServices, service];

    return newServices;
  });

export const updateService = (service: HotelServiceForm, setServices: any) =>
  setServices((previousServices: HotelServiceForm[]) => {
    const { id, serviceTypeId } = service;
    const index = previousServices.findIndex(
      (serv: HotelServiceForm) =>
        !serv.isDeleted &&
        (id ? serv.id === id : serv.serviceTypeId === serviceTypeId)
    );

    if (index < 0) {
      return previousServices;
    }

    const newServices = [
      ...previousServices.slice(0, index),
      service,
      ...previousServices.slice(index + 1),
    ];

    return newServices;
  });

export const removeService = (service: HotelServiceForm, setServices: any) =>
  setServices((previousServices: HotelServiceForm[]) => {
    const { id, serviceTypeId } = service;
    const index = previousServices.findIndex((serv: HotelServiceForm) =>
      id ? serv.id === id : serv.serviceTypeId === serviceTypeId
    );

    if (index < 0) {
      return previousServices;
    }

    if (!id) {
      return [
        ...previousServices.slice(0, index),
        ...previousServices.slice(index + 1),
      ];
    }

    const deletedService = { ...service, isDeleted: true };
    const newServices = [
      ...previousServices.slice(0, index),
      deletedService,
      ...previousServices.slice(index + 1),
    ];

    return newServices;
  });

const isServiceOpeningHoursValid = (
  serviceType: HotelServiceType,
  service: HotelServiceForm,
  formServices: HotelServiceForm[]
) => {
  // Si on doit préciser des openingHours
  if (serviceType.openingHoursRequired) {
    const rawOpeningHours = formServices?.find(
      (formServ: HotelServiceForm) =>
        formServ.serviceTypeId === service.serviceTypeId
    )?.openingHours;

    // Si les openingHours ne sont pas fournies on renvoie une erreur
    if (!rawOpeningHours) {
      return false;
    }

    const openingHours = formatOpeningHoursForAPI(rawOpeningHours);

    // Si le type est set à Non applicable
    if (!openingHours) {
      return true;
    }

    // Si le type choisi d'openingHours est à la semaine
    if (openingHours.type === 'weekly') {
      // Si le isAllDay n'est pas à true
      if (!openingHours.isAllDay) {
        // Si aucun slot n'est renseigné on renvoie une erreur
        if (!openingHours.slots?.length) {
          return false;
        }

        // Si au moins un des slots précisés n'est pas complet on renvoie une erreur
        return openingHours.slots?.every((slot) => slot.start && slot.end);
      }
    }

    // Si le type choisi d'openingHours est à la journée
    if (openingHours.type === 'daily') {
      // Si aucun jour n'est renseigné on renvoie une erreur
      if (!openingHours.days) {
        return false;
      }

      const days = Object.keys(openingHours.days);

      // Si aucun jour n'est renseigné on renvoie une erreur
      if (!days.length) {
        return false;
      }

      // On itère sur tout les jours précisés, si on obtient un seul false on renvoie une erreur
      return !days.every((day) => {
        // Si le jour n'est pas renseigné on renvoie une erreur
        if (!openingHours.days?.[day]) {
          return false;
        }

        // Si le isAllDay est à true on ne renvoit pas d'erreur et on passe au jour d'après
        if (openingHours.days[day].isAllDay) {
          return false;
        }

        // Si aucun slot n'est renseigné on renvoie une erreur
        if (!openingHours.days[day].slots?.length) {
          return false;
        }

        // Si au moins un des slots précisés n'est pas complet on renvoie un false
        return !openingHours.days[day].slots?.every(
          (slot) => slot.start && slot.end
        );
      });
    }
  }

  return true;
};

const isServiceKindValid = (
  serviceType: HotelServiceType,
  service: HotelServiceForm
) => {
  // Si on doit préciser un serviceKind
  if (serviceType.serviceKinds) {
    // Si le serviceKind n'a pas été précisé on retourne une erreur
    if (!service.serviceKind) {
      return false;
    }

    // Si on doit préciser un customData
    if (
      serviceType.name === 'Pets' &&
      (service.serviceKind === 'All pets' || service.serviceKind === 'Dogs')
    ) {
      // Si le customData n'a pas été précisé on retourne une erreur
      if (!service.customData) {
        return false;
      }
    }
  }

  return true;
};

const isServicePriceValid = (
  serviceType: HotelServiceType,
  service: HotelServiceForm
) => {
  // Si on doit préciser un price
  if (serviceType.priceRequired) {
    // Si le price est à 0 et donc qu'il est "Free of charge" on ne renvoie pas d'erreur
    if (service.price === 0) {
      return true;
    }

    // Si le price précisé est vide renvoie une erreur
    if (!service.price) {
      return false;
    }

    // Si le priceKind n'est pas précisé on renvoie une erreur
    if (!service.priceKind) {
      return false;
    }
  }

  return true;
};

export const isServicesValid = (
  hotelsServicesTypes: HotelServiceType[],
  services: HotelServiceForm[],
  formServices: HotelServiceForm[]
) => {
  const filteredServices = services.filter(
    (service: HotelServiceForm) => !service.isDeleted
  );

  return filteredServices.every((service: HotelServiceForm) => {
    const serviceType = hotelsServicesTypes.find(
      (hotelsServicesType: HotelServiceType) =>
        hotelsServicesType.id === service.serviceTypeId
    );

    if (!serviceType) {
      return false;
    }

    const serviceOpeningHourValid = isServiceOpeningHoursValid(
      serviceType,
      service,
      formServices
    );
    const serviceKindValid = isServiceKindValid(serviceType, service);
    const servicePriceValid = isServicePriceValid(serviceType, service);

    return serviceOpeningHourValid && serviceKindValid && servicePriceValid;
  });
};

export const formatServicesForAPI = (
  services: HotelServiceForm[],
  hotelsServicesTypes: HotelServiceType[],
  formServices: HotelServiceForm[]
) =>
  services.map((service: HotelServiceForm) => {
    const hotelServiceType = hotelsServicesTypes.find(
      (hotelsServicesType: HotelServiceType) =>
        service.serviceTypeId === hotelsServicesType.id
    );

    if (hotelServiceType?.openingHoursRequired) {
      const rawOpeningHours = formServices?.find((formServ: any) =>
        formServ.id
          ? formServ.id === service.id
          : formServ.serviceTypeId === service.serviceTypeId
      )?.openingHours;

      if (!rawOpeningHours) {
        return service;
      }

      const openingHours = formatOpeningHoursForAPI(rawOpeningHours);

      return {
        ...service,
        openingHours,
      };
    }

    return service;
  });
