import { Col, Form, InputNumber, Row } from 'antd';

import SCard from 'app/components/StaycationUI/SCard/SCard';
import { Option, Select } from 'app/components/fields/Select';
import { useCountries } from 'app/hooks/data/useCountries';
import { useHotelGroups } from 'app/hooks/data/useHotelGroups';
import { NewHotel } from 'app/typings/hotels/NewHotel/NewHotel';

import { isSubCityRequired } from '../utils';

import CustomInput from './CustomInput/CustomInput';
import './FirstStep.scss';
import { GetLucky } from './GetLucky/GetLucky';

const RULES = [{ required: true, message: 'Required' }];

const FirstStep = ({ initializeForm }: Props) => {
  const { data: countries } = useCountries();
  const { data: hotelGroups } = useHotelGroups();

  const form = Form.useFormInstance();
  const valueCity = Form.useWatch(['address', 'city'], form);

  return (
    <SCard title="Basic information" className="first-step">
      <GetLucky onSelect={initializeForm} />
      <CustomInput
        name="name"
        label="Hotel name"
        required
        className="width-440"
      />
      <Row>
        <Col span={20}>
          <div className="width-104">
            <Form.Item name="stars" label="Stars" rules={RULES}>
              <InputNumber min={0} max={5} />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <CustomInput name="phone" label="Phone" required className="width-200" />
      <CustomInput
        name={['address', 'street']}
        label="Street"
        required
        className="width-440"
      />
      <CustomInput
        name={['address', 'zipCode']}
        label="ZIP"
        required
        className="width-104"
      />
      <CustomInput
        name={['address', 'city']}
        label="City"
        required
        className="width-320"
      />
      <CustomInput
        name={['address', 'subCity']}
        label="Subcity"
        className="width-320"
        required={isSubCityRequired(valueCity)}
      />
      <CustomInput
        name={['address', 'department']}
        label="Department"
        required
        className="width-320"
      />
      <CustomInput
        name={['address', 'region']}
        label="Region"
        required
        className="width-320"
      />
      <Row>
        <Col span={20}>
          <div className="width-200">
            <Form.Item
              name={['address', 'countryId']}
              label="Country"
              rules={RULES}
            >
              <Select>
                {countries?.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <div className="width-200">
            <Form.Item name="groupId" label="Club" rules={RULES}>
              <Select>
                {hotelGroups?.map((hotelGroup) => (
                  <Option value={hotelGroup.id} key={hotelGroup.id}>
                    {hotelGroup.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <Form.Item
            className="width-200"
            name="parentHotelId"
            label="Parent hotel Id"
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <CustomInput name="subway" label="Subway" className="width-320" />
      <Row>
        <Col span={20}>
          <div className="width-200">
            <Form.Item
              name={['location', 'coords', 'lat']}
              label="Lat"
              rules={RULES}
            >
              <InputNumber />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <div className="width-200">
            <Form.Item
              name={['location', 'coords', 'lng']}
              label="Lng"
              rules={RULES}
            >
              <InputNumber />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </SCard>
  );
};

type Props = {
  initializeForm: (data: Partial<NewHotel>) => void;
};

export default FirstStep;
