import {
  CHANNEL_MANAGER_LABELS,
  ChannelManager,
} from 'app/typings/channelManagers';

export const getChannelManagerLink = (
  channelManager: ChannelManager | undefined
) => {
  if (channelManager === ChannelManager.D_EDGE) {
    return 'https://extranet.availpro.com/Planning/Monthly';
  }

  if (channelManager === ChannelManager.SITEMINDER) {
    return 'https://authx.siteminder.com/login';
  }

  if (channelManager === ChannelManager.RESERVIT) {
    return 'https://securestatic.reservit.com/bo/login/2';
  }

  if (channelManager === ChannelManager.CUBILIS) {
    return 'https://my.stardekk.com';
  }

  if (channelManager === ChannelManager.TRAVELCLICK) {
    return 'https://otaconnect-cert.travelclick.com/';
  }

  if (channelManager === ChannelManager.ROOMCLOUD) {
    return 'https://secure.roomcloud.net/be/owners_area/index.jsp';
  }

  return '';
};

export const getChannelManagerLabel = (channelManager: ChannelManager) => {
  return (
    CHANNEL_MANAGER_LABELS.find(({ manager }) => manager === channelManager)
      ?.label || 'None'
  );
};

export const ChannelManagerDataSource = (
  Object.keys(ChannelManager) as Array<keyof typeof ChannelManager>
).map((key) => ({
  id: ChannelManager[key] as ChannelManager,
  name:
    CHANNEL_MANAGER_LABELS.find((cml) => cml.manager === ChannelManager[key])
      ?.label || 'Unknown',
}));
