import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/redux/store';

const getHotels = (state: RootState) => state.hotels;

export const getHotelsHotels = createSelector(
  getHotels,
  (hotels) => hotels.hotels
);

export const getHotelsHotel = createSelector(
  getHotels,
  (hotels) => hotels.hotel
);

export const getHotelsLoading = createSelector(
  getHotels,
  (hotels) => hotels.isLoading
);

export const getHotel = createSelector(getHotels, (hotels) => hotels.hotel);
