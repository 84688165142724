import moment from 'moment';

import {
  AvailableOn,
  DEFAULT_AVAILABLE_ON,
  WeekDays,
} from 'app/components/commons/Experience/Availability/utils';

import type { Picture } from '../Picture/Picture';

export type DateRange = {
  start: Date;
  end: Date;
};

export type Experience = {
  readonly id: number;
  readonly categoryId: number;
  readonly coverPicture?: Picture;
  readonly emoji: any; // Can be null
  readonly description: string | null;
  readonly hotelId?: number;
  readonly countryId?: number;
  readonly included: boolean;
  readonly name: string | null;
  readonly pictures: Array<any>;
  readonly price: number;
  readonly priceDescription: string | null;
  readonly purchasePrice?: number;
  readonly publicPrice?: number;
  readonly openingHours: any; // Can be null
  readonly published: boolean;
  readonly brand: string | null;
  readonly roomServiceAvailable: boolean;
  readonly roomServiceIncluded: boolean;
  readonly spaceIds: Array<number>;
  readonly accessMode: string | null;
  readonly roomServicePrice: number | null;
  readonly quantity: number | null;
  readonly quantityDescription: string | null;
  readonly duration: number | null;
  readonly durationDescription: string | null;
  readonly time: moment.Moment | string | null;
  readonly locations: string | null;
  readonly closingPeriods: any;
  readonly closedHoursBeforeCheckin: number | null;
  readonly addOnLimitAvailabilityUnit: 'hours' | 'days';
  readonly maxQuantity: number | null;
  readonly availableDays: WeekDays[];
  readonly availableDates: string[];
  readonly availableDayLimits: AvailableOn[];
};

const defaultState: Experience = {
  id: 0,
  categoryId: 0,
  description: null,
  hotelId: undefined,
  countryId: undefined,
  included: false,
  name: null,
  pictures: [],
  coverPicture: undefined,
  price: 0,
  priceDescription: null,
  purchasePrice: undefined,
  publicPrice: undefined,
  openingHours: null,
  brand: null,
  published: false,
  spaceIds: [],
  accessMode: null,
  emoji: null,
  roomServiceIncluded: false,
  roomServiceAvailable: false,
  roomServicePrice: null,
  quantity: null,
  quantityDescription: null,
  duration: null,
  durationDescription: null,
  time: null,
  locations: null,
  closingPeriods: [],
  closedHoursBeforeCheckin: null,
  addOnLimitAvailabilityUnit: 'hours',
  maxQuantity: null,
  availableDays: [],
  availableDates: [],
  availableDayLimits: DEFAULT_AVAILABLE_ON,
};

export function createExperience(values: any): Experience {
  if (!values) {
    return defaultState;
  }

  const {
    id,
    description,
    hotelId,
    countryId,
    included,
    name,
    pictures,
    price,
    purchasePrice,
    publicPrice,
    published,
    categoryId,
    coverPicture,
    emoji,
    accessMode,
    roomServiceIncluded,
    roomServiceAvailable,
    spaceIds,
    roomServicePrice,
    quantity,
    quantityDescription,
    duration,
    durationDescription,
    priceDescription,
    openingHours,
    brand,
    time,
    locations,
    closingPeriods,
    closedHoursBeforeCheckin,
    addOnLimitAvailabilityUnit,
    maxQuantity,
    availableDays,
    availableDayLimits,
    availableDates,
  } = values;

  return {
    id,
    description: description || null,
    hotelId,
    countryId,
    included,
    name: name || null,
    pictures,
    coverPicture,
    price,
    purchasePrice,
    publicPrice,
    published,
    categoryId,
    emoji: emoji || null,
    accessMode: accessMode || null,
    roomServiceIncluded,
    roomServiceAvailable,
    spaceIds,
    roomServicePrice: roomServicePrice || null,
    quantity: quantity || null,
    quantityDescription: quantityDescription || null,
    duration: duration || null,
    durationDescription: durationDescription || null,
    priceDescription: priceDescription || null,
    openingHours: openingHours || null,
    brand: brand || null,
    time: time ? moment(values.time, 'HH:mm') : null,
    locations: locations || null,
    closingPeriods: closingPeriods.map((cp: any) => {
      return {
        start: new Date(cp.start),
        end: new Date(cp.end),
      };
    }),
    closedHoursBeforeCheckin,
    addOnLimitAvailabilityUnit,
    maxQuantity,
    availableDays,
    availableDates,
    availableDayLimits,
  };
}
