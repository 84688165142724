import { FormInstance, FormListFieldData } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { LocationSelect } from 'app/components/commons/LocationSelect/LocationSelect';
import { HeaderWrapper } from 'app/components/pages/Edito/commons/Detail/HeaderWrapper/HeaderWrapper';
import { Coordinates } from 'app/redux/models/Coordinates/Coordinates';
import { IEditoEventForm } from 'app/typings/edito';
import { placeFromCoords } from 'app/utils/googlePlace/googlePlace';
import { placesService } from 'app/utils/googlePlace/services';
import { PlaceResult } from 'app/utils/googlePlace/typings';

import './LocationForm.scss';

type Props = {
  field: FormListFieldData;
  form: FormInstance<IEditoEventForm>;
};

export const LocationForm = ({ field, form }: Props) => {
  const [initialValue, setInitialValue] = useState('');

  const setPlace = useCallback(
    async (coords: Coordinates) => {
      const place = await placeFromCoords(coords);

      if (place) {
        form.setFieldValue(
          ['sections', field.name, 'searchFilters', 'placeId'],
          place.place_id
        );

        const addressComponents = place.address_components.filter((address) =>
          address.types.includes('locality')
        );

        if (addressComponents.length) {
          const locality = addressComponents[0].long_name;

          setInitialValue(locality);

          form.setFieldValue(
            ['sections', field.name, 'searchFilters', 'location'],
            locality
          );
        }
      }
    },
    [field.name, form]
  );

  useEffect(() => {
    const coords = form.getFieldValue([
      'sections',
      field.name,
      'searchFilters',
      'coords',
    ]);

    if (coords) {
      setPlace(coords);
    }
  }, [field.name, form, setPlace]);

  const setPlaceFromPlaceResult = (placeResult: PlaceResult) => {
    const coordinates = placeResult.geometry?.location;

    if (coordinates) {
      const coords = {
        lng: coordinates.lng(),
        lat: coordinates.lat(),
      };

      form.setFieldValue(
        ['sections', field.name, 'searchFilters', 'coords'],
        coords
      );

      form.setFieldValue(
        ['sections', field.name, 'searchFilters', 'location'],
        placeResult.name
      );
    }
  };

  const coordsFromPlaceId = (placeId: string) => {
    placesService.getDetails(
      {
        placeId,
        fields: ['geometry', 'name'],
      },
      setPlaceFromPlaceResult
    );
  };

  return (
    <div className="locationForm">
      <HeaderWrapper isBold={false} title="Location">
        <LocationSelect
          renderLocationLabel={(location) => (
            <div>
              <span>{location.text}</span>
              <span>{location.subtext && ` (${location.subtext})`}</span>
            </div>
          )}
          onSelectPlaceId={coordsFromPlaceId}
          formItemProps={{
            name: [field.name, 'searchFilters', 'placeId'],
          }}
          initialValue={initialValue}
          placePredictionTypes={['locality']}
          minCharacters={0}
          placeholder="Search for a location"
        />
      </HeaderWrapper>
    </div>
  );
};
