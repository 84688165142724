import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Layout, Table } from 'antd';
import classNames from 'classnames';
import fileDownload from 'js-file-download';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import ActionCellMenu from 'app/components/lists/ActionCellMenu';
import { StatusCell } from 'app/components/lists/cells';
import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import {
  useAmplitude,
  useAmplitudeViewPage,
} from 'app/hooks/useAmplitude/useAmplitude';
import type { HotelAdminTryFetchBookingsPayload } from 'app/redux/actions/hotelAdmin';
import {
  tryFetchBookings,
  tryFetchBookingsCSV,
} from 'app/redux/actions/hotelAdmin';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import {
  ExtranetBooking,
  ExtranetBookingStatus,
} from 'app/redux/models/ExtranetBooking/ExtranetBooking';
import { PAX } from 'app/typings/PAX';
import { formatSortingForAPI } from 'app/utils/sort';
import { toSnakeCase } from 'app/utils/strings';

import 'styles/pages/Extranet/extranet-table.scss';

import { ExtranetHeader } from '../commons/ExtranetHeader/ExtranetHeader';

import ModalCheckBox from './ModalCheckBox';
import BookingPopin from './Popin';

const { Content } = Layout;
const { Column } = Table;

declare type SortOrder = 'descend' | 'ascend' | null;

const { RangePicker } = DatePicker;

const PAGE = 'Bookings';

const formatAdults = (adults: number) =>
  `${adults} ${adults === 1 ? 'adult' : 'adults'}`;
const formatChildren = (children: number) =>
  `${children} ${children === 1 ? 'child' : 'children'}`;
const formatBabies = (babies: number) =>
  `${babies} ${babies === 1 ? 'baby' : 'babies'}`;
const formatPax = (customer: PAX) =>
  `${formatAdults(customer.adults)}, ${formatChildren(
    customer.children
  )}, ${formatBabies(customer.babies)}`;
const formatDate = (date: string) => moment(date).format('DD/MM/YYYY');

const sortingIntitialState = [
  {
    fieldName: 'checkin',
    sortOrder: 'DESC',
  },
  {
    fieldName: 'createdAt',
    sortOrder: 'DESC',
  },
];

const BOOKING_CSV_FILE_NAME = 'bookings.csv';

const ExtranetInventory = () => {
  const hotelId = useAppSelector((state) => state.hotelAdmin.hotelId);
  const bookings = useAppSelector((state) => state.hotelAdmin.bookings);
  const isLoading = useAppSelector((state) => state.hotelAdmin.isLoading);
  const bookingsCSV = useAppSelector((state) => state.hotelAdmin.bookingsCSV);

  const dispatch = useAppDispatch();

  const { setPage } = useContext(TaskCenterContext);

  setPage(PAGE);

  const { currencyFormatter } = useContext(CurrencyContext);
  const [popinBookingCode, setPopinBookingCode] = useState<string | undefined>(
    undefined
  );
  const [search, setSearch] = useState('');
  const [sortQueryParams, setSortQueryParams] = useState(sortingIntitialState);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);
  const [statusFilter, setStatusFilter] = useState<ExtranetBookingStatus[]>([
    ExtranetBookingStatus.Accepted,
    ExtranetBookingStatus.Cancelled,
  ]);
  const [askToDownloadCSV, setAskToDownloadCSV] = useState(false);
  const { track } = useAmplitude();

  const formatSorting = useCallback(() => {
    if (!sortQueryParams.length) return undefined;

    return sortQueryParams.map(
      (sortParam) =>
        `${toSnakeCase(sortParam.fieldName)}:${sortParam.sortOrder}`
    );
  }, [sortQueryParams]);

  const makePayload = useCallback(
    (definedHotelId: number) => ({
      hotelId: definedHotelId,
      offset: (pageNumber - 1) * 50,
      search: search || undefined,
      sorting: formatSorting() || undefined,
      beginning: startDate,
      ending: endDate,
      status: statusFilter,
    }),
    [pageNumber, search, formatSorting, startDate, endDate, statusFilter]
  );

  const debounceFetch = useMemo(
    () =>
      debounce(
        (params: HotelAdminTryFetchBookingsPayload) =>
          dispatch(tryFetchBookings(params)),
        800
      ),
    [dispatch]
  );

  useEffect(() => {
    if (hotelId !== undefined) {
      debounceFetch(makePayload(hotelId));
    }
  }, [debounceFetch, makePayload, hotelId]);

  useEffect(() => {
    setPageNumber(1);
  }, [
    setPageNumber,
    hotelId,
    search,
    startDate,
    endDate,
    statusFilter,
    sortQueryParams,
  ]);

  useEffect(() => {
    if (askToDownloadCSV && !bookingsCSV.isLoading) {
      setAskToDownloadCSV(false);
      fileDownload(bookingsCSV.data, BOOKING_CSV_FILE_NAME);
    }
  }, [askToDownloadCSV, bookingsCSV]);

  useAmplitudeViewPage({ page: 'bookings' });

  const handleFilterDate = (interval: RangeValue<Moment>) => {
    if (!interval || !interval[0] || !interval[1]) {
      setStartDate(undefined);
      setEndDate(undefined);
    } else {
      setStartDate(interval[0].format('YYYY-MM-DD'));
      setEndDate(interval[1].format('YYYY-MM-DD'));
    }
  };

  const handleSorting = (field: string, sort: SortOrder) => {
    const newSortQueryParams = sortQueryParams.filter(
      (sortParam) => sortParam.fieldName !== field
    );

    if (sort) {
      newSortQueryParams.unshift({
        fieldName: field,
        sortOrder: formatSortingForAPI(sort),
      });
    }

    setSortQueryParams(newSortQueryParams);
  };

  const downloadCSV = () => {
    track('Download Booking CSV');
    setAskToDownloadCSV(true);

    if (hotelId) {
      dispatch(tryFetchBookingsCSV(makePayload(hotelId)));
    }
  };

  const handleStatusFilter = (statuses: ExtranetBookingStatus[]) => {
    if (!statuses.length) {
      setStatusFilter([
        ExtranetBookingStatus.Accepted,
        ExtranetBookingStatus.Cancelled,
      ]);
    } else {
      setStatusFilter(statuses);
    }
  };

  const pagination = (className: string) => (
    <div className={className}>
      <Button
        disabled={pageNumber === 1}
        onClick={() => setPageNumber(pageNumber - 1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{pageNumber}</Button>
      <Button
        disabled={bookings.length < 50}
        onClick={() => setPageNumber(pageNumber + 1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search here..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
        <div className="checkin-input-title">Check-in period:</div>
        <RangePicker
          onChange={handleFilterDate}
          format="ddd DD MMM"
          ranges={{
            Today: [moment(), moment()],
            'This Week': [
              moment().startOf('isoWeek'),
              moment().endOf('isoWeek'),
            ],
          }}
          className="range-picker"
          size="large"
        />
      </div>
      <div className="right">
        <Button
          onClick={downloadCSV}
          className="button"
          size="large"
          loading={bookingsCSV.isLoading}
        >
          Download .csv
        </Button>
        {pagination('button-wrapper')}
      </div>
    </div>
  );

  const handleRowActions = ({ code }: ExtranetBooking) => ({
    onClick: () => setPopinBookingCode(code),
  });

  const handleActionClick = ({ code }: ExtranetBooking) =>
    setPopinBookingCode(code);

  return (
    <Layout className="extranet-table">
      <ExtranetHeader page={PAGE} />
      <Content className="body">
        <Table
          dataSource={bookings}
          pagination={false}
          rowKey="code"
          onRow={handleRowActions}
          title={header}
          loading={isLoading}
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { field, order } = sorter;

            handleSorting(field, order);
          }}
        >
          <Column
            title="Date"
            dataIndex="createdAt"
            render={formatDate}
            sorter
          />
          <Column
            title="Guest"
            render={({ customer, pax }) => (
              <div style={{ minWidth: 200 }}>
                <div>{`${customer.gender} ${customer.firstName} ${customer.lastName}`}</div>
                <div style={{ color: '#8C8C8C' }}>{formatPax(pax)}</div>
              </div>
            )}
          />
          <Column
            title="Check-in"
            dataIndex="checkin"
            render={formatDate}
            sorter
          />
          <Column title="Check-out" dataIndex="checkout" render={formatDate} />
          <Column
            title="Number of nights"
            dataIndex="nbNight"
            render={(nbNight) => (
              <div
                className={classNames([
                  { 'ug-multinight-bookings': nbNight > 1 },
                ])}
              >
                {nbNight}
              </div>
            )}
          />
          <Column
            title="Room"
            dataIndex="roomCategory"
            render={(roomCategory) => (
              <div style={{ minWidth: 200 }}>{roomCategory}</div>
            )}
          />
          <Column title="Extras" dataIndex="extrasCount" />
          <Column
            title="Total incl. VAT"
            dataIndex="totalAmount"
            render={(totalAmount) => `${currencyFormatter(totalAmount || 0)}`}
          />
          <Column title="Booking ID" dataIndex="code" />
          <Column
            title={
              <ModalCheckBox
                title="Status"
                defaultValue={[
                  ExtranetBookingStatus.Accepted,
                  ExtranetBookingStatus.Cancelled,
                ]}
                onChange={handleStatusFilter}
                items={[
                  {
                    value: ExtranetBookingStatus.Accepted,
                    label: 'Confirmed',
                  },
                  {
                    value: ExtranetBookingStatus.Cancelled,
                    label: 'Cancelled',
                  },
                ]}
                value={statusFilter}
              />
            }
            dataIndex="status"
            render={StatusCell}
            className="sort-column"
          />
          <Column
            key="actions"
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            render={(entity) => (
              <ActionCellMenu
                entity={entity}
                handleActionClick={handleActionClick}
              />
            )}
          />
        </Table>
        {pagination('footer')}
      </Content>
      <BookingPopin
        bookingCode={popinBookingCode}
        onClose={() => setPopinBookingCode(undefined)}
      />
    </Layout>
  );
};

export default ExtranetInventory;
