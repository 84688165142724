import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { PackageListItem } from 'app/typings/packages';
import { apiRequest } from 'app/utils/request/api';

export const listPath = '/packages' as const;

export function usePackages() {
  return useQuery({
    queryKey: [listPath],
    queryFn: () => apiRequest({ path: listPath }) as Promise<PackageListItem[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
