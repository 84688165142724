import { isBefore, isFuture } from 'date-fns';

import {
  EditoEventListCategory,
  EditoEventStatus,
  FilterCategory,
  IEditoEventFormStatusInfo,
  IEditoEventList,
  IEditoEventStatusInfo,
  SearchCriteria,
  SearchCriteriaOption,
  TagCriteria,
  editoEventStatuses,
} from 'app/typings/edito';

import { isBetween } from './dates';
import { DEFAULT_FILTERS, DEFAULT_PAX } from './filters/filters';

const editoStatuses: [
  EditoEventStatus,
  (edito: IEditoEventStatusInfo) => boolean
][] = [
  ['draft', (edito) => !edito.published && !edito.publishedAt],
  [
    'scheduled',
    (edito) => edito.published && isFuture(new Date(edito.startDate)),
  ],
  [
    'featured',
    (edito) =>
      edito.published &&
      isBetween(new Date(edito.startDate), new Date(edito.endDate)),
  ],
  [
    'published',
    (edito) => edito.published && isBefore(new Date(edito.endDate), new Date()),
  ],
  ['unpublished', (edito) => !edito.published && !!edito.publishedAt],
];

export const getEditoEventStatus = (
  edito: IEditoEventStatusInfo
): EditoEventStatus => {
  for (const [status, matchStatus] of editoStatuses) {
    if (matchStatus(edito)) {
      return status;
    }
  }

  throw new Error('No matching edito event status found');
};

export const getEditoEventFormStatus = (
  editoEvent: IEditoEventFormStatusInfo
): EditoEventStatus => {
  if (editoEvent.startDate && editoEvent.endDate) {
    return getEditoEventStatus({
      endDate: editoEvent.endDate,
      published: editoEvent.published,
      startDate: editoEvent.startDate,
      publishedAt: editoEvent.publishedAt,
    });
  }

  return 'draft';
};

export const sortEditoEventsByUpdatedAt = (editoEvents: IEditoEventList[]) => {
  return editoEvents.sort(
    (event1, event2) => event1.updatedAt.getTime() - event2.updatedAt.getTime()
  );
};

export const sortEditoEventsByClub = (editoEvents: IEditoEventList[]) => {
  return editoEvents.sort(
    (event1, event2) => (event1.clubId ?? 0) - (event2.clubId ?? 0)
  );
};

export const sortFeaturedEditoEventsByStatus = (
  editoEvents: IEditoEventList[]
): IEditoEventList[] => {
  const sortedDrafts = editoEvents
    .filter((event) => event.status === 'draft')
    .sort(
      (event1, event2) =>
        event1.updatedAt.getTime() - event2.updatedAt.getTime()
    );
  const sortedLiveScheduled = editoEvents
    .filter((event) => ['featured', 'scheduled'].includes(event.status))
    .sort(
      (event1, event2) =>
        (event1.displayOrder ?? 0) - (event2.displayOrder ?? 0)
    );

  return [...sortedLiveScheduled, ...sortedDrafts];
};

export const sortEditoEvents = (
  editoEvents: IEditoEventList[],
  isFeatured: boolean,
  sortByClub: boolean
) => {
  if (sortByClub) {
    return sortEditoEventsByClub(editoEvents);
  }

  return isFeatured
    ? sortFeaturedEditoEventsByStatus(editoEvents)
    : sortEditoEventsByUpdatedAt(editoEvents);
};

export type EventConfirmAction =
  | 'update'
  | 'updateAndMoveToFeatured'
  | 'updateAndRemoveFromFeatured'
  | 'updateAndMoveToPublished'
  | 'updateAndSchedule';

const updateStatuses: EditoEventStatus[] = [
  'featured',
  'published',
  'scheduled',
  'unpublished',
];
const updateAndMoveToFeaturedStatuses: EditoEventStatus[] = [
  'scheduled',
  'published',
];
const updateAndMoveToPublishedStatuses: EditoEventStatus[] = ['scheduled'];
const updateAndRemoveFromFeaturedStatuses: EditoEventStatus[] = ['featured'];
const updateAndScheduleStatuses: EditoEventStatus[] = ['published'];

const eventConfirmChecks: [
  EventConfirmAction,
  (
    editoEvent: { startDate: Date; endDate: Date },
    editoStatus: EditoEventStatus
  ) => boolean
][] = [
  [
    'updateAndMoveToFeatured',
    (editoEvent, editoStatus) => {
      return (
        updateAndMoveToFeaturedStatuses.includes(editoStatus) &&
        isBefore(editoEvent.startDate, new Date()) &&
        isFuture(editoEvent.endDate)
      );
    },
  ],
  [
    'updateAndMoveToPublished',
    (editoEvent, editoStatus) => {
      return (
        updateAndMoveToPublishedStatuses.includes(editoStatus) &&
        isBefore(editoEvent.startDate, new Date()) &&
        isBefore(editoEvent.endDate, new Date())
      );
    },
  ],
  [
    'updateAndRemoveFromFeatured',
    (editoEvent, editoStatus) => {
      return (
        updateAndRemoveFromFeaturedStatuses.includes(editoStatus) &&
        (isBefore(editoEvent.endDate, new Date()) ||
          isFuture(editoEvent.startDate))
      );
    },
  ],
  [
    'updateAndSchedule',
    (editoEvent, editoStatus) => {
      return (
        updateAndScheduleStatuses.includes(editoStatus) &&
        isFuture(editoEvent.startDate)
      );
    },
  ],
  [
    'update',
    (_editoEvent, editoStatus) => {
      return updateStatuses.includes(editoStatus);
    },
  ],
];

export const getEventEditionConfirmAction = (
  editoEvent: { startDate: Date; endDate: Date },
  editoStatus: EditoEventStatus
): EventConfirmAction => {
  for (const [status, checkStatus] of eventConfirmChecks) {
    if (checkStatus(editoEvent, editoStatus)) {
      return status;
    }
  }

  throw new Error('No matching edito event confirm action found');
};

export const getEditoEventListCategory = (query: string | null) => {
  return isEditoEventListCategory(query) ? query : 'featured';
};

export const isEditoEventListCategory = (
  query: string | null
): query is EditoEventListCategory => {
  return !!query && (query === 'featured' || query === 'allEvents');
};

export const getEditoEventStatusFromQuery = (query: string | null) => {
  return isEditoEventStatus(query) ? query : 'all';
};

export const isEditoEventStatus = (
  query: string | null
): query is EditoEventStatus => {
  return !!query && editoEventStatuses.includes(query as EditoEventStatus);
};

export const searchCriteriaOptions: SearchCriteriaOption[] = [
  {
    label: 'Location',
    value: 'coords',
  },
  {
    label: 'Dates',
    value: 'dates',
  },
  {
    label: 'PAX configuration',
    value: 'pax',
  },
  {
    label: 'Experience filter',
    value: 'experience',
  },
  {
    label: 'Distance filter',
    value: 'distances',
  },
  {
    label: 'Budget filter',
    value: 'prices',
  },
  {
    label: 'Style filter',
    value: 'style',
  },
  {
    label: 'Situation filter',
    value: 'situation',
  },
  {
    label: 'Room filter',
    value: 'room',
  },
  {
    label: 'Accessibility filter',
    value: 'accessibility',
  },
  {
    label: 'Sort by',
    value: 'sort',
  },
];

export const searchCriteriaDefaultValue: {
  [criteria in SearchCriteria]: { name: string[]; value: string | number }[];
} = {
  pax: [
    {
      name: ['pax', 'adults'],
      value: DEFAULT_PAX.adults,
    },
    {
      name: ['pax', 'babies'],
      value: DEFAULT_PAX.babies,
    },
    {
      name: ['pax', 'children'],
      value: DEFAULT_PAX.children,
    },
  ],
  dates: [],
  sort: [{ name: ['sort'], value: DEFAULT_FILTERS.sort }],
  accessibility: [],
  prices: [],
  distances: [],
  experience: [],
  room: [],
  situation: [],
  style: [],
  coords: [],
};

export const filterCategoryByCriteria: {
  [criteria in TagCriteria]: FilterCategory[];
} = {
  experience: ['foodAndDrinks', 'experiences'],
  room: ['room'],
  situation: ['location'],
  style: ['style'],
};
