import { message } from 'antd';
import queryString from 'query-string';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as roomsActions from 'app/redux/actions/rooms';
import { del, get, post, requestGenerator } from 'app/redux/requests';

export function* tryFetchRoom({ roomId }) {
  yield requestGenerator(
    () => put(roomsActions.startLoading()),
    () => get(`rooms/${roomId}`),
    (room) => put(roomsActions.fetchRoomSuccess(room)),
    (error) => {
      message.error('Error fetching Room', error);

      return put(roomsActions.stopLoading());
    }
  );
}

export function* tryFetchHotelRooms({ hotelId }) {
  yield requestGenerator(
    () => put(roomsActions.startLoading()),
    () => get(`hotels/${hotelId}/rooms`),
    (rooms) =>
      all([
        put(roomsActions.fetchHotelRoomsSuccess(rooms)),
        put(roomsActions.stopLoading()),
      ]),
    () =>
      all([
        put(roomsActions.stopLoading()),
        message.error('Error fetching Hotel Rooms'),
      ])
  );
}

export function* trySaveHotelRoom({ hotelId, room }) {
  yield requestGenerator(
    () => put(roomsActions.startLoading()),
    () => post(`rooms`, room),
    () =>
      all([
        put(roomsActions.tryFetchHotelRooms(hotelId)),
        message.success('Room saved'),
      ]),
    () =>
      all([put(roomsActions.stopLoading()), message.error('Error saving room')])
  );
}

export function* tryDeleteHotelRoom({ roomId, hotelId }) {
  yield requestGenerator(
    () => put(roomsActions.startLoading()),
    () => del(`rooms/${roomId}`),
    () =>
      all([
        put(roomsActions.tryFetchHotelRooms(hotelId)),
        message.success('Room deleted'),
      ]),
    () =>
      all([
        put(roomsActions.stopLoading()),
        message.error('Error deleting room'),
      ])
  );
}

export function* tryFetchRooms({
  search,
  limit,
  sortingColumn,
  sortingOrder,
  offset,
  isPublished,
}) {
  yield requestGenerator(
    () => put(roomsActions.startLoading()),
    () => {
      const stringifiedParams = queryString.stringify({
        search,
        limit,
        sortingColumn,
        sortingOrder,
        offset,
        isPublished,
      });

      return get(`rooms?${stringifiedParams}`);
    },
    (result) =>
      all([
        put(roomsActions.fetchRoomsSuccess(result)),
        put(roomsActions.stopLoading()),
      ]),
    (error) => {
      message.error('Error fetching Rooms', error);

      return put(roomsActions.stopLoading());
    }
  );
}

export default function* roomsSaga() {
  yield all([
    takeEvery(roomsActions.ROOMS_TRY_FETCH_ROOM, tryFetchRoom),
    takeEvery(roomsActions.ROOMS_TRY_FETCH_HOTEL_ROOMS, tryFetchHotelRooms),
    takeEvery(roomsActions.ROOMS_TRY_SAVE_HOTEL_ROOM, trySaveHotelRoom),
    takeEvery(roomsActions.ROOMS_TRY_DELETE_HOTEL_ROOM, tryDeleteHotelRoom),
    takeEvery(roomsActions.ROOMS_TRY_FETCH_ROOMS, tryFetchRooms),
  ]);
}
