import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { apiRequest } from 'app/utils/request/api';

export const path = '/hotel-admin/:hotelId/score' as const;

export function useHotelScore(hotelId?: number) {
  return useQuery({
    queryKey: [path, { hotelId }],
    queryFn: !!hotelId
      ? () =>
          apiRequest({
            path,
            params: { hotelId: hotelId.toString() },
          }) as Promise<{ stockOptimisationScore: number }>
      : undefined,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!hotelId,
  });
}
