import type { HotelBrief } from 'app/redux/models/HotelBrief/HotelBrief';
import type { Location } from 'app/redux/models/Location/Location';
import type { Space } from 'app/redux/models/Space/Space';
import { BillingInterval } from 'app/typings/billingIntervals';
import { ChannelManager } from 'app/typings/channelManagers';
import { RateMode } from 'app/typings/rateModes';

type Address = {
  street: string;
  zipCode: string;
  city: string;
  subCity?: string;
  department: string;
  region: string;
  countryId: number;
  full: string;
};

type Dimensions = {
  width: number;
  height: number;
};

type StreetPicture = {
  readonly pictureId: string;
  readonly name: string;
  readonly size: number;
  readonly updatedAt: string;
  readonly id: number;
  readonly dimensions: Dimensions;
};

export type Service = {
  readonly serviceTypeId: number;
  readonly serviceKind?: string;
  readonly price?: number;
  readonly openingHours?: any;
  readonly priceKind?: string;
  readonly customData?: string;
  readonly id: number;
  readonly hotelId?: number;
  readonly isDeleted: boolean;
};

type HotelService = {
  safety: Array<string>;
  services: Array<Service>;
};

type HotelContact = {
  readonly firstName: string;
  readonly lastName: string;
  readonly job: string;
  readonly email: string;
  readonly phone: string;
};

type Invoicing = {
  gender: string;
  firstName: string;
  lastName: string;
  email: string;
  city?: string;
  zipCode?: string;
  street?: string;
  country?: string;
};

type HotelFinance = {
  bank: string;
  iban: string;
  vat: string;
  invoicing: Invoicing;
};

type GuestContact = {
  readonly firstName?: string;
  readonly lastName?: string;
  readonly jobTitle?: string;
};

export type NotifEmails = {
  readonly email: string;
  readonly bookingConfirmation: boolean;
  readonly billing: boolean;
  readonly stock: boolean;
  readonly role?: string;
};

export type Hotel = {
  readonly id: number;
  readonly groupId: number;
  readonly name: string;
  readonly stars: number;
  readonly address: Address;
  readonly location: Location;
  readonly phone: string;
  readonly notifEmails: Array<NotifEmails>;
  readonly roomCount?: number;
  readonly checkInTime?: string;
  readonly checkOutTime?: string;
  readonly email?: string;
  readonly streetPictureId?: number;
  readonly streetPicture?: StreetPicture;
  readonly saleContact?: HotelContact;
  readonly services: HotelService;
  readonly spaces?: Array<Space>;
  readonly hotelAdminAccess?: boolean;
  readonly brief: HotelBrief;
  readonly pspId?: string;
  readonly pspOnboardingDone: boolean;
  readonly paymentReady: boolean;
  readonly channelManager?: ChannelManager;
  readonly rateMode?: RateMode;
  readonly subway?: string;
  readonly touristTaxId?: number;
  readonly finance?: HotelFinance;
  readonly billingEmails: Array<string>;
  readonly guestContact?: GuestContact;
  readonly countryId: number;
  readonly parentHotelId?: number;
  readonly managerId?: number;
  readonly billingInterval: BillingInterval;
  readonly stockScrapperEnabled?: boolean;
};

export type HotelAdminContact = {
  saleContact: HotelContact;
  notifEmails: Array<NotifEmails>;
  guestContact: GuestContact;
  billingEmails: Array<string>;
  email: string;
  phone: string;
};

export function createHotel(values: any): Hotel {
  const {
    id,
    groupId,
    name,
    stars,
    address,
    location,
    phone,
    notifEmails,
    roomCount,
    checkInTime,
    checkOutTime,
    email,
    streetPictureId,
    streetPicture,
    saleContact,
    services,
    spaces,
    hotelAdminAccess,
    brief,
    pspId,
    pspOnboardingDone,
    paymentReady,
    channelManager,
    rateMode,
    subway,
    touristTaxId,
    finance,
    billingEmails,
    guestContact,
    countryId,
    parentHotelId,
    managerId,
    billingInterval,
    stockScrapperEnabled,
  } = values;

  return {
    id,
    groupId,
    name,
    stars,
    address,
    location,
    phone,
    notifEmails,
    roomCount,
    checkInTime,
    checkOutTime,
    email,
    streetPictureId,
    streetPicture,
    saleContact,
    services,
    spaces,
    hotelAdminAccess,
    brief,
    pspId,
    pspOnboardingDone,
    paymentReady,
    channelManager,
    rateMode,
    subway,
    touristTaxId,
    finance,
    billingEmails,
    guestContact,
    countryId,
    parentHotelId,
    managerId,
    billingInterval,
    stockScrapperEnabled,
  };
}
