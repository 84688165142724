import { Tooltip } from 'antd';

import './AutoInventoryIcon.scss';

type AutoInventoryIconProps = {
  autoRow: boolean;
  showIcon: boolean;
};

const AutoInventoryIcon = ({ autoRow, showIcon }: AutoInventoryIconProps) => {
  if (!showIcon) {
    return <></>;
  }

  let tootlipTitle;
  let icon;

  if (showIcon && autoRow) {
    tootlipTitle = 'Auto inventory activated';
    icon = <div style={{ fontSize: '34px' }}>•</div>;
  } else if (showIcon && autoRow === false) {
    tootlipTitle = 'Auto inventory deactivated by manual edition';
    icon = <div style={{ marginTop: '3px' }}>○</div>;
  } else {
    tootlipTitle = '';
    icon = <div style={{ width: '12px', height: '17px' }}></div>;
  }

  return (
    <div className="auto-inventory-icon">
      <Tooltip
        placement="bottom"
        title={tootlipTitle}
        arrowPointAtCenter
        className="tooltip"
      >
        {icon}
      </Tooltip>
    </div>
  );
};

export default AutoInventoryIcon;
