import { CheckOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import classNames from 'classnames';
import { eachDayOfInterval, format } from 'date-fns';
import { memo, useMemo } from 'react';

import { useAppSelector } from 'app/redux/hooks';
import { OpeningForm } from 'app/redux/models/RoomOpening/RoomOpening';
import { selectHotelAdminDirtOpenings } from 'app/redux/selectors/hotelAdmin';
import { isEndOfWeek, isInDateArray, isPassed } from 'app/utils/dates';

import { OpeningStatus, OpeningsErrorMessage, Room } from '../../Types';

type Props = {
  currentRange: Interval;
  room: Room;
};

const renderTooltipWordings = (
  dirtyOpening: OpeningForm,
  currentStatus: OpeningStatus
) => {
  const getErrors = () => {
    const errors = (dirtyOpening.errorStatus ?? []).reduce<string[]>(
      (acc, error) => {
        const errorWording = OpeningsErrorMessage[error];

        return [...acc, errorWording];
      },
      []
    );

    return dirtyOpening?.isForced && dirtyOpening?.published === false
      ? [...errors, 'Rejected by account manager']
      : errors;
  };

  switch (currentStatus) {
    case OpeningStatus.PUBLISHED:
      return ['These stocks can be sold on Staycation'];
    case OpeningStatus.REJECTED:
      return getErrors();
    case OpeningStatus.UNAVAILABLE:
      return (dirtyOpening?.stock || 0) === 0
        ? ['No stocks available']
        : ['All added stocks have been sold'];
    case OpeningStatus.VALID:
      return ['The errors have been fixed'];
    case OpeningStatus.CLOSED_ON_STAYCATION:
      return ['This date is not yet for sale on Staycation'];
    case OpeningStatus.CLOSED:
      return [
        'This day is closed on your channel manager. Open it to sell these stocks on Staycation',
      ];
    default:
      throw new Error('failed to get proper opening status');
  }
};

const getStatus = (
  dirtyOpening: OpeningForm,
  opening: OpeningForm,
  shouldClose: boolean
) => {
  if (shouldClose) {
    return OpeningStatus.CLOSED_ON_STAYCATION;
  }

  if (dirtyOpening.closed) {
    return OpeningStatus.CLOSED;
  }

  if (
    dirtyOpening.stock === 0 ||
    dirtyOpening.stock - dirtyOpening.booked <= 0
  ) {
    return OpeningStatus.UNAVAILABLE;
  }

  if (dirtyOpening.published && dirtyOpening.isForced) {
    if (!opening.published) {
      return OpeningStatus.VALID;
    }

    return OpeningStatus.PUBLISHED;
  }

  if (dirtyOpening.published === false || dirtyOpening.hasWarning) {
    return OpeningStatus.REJECTED;
  }

  if (
    !dirtyOpening.hasWarning &&
    (opening.hasWarning ||
      opening.stock === 0 ||
      opening.stock - opening.booked <= 0)
  ) {
    return OpeningStatus.VALID;
  }

  if (dirtyOpening.published) {
    return OpeningStatus.PUBLISHED;
  }

  return OpeningStatus.REJECTED;
};

const renderWording = (
  currentStatus: OpeningStatus,
  dirtyOpening: OpeningForm
) => {
  switch (currentStatus) {
    case OpeningStatus.VALID:
      return <CheckOutlined />;
    case OpeningStatus.PUBLISHED:
      return 'Published';
    case OpeningStatus.REJECTED:
      return 'Rejected';
    case OpeningStatus.UNAVAILABLE:
      return dirtyOpening.stock === 0 ? 'No Stock' : 'Sold Out';
    case OpeningStatus.CLOSED:
      return 'Closed';
    case OpeningStatus.CLOSED_ON_STAYCATION:
      return 'Not for sale';
    default:
      throw new Error('failed to get proper opening status');
  }
};

const StatusRow = ({ currentRange, room }: Props) => {
  const dirtyOpenings = useAppSelector(selectHotelAdminDirtOpenings);

  const dates = useMemo(() => eachDayOfInterval(currentRange), [currentRange]);

  return (
    <Row>
      <Col className="extranet-inventory__property-cell extranet-inventory__head">
        Status
      </Col>
      {dates.map((date) => {
        const opening = room.openings.find(
          (o) => o.date === format(date, 'yyyy-MM-dd')
        );
        const dirtyOpening =
          dirtyOpenings.find(
            (o) =>
              o.date === format(date, 'yyyy-MM-dd') && o.roomId === room.room.id
          ) || opening;

        if (!opening || !dirtyOpening) {
          return null;
        }

        return (
          <Status
            key={`${format(date, 'yyyy-MM-dd')}-status-row`}
            date={date}
            dirtyOpening={dirtyOpening}
            opening={opening}
          />
        );
      })}
    </Row>
  );
};

type StatusProps = {
  date: Date;
  opening: OpeningForm;
  dirtyOpening: OpeningForm;
};

const Status = memo(({ date, opening, dirtyOpening }: StatusProps) => {
  const bookableDays = useAppSelector(
    (s) => s.hotelAdmin.inventory?.saleDate.bookableDays ?? []
  );

  const isInBookableDays = isInDateArray(date, bookableDays);
  const currentStatus = getStatus(
    dirtyOpening,
    opening,
    !isInBookableDays && !isPassed(date)
  );

  const renderTooltip = () => {
    const wordings = renderTooltipWordings(dirtyOpening, currentStatus);

    if (currentStatus === OpeningStatus.VALID || wordings.length === 0) {
      return null;
    }

    return (
      <div className="extranet-inventory-status-tooltip__container">
        <div className="extranet-inventory-status-tooltip__title">
          {wordings.map((tip, index) => (
            <div key={index}>{tip}</div>
          ))}
        </div>
      </div>
    );
  };

  const tooltip = renderTooltip();
  const wording = renderWording(currentStatus, dirtyOpening);

  const classes = [
    'extranet-inventory__property-cell',
    `extranet-inventory__property-cell--status`,
    {
      'extranet-inventory__property-cell--passed': isPassed(date),
      'extranet-inventory__property-cell--end-of-week': isEndOfWeek(date),
      'extranet-inventory__property-cell--inactive':
        !isInBookableDays || isPassed(date),
      [`extranet-inventory__property-cell--${currentStatus.toLowerCase()}`]:
        !isPassed(date),
    },
  ];

  return (
    <Tooltip
      title={tooltip}
      placement="bottom"
      overlayInnerStyle={{ width: 'fix-content' }}
      trigger={tooltip ? 'hover' : []}
      color="#000000"
    >
      <Col
        className={classNames(classes)}
        flex="1"
        key={`${format(date, 'yyyy-MM-dd')}-${
          opening ? opening.roomId : ''
        }-booked`}
      >
        {wording}
      </Col>
    </Tooltip>
  );
});

export default StatusRow;
