export enum VoucherReasonType {
  STAYCATION_ERROR = "Staycation's error",
  HOTEL_ERROR = 'Hotel error',
  BAD_FEEDBACK = 'Bad feedback',
  CLIENTS_COMPLAINTS = 'Clients complaints',
  OTHER = 'Other',
}

export type Voucher = {
  code: string;
  initialValue: number;
  remainingValue: number;
  expirationDate: string;
  createdAt: string;
  createdBy: string;
  reasonType: VoucherReasonType;
  comment?: string;
};

export type VoucherForm = {
  code?: string;
  value: number;
  beneficiary: string;
  countryId: number;
  expirationDate: string;
  bookingId: number;
  reasonType: string;
  comment?: string;
  userId: number;
  giftCardType: string;
  infinite: boolean;
};
