import { ThunderboltFilled } from '@ant-design/icons';

import DoubleThunderbolt from 'app/components/commons/DoubleThunderbolt/DoubleThunderbolt';
import { assertNever } from 'app/utils/typing';

import { Inventory } from '../../Types';
import { isValidDiscount } from '../../Utils';

export type RuleType = {
  key: 'standardDiscount' | 'lastMinuteDiscount';
  title: string;
  icon: React.ReactElement;
  information: string;
};

export const RULES: RuleType[] = [
  {
    key: 'standardDiscount',
    title: 'Standard Discount',
    icon: <ThunderboltFilled />,
    information:
      'The Staycation rate on your Channel Manager will be overridden by the pricing rule.',
  },
  {
    key: 'lastMinuteDiscount',
    title: 'Last Minute Discount',
    icon: <DoubleThunderbolt />,
    information:
      'The last minute Staycation rate on your Channel Manager will be overridden by the pricing rule.',
  },
];
export type RuleKeys = RuleType['key'];

export const disableableLabel = (label: string, cond: boolean) => (
  <label
    style={{
      color: cond ? 'rgba(0, 0, 0, 0.85)' : '#8c8c8c',
    }}
  >
    {label}
  </label>
);

type FormValue = {
  discount: number;
  lastMinuteDays: number;
  lastMinuteDiscountValue: number;
};

export const renderPrincingRulerError = (
  key: RuleKeys,
  isRuleActive: boolean,
  formValues: FormValue,
  inventory: Inventory,
  isRuleInvalid: boolean
) => {
  switch (key) {
    case 'standardDiscount':
      return renderDefaultDiscountErrors(
        isRuleActive,
        formValues,
        inventory,
        isRuleInvalid
      );

    case 'lastMinuteDiscount':
      return renderLastMinuteErrors(
        isRuleActive,
        formValues,
        inventory,
        isRuleInvalid
      );
    default:
      assertNever(key);
  }
};

export const renderDefaultDiscountErrors = (
  isRuleActive: boolean,
  formValues: FormValue,
  inventory: Inventory,
  isRuleInvalid: boolean
) => {
  const emptyRateError =
    isRuleActive && Math.trunc(formValues?.discount) == null ? (
      <div>
        Discount rate is required if you want to last minute activate pricing
        rule
      </div>
    ) : (
      <></>
    );

  const invalidRateError = isRuleInvalid ? (
    <div>
      {`Discount must be between ${inventory.pkg.dayPackage ? '0%' : '-18%'}
        and -70% of crossed rate`}
    </div>
  ) : (
    <></>
  );

  return (
    <div className="form-errors">
      {emptyRateError}
      {invalidRateError}
    </div>
  );
};

const renderLastMinuteErrors = (
  isRuleActive: boolean,
  formValues: FormValue,
  inventory: Inventory,
  isRuleInvalid: boolean
) => {
  const emptyDaysError =
    isRuleActive &&
    (formValues?.lastMinuteDays == null || formValues?.lastMinuteDays === 0) ? (
      <div>
        Number of days is required if you want to activate a last minute pricing
        rule
      </div>
    ) : (
      <></>
    );

  const emptyRateError =
    isRuleActive && Math.trunc(formValues?.lastMinuteDiscountValue) == null ? (
      <div>
        Discount rate is required if you want to last minute activate pricing
        rule
      </div>
    ) : (
      <></>
    );

  const invalidRateError = isRuleInvalid ? (
    <div>
      {`Discount must be between ${inventory.pkg.dayPackage ? '0%' : '-18%'}
          and -70% of crossed rate`}
    </div>
  ) : (
    <></>
  );

  return (
    <div className="form-errors">
      {emptyDaysError}
      {emptyRateError}
      {invalidRateError}
    </div>
  );
};

export const invalidateStandardDiscount = (
  activeRules: boolean,
  formValues: FormValue,
  inventory: Inventory,
  autoDiscountRate: number
) => {
  const hasInvalidValue =
    !formValues?.discount ||
    !isValidDiscount(
      -Math.trunc(formValues?.discount ?? 0),
      inventory.pkg.dayPackage
    );

  const valueAreSame =
    Math.trunc(autoDiscountRate * 100) === formValues?.discount;

  return activeRules && (hasInvalidValue || valueAreSame);
};

export const invalidateLastMinuteDiscount = (
  activeRules: boolean,
  formValues: FormValue,
  inventory: Inventory,
  lastMinuteAutoDiscountRate: number,
  lastMinuteAutoDiscountDays: number
) => {
  const hasInvalidValue =
    !formValues?.lastMinuteDiscountValue ||
    !isValidDiscount(
      -Math.trunc(formValues?.lastMinuteDiscountValue ?? 0),
      inventory.pkg.dayPackage
    );
  const hasInvalidDayNumber =
    !formValues?.lastMinuteDays || formValues?.lastMinuteDays === 0;

  const valueAreSame =
    Math.trunc(lastMinuteAutoDiscountRate * 100) ===
      formValues?.lastMinuteDiscountValue &&
    Math.trunc(lastMinuteAutoDiscountDays * 100) === formValues?.lastMinuteDays;

  return (
    activeRules && (hasInvalidValue || hasInvalidDayNumber || valueAreSame)
  );
};

export const canSubmitStandardDiscount = (
  activeRules: boolean,
  formValues: FormValue,
  inventory: Inventory,
  autoDiscountRate: number,
  initialDisplayValue: boolean
) => {
  const isNotEgalsToPrevious =
    Math.trunc(autoDiscountRate * 100) !== Math.trunc(formValues?.discount);
  const switchDifferentComparePrevious = initialDisplayValue !== activeRules;

  return isNotEgalsToPrevious && switchDifferentComparePrevious;
};

export const canSubmitLastMinute = (
  activeRules: boolean,
  formValues: FormValue,
  inventory: Inventory,
  lastMinuteAutoDiscountRate: number,
  lastMinuteAutoDiscountDays: number,
  initialDisplayValue: boolean
) => {
  const activateWithoutValue =
    activeRules &&
    (Math.trunc(formValues?.lastMinuteDays) == null ||
      Math.trunc(formValues?.lastMinuteDiscountValue) == null);

  const activateWithoutValidValue =
    (activeRules && formValues?.lastMinuteDays === 0) ||
    (inventory &&
      activeRules &&
      !isValidDiscount(
        -Math.trunc(formValues?.lastMinuteDiscountValue),
        inventory.pkg.dayPackage
      ));

  const isValid = !activateWithoutValidValue && !activateWithoutValue;

  const isNotEgalsToPrevious =
    isValid &&
    Math.trunc(lastMinuteAutoDiscountRate * 100) !==
      Math.trunc(formValues?.lastMinuteDiscountValue);

  const dayAreNotEgalsToPrevious =
    isValid && lastMinuteAutoDiscountDays !== formValues?.lastMinuteDays;
  const switchDifferentComparePrevious = initialDisplayValue !== activeRules;

  return (
    isValid &&
    isNotEgalsToPrevious &&
    dayAreNotEgalsToPrevious &&
    switchDifferentComparePrevious
  );
};
