import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { Guide, ListGuide } from 'app/typings/guides';
import { apiRequest } from 'app/utils/request/api';

export const listPath = '/guides' as const;
export const guidePath = '/guides/:id' as const;

export function useGuides() {
  return useQuery({
    queryKey: [listPath],
    queryFn: () => apiRequest({ path: listPath }) as Promise<ListGuide[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}

export function useGuide(guideId?: number) {
  return useQuery({
    queryKey: [guidePath, { guideId }],
    queryFn: () =>
      guideId
        ? (apiRequest({
            path: guidePath,
            params: { id: guideId.toString() },
          }) as Promise<Guide>)
        : undefined,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!guideId,
  });
}
