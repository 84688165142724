import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Input, Row, Tooltip } from 'antd';
import classNames from 'classnames';
import { Interval, eachDayOfInterval, format } from 'date-fns';

import { useAppSelector } from 'app/redux/hooks';
import type {
  OpeningForm,
  RoomOpening,
} from 'app/redux/models/RoomOpening/RoomOpening';
import {
  selectHotelAdminDirtOpenings,
  selectHotelAdminInventory,
  selectHotelAdminIsEditing,
} from 'app/redux/selectors/hotelAdmin';
import { RateMode } from 'app/typings/rateModes';
import { isEndOfWeek, isInDateArray, isPassed } from 'app/utils/dates';

import { StaycationRateTooltip } from '../../Tooltip/StaycationRateTooltip';
import { DuplicationModeData, OpeningErrors } from '../../Types';
import {
  MINIMAL_DISCOUNT_DAY_PACKAGE,
  MINIMAL_DISCOUNT_STAYCATION,
  computeDiscount,
  computePackageValue,
  getDiscountLabel,
} from '../../Utils';
import DuplicationMenu from '../DuplicationMenu/DuplicationMenu';

import './DiscountPriceRow.scss';

type Props = {
  room: RoomOpening;
  currentRange: Interval;
  handlePropertyChange: (
    dirtyOpening: OpeningForm | undefined,
    property: string,
    value: number
  ) => void;
  handleDuplicate: (
    property: 'bar' | 'stock' | 'discountPrice',
    value: number,
    day: Date,
    mode: DuplicationModeData,
    customDay?: Date
  ) => void;
  handleDuplicateHover: (
    day: Date,
    mode: DuplicationModeData,
    customDay?: Date
  ) => void;
  dropDuplicationHover: () => void;
  onFocus: (date: Date, roomId: number) => void;
  onBlur: () => void;
  discountRoomFocus: { date: Date; roomId: number } | undefined;
  autoDiscountRate?: number;
  lastMinuteAutoDiscountRate?: number;
  lastMinuteAutoDiscountDays?: number;
};

export const DiscountPriceRow = ({
  room,
  currentRange,
  handlePropertyChange,
  handleDuplicate,
  handleDuplicateHover,
  dropDuplicationHover,
  onFocus,
  onBlur,
  discountRoomFocus,
  autoDiscountRate,
  lastMinuteAutoDiscountRate,
  lastMinuteAutoDiscountDays,
}: Props) => {
  const dirtyOpenings = useAppSelector(selectHotelAdminDirtOpenings);
  const isEditing = useAppSelector(selectHotelAdminIsEditing);
  const inventory = useAppSelector(selectHotelAdminInventory);

  const bookableDays = inventory?.saleDate.bookableDays ?? [];

  const minimalDiscount = inventory?.pkg.dayPackage
    ? MINIMAL_DISCOUNT_DAY_PACKAGE
    : MINIMAL_DISCOUNT_STAYCATION;

  const isExternallyManaged =
    !!inventory?.channelManager &&
    inventory.rateMode !== RateMode.SINGLE_RATE &&
    !autoDiscountRate;

  const renderDiscountPrice = (date: Date) => {
    const opening = room.openings.find(
      (o) => o.date === format(date, 'yyyy-MM-dd')
    );
    const dirtyOpening =
      dirtyOpenings.find(
        (o) =>
          o.date === format(date, 'yyyy-MM-dd') && o.roomId === room.room.id
      ) || opening;

    const packageValue = computePackageValue(dirtyOpening, inventory, date);
    const discount = computeDiscount(dirtyOpening, packageValue);

    const isRateError =
      dirtyOpening &&
      dirtyOpening.errorStatus &&
      dirtyOpening.errorStatus.includes(OpeningErrors.EXCEED_RATE);
    const isMissingRateError =
      dirtyOpening &&
      dirtyOpening.errorStatus &&
      dirtyOpening.errorStatus.includes(OpeningErrors.MISSING_DISCOUNT_PRICE);
    const isMinimumRateError =
      dirtyOpening &&
      dirtyOpening.errorStatus &&
      dirtyOpening.errorStatus.includes(OpeningErrors.UNDER_MINIMUM_PRICE);
    const currentRateError =
      dirtyOpening &&
      dirtyOpening.discountPrice &&
      dirtyOpening.price &&
      dirtyOpening.discountPrice >
        Math.round(dirtyOpening.price * (100 - minimalDiscount));

    const discountLabel = getDiscountLabel(
      discount,
      date,
      inventory?.pkg.dayPackage || false
    );
    const openingHasError =
      !isPassed(date) &&
      ((isRateError && dirtyOpening && dirtyOpening.price) ||
        currentRateError ||
        isMissingRateError ||
        isMinimumRateError ||
        discountLabel);

    const buildCommonClassname = (date: Date) =>
      [
        'extranet-inventory__property-cell',
        'extranet-inventory__property-cell--small',
        'extranet-inventory__property-cell--without-border',
        isPassed(date) ? 'extranet-inventory__property-cell--passed' : '',
        !isInDateArray(date, bookableDays) || isPassed(date)
          ? 'extranet-inventory__property-cell--inactive'
          : '',
        isEndOfWeek(date)
          ? 'extranet-inventory__property-cell--end-of-week'
          : '',
        openingHasError ? 'extranet-inventory__property-cell--error' : '',
      ].join(' ');

    const tryDuplicate = (mode: DuplicationModeData, customDay?: Date) => {
      handleDuplicate(
        'discountPrice',
        dirtyOpening?.discountPrice || 0,
        date,
        mode,
        customDay
      );
    };

    if (!isEditing) {
      return (
        <Col
          className={classNames(buildCommonClassname(date))}
          flex="1"
          key={`${format(date, 'yyyy-MM-dd')}-${
            opening ? opening.roomId : ''
          }-discount`}
        >
          {!!opening && (
            <div className="property-cell--with-detail">
              <div className="property-cell--with-detail__content">
                {opening.discountPrice || 0}
              </div>
            </div>
          )}
        </Col>
      );
    }

    const renderInput = () => {
      const isRoomFocus =
        (discountRoomFocus &&
          format(discountRoomFocus.date, 'yyyy-MM-dd') ===
            format(date, 'yyyy-MM-dd') &&
          discountRoomFocus.roomId === room.room.id) ||
        false;

      const input = (
        <Input
          type="number"
          min={0}
          defaultValue={dirtyOpening && (dirtyOpening.discountPrice || 0)}
          disabled={isPassed(date) || isExternallyManaged}
          onChange={(e) => {
            handlePropertyChange(
              dirtyOpening,
              'discountPrice',
              parseFloat(e.target.value)
            );
          }}
          value={dirtyOpening?.discountPrice || 0}
          className={`property-cell--with-detail__input ${
            isRoomFocus ? 'property-cell--with-detail__input--focus' : ''
          }`}
          // Prevent the value to change when user scrolls.
          onWheel={(e) => e.currentTarget.blur()}
          onFocus={() => onFocus(date, room.room.id)}
          onBlur={onBlur}
        />
      );

      if (isExternallyManaged) {
        return input;
      }

      return (
        <div className="extranet-inventory__property-cell--input">
          {input}
          <DuplicationMenu
            date={date}
            currentRange={currentRange}
            tryDuplicate={tryDuplicate}
            handleDuplicateHover={handleDuplicateHover}
            dropDuplicationHover={dropDuplicationHover}
          />
        </div>
      );
    };

    return (
      <Col
        className={classNames(buildCommonClassname(date))}
        flex="1"
        key={`${format(date, 'yyyy-MM-dd')}-${
          opening ? opening.roomId : ''
        }-discount`}
      >
        <div className="property-cell--with-detail">{renderInput()}</div>
      </Col>
    );
  };

  return (
    <Row>
      <Col className="extranet-inventory__property-cell extranet-inventory__property-cell--without-border extranet-inventory__head extranet-inventory__head--small">
        Staycation rate
        <Tooltip
          title={<StaycationRateTooltip />}
          overlayClassName="extranet-inventory-tooltip"
          placement="topLeft"
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Col>
      {eachDayOfInterval(currentRange).map(renderDiscountPrice)}
    </Row>
  );
};

export default DiscountPriceRow;
