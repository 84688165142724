import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';

import { ModalData } from 'app/components/pages/Collections/CollectionDetail/CollectionSections/CollectionSections';
import {
  Collection,
  CollectionPreviewResult,
  CollectionResults,
  CollectionsFormQueryParam,
} from 'app/typings/collection';
import { apiRequest } from 'app/utils/request/api';

export const pathCollections = '/collections' as const;
export const pathCollection = '/collections/:id' as const;

const pathCollectionPreview = '/collections/preview' as const;

export function useCollections(queryParams: CollectionsFormQueryParam) {
  const queryKey = [pathCollections, { queryParams }];

  const { data, isFetching } = useQuery({
    queryKey: queryKey,
    queryFn: () =>
      apiRequest({
        path: pathCollections,
        queryParams,
      }) as Promise<CollectionResults>,
  });

  return {
    data,
    isFetching,
  };
}

export function useCollection(collectionId?: number) {
  return useQuery({
    queryKey: [pathCollection, { id: collectionId }],
    queryFn: collectionId
      ? () =>
          apiRequest({
            path: pathCollection,
            params: {
              id: collectionId.toString(),
            },
          }) as Promise<Collection>
      : undefined,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!collectionId,
  });
}

export function useCollectionPreview() {
  const queryClient = useQueryClient();

  const getPreviewResults = (queryParams: ModalData) => {
    const stringifiedParams = queryString.stringify(queryParams);

    return queryClient.fetchQuery<
      CollectionPreviewResult[],
      unknown,
      CollectionPreviewResult[]
    >({
      queryKey: [pathCollectionPreview, stringifiedParams],
      queryFn: () =>
        apiRequest({
          path: pathCollectionPreview + `?${stringifiedParams}`,
        }),
      staleTime: 5 * MILLISECONDS_IN_MINUTE,
    });
  };

  return {
    getPreviewResults,
    isLoading: !!queryClient.isFetching(),
  };
}
