import { useQuery } from '@tanstack/react-query';

import { Country } from 'app/typings/countries';
import { apiRequest } from 'app/utils/request/api';

const path = '/countries' as const;

export function useCountries() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<Country[]>,
    staleTime: Infinity,
  });
}
