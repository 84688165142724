import { Button } from 'antd';
import moment from 'moment';
import { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import Line from 'app/components/pages/GiftCards/details/Line/Line';
import type { Voucher as VoucherType } from 'app/typings/adminBookings/bookingVoucher';
import { VoucherReasonType } from 'app/typings/adminBookings/bookingVoucher';

type Props = {
  setIsVoucherModalVisible: (bool: boolean) => void;
  voucher?: VoucherType;
};

const Voucher = ({ setIsVoucherModalVisible, voucher }: Props) => {
  const { currencyFormatter } = useContext(CurrencyContext);

  if (!voucher) {
    return null;
  }

  const {
    code,
    initialValue,
    remainingValue,
    expirationDate,
    reasonType,
    comment,
    createdAt,
    createdBy,
  } = voucher;

  return (
    <div className="bloc">
      <div className="title-with-button2">
        <div className="label">Voucher</div>
        <div>
          <Button
            className="button"
            onClick={() => setIsVoucherModalVisible(true)}
          >
            Edit
          </Button>
        </div>
      </div>
      <div className="generic-content">
        <Line title="Code" value={code} />
        <Line title="Initial value" value={currencyFormatter(initialValue)} />
        <Line
          title="Remaining value"
          value={currencyFormatter(remainingValue)}
        />
        <Line
          title="Expiry date"
          value={moment(expirationDate).format('DD/MM/YYYY')}
        />
        <Line
          title="Created at"
          value={moment(createdAt).format('DD/MM/YYYY')}
        />
        <Line title="Created by" value={createdBy} />
        {/** @ts-ignore */}
        <Line title="Reason type" value={VoucherReasonType[reasonType]} />
        {!!comment && <Line title="Comment" value={comment} />}
      </div>
    </div>
  );
};

export default Voucher;
