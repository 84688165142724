import { Form } from 'antd';
import { FC, useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import {
  HotelServiceForm,
  IHotelsServicesType,
} from 'app/components/commons/Hotel/Types';
import { Option, Select } from 'app/components/fields/Select';

const ServiceKinds: FC<Props> = ({
  service,
  hotelsServicesType,
  update,
  mode,
}) => {
  const { countrySlug } = useContext(CurrencyContext);

  if (!hotelsServicesType.serviceKinds) {
    return null;
  }

  const smallDogsValue =
    countrySlug === 'GB' ? 'Small dogs (<5lbs)' : 'Small dogs (<12kg)';

  return (
    <>
      <div className="service-block">
        <Form.Item
          label={
            hotelsServicesType.name === 'Parking'
              ? 'Parking type'
              : 'Accepted pets'
          }
        >
          {mode !== 'view' ? (
            <Select
              value={service.serviceKind}
              showSearch
              onChange={(newServiceKind) => {
                update({
                  ...service,
                  serviceKind: newServiceKind,
                });
              }}
              allowClear
            >
              {hotelsServicesType.serviceKinds.map((serviceKind) => (
                <Option value={serviceKind} key={serviceKind}>
                  {serviceKind}
                </Option>
              ))}
            </Select>
          ) : (
            <div>{service.serviceKind || 'empty'}</div>
          )}
        </Form.Item>
      </div>
      {hotelsServicesType.name === 'Pets' &&
        (service.serviceKind === 'All pets' ||
          service.serviceKind === 'Dogs') && (
          <div className="service-block">
            <Form.Item label="Dog size">
              {mode !== 'view' ? (
                <Select
                  value={service.customData}
                  showSearch
                  onChange={(newCustomData) => {
                    update({
                      ...service,
                      customData: newCustomData,
                    });
                  }}
                  allowClear
                >
                  <Option value="All dogs" key="All dogs">
                    All dogs
                  </Option>
                  <Option value={smallDogsValue} key={smallDogsValue}>
                    {smallDogsValue}
                  </Option>
                </Select>
              ) : (
                <div>{service.customData || 'empty'}</div>
              )}
            </Form.Item>
          </div>
        )}
    </>
  );
};

type Props = {
  service: HotelServiceForm;
  hotelsServicesType: IHotelsServicesType;
  update: (newService: HotelServiceForm) => void;
  mode?: string;
};

export default ServiceKinds;
