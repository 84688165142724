import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import { tryFetchTags } from 'app/redux/actions/tags';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { selectTagList } from 'app/redux/selectors/tags';
import { getEditoEventDetailPath } from 'app/routes/edito';
import { IEditoEventForm, IEditoEventResponse } from 'app/typings/edito';
import { apiRequest } from 'app/utils/request/api';

import { formatEditoEventResponseToForm } from './format/details';

export const useGetEditoEventDetails = (id: number | undefined) => {
  const query = useQuery<
    IEditoEventResponse,
    unknown,
    IEditoEventResponse,
    (
      | typeof getEditoEventDetailPath
      | {
          id: number | undefined;
        }
    )[]
  >({
    queryKey: [getEditoEventDetailPath, { id }],
    queryFn: () =>
      apiRequest({
        path: getEditoEventDetailPath,
        params: { id: id?.toString() ?? '' },
      }),
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!id,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(tryFetchTags());
  }, [dispatch]);

  const tags = useAppSelector(selectTagList);

  const editoEvent = query.data;

  const initialEditoEventForm: IEditoEventForm | undefined = useMemo(() => {
    if (editoEvent) {
      return formatEditoEventResponseToForm(editoEvent, tags);
    }
  }, [editoEvent, tags]);

  return {
    ...query,
    initialEditoEventForm,
  };
};
