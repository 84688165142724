import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
} from 'antd';
import moment from 'moment';
import { useContext, useState } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { Option, Select } from 'app/components/fields/Select';
import { UserContext } from 'app/context/UserContext/UserContext';
import {
  useCreateBookingVoucher,
  useDeleteBookingVoucher,
  useUpdateBookingVoucher,
} from 'app/hooks/data/adminBookingDetails/useAdminBookingVoucher';
import {
  VoucherForm,
  VoucherReasonType,
} from 'app/typings/adminBookings/bookingVoucher';
import { BookingDetails } from 'app/typings/adminBookings/bookings';

import './VoucherModal.scss';

type Props = {
  isVisible: boolean;
  setIsVoucherModalVisible: (bool: boolean) => void;
  bookingDetails: BookingDetails;
  reloadBookingDetails: () => void;
};

export type FormType = {
  value: number;
  expirationDate?: moment.Moment;
  reasonType?: string;
  comment?: string;
};

const RULES = [{ required: true, message: 'Required' }];

const defaultFormValues: FormType = {
  value: 0,
  expirationDate: moment().add(1, 'years'),
};

const VoucherModal = ({
  isVisible,
  setIsVoucherModalVisible,
  bookingDetails,
  reloadBookingDetails,
}: Props) => {
  const { currencySymbol } = useContext(CurrencyContext);
  const { user } = useContext(UserContext);

  const getInitialValue = () => {
    if (!bookingDetails.voucher) {
      return { ...defaultFormValues };
    }

    const {
      remainingValue: value,
      expirationDate,
      reasonType,
      comment,
    } = bookingDetails.voucher;

    return {
      value,
      expirationDate: moment(expirationDate),
      reasonType,
      comment,
    };
  };

  const [formValues, handleFormValues] = useState<FormType>(getInitialValue());

  const { bookingSummary, voucher } = bookingDetails;
  const doesVoucherExist = !!voucher;

  const { mutateAsync: createBookingVoucher } = useCreateBookingVoucher();
  const { mutateAsync: deleteBookingVoucher } = useDeleteBookingVoucher(
    voucher?.code
  );
  const { mutateAsync: updateBookingVoucher } = useUpdateBookingVoucher(
    voucher?.code
  );

  const onSaveVoucher = async () => {
    if (!user) {
      return;
    }

    const { guestFirstname, guestLastname } = bookingSummary.customer;
    const { countryId, bookingId } = bookingSummary;

    const newVoucher: VoucherForm = {
      bookingId,
      countryId,
      code: voucher?.code,
      value: formValues.value,
      beneficiary: `${guestFirstname} ${guestLastname}`,
      userId: user.id,
      expirationDate: moment(
        formValues.expirationDate || moment().add(1, 'years')
      ).format(),
      reasonType: formValues.reasonType || voucher?.reasonType || 'OTHER',
      comment: formValues.comment,
      giftCardType: 'voucher',
      infinite: false,
    };

    if (doesVoucherExist) {
      await updateBookingVoucher(newVoucher);
    } else {
      await createBookingVoucher(newVoucher);
    }

    handleFormValues({
      value: newVoucher.value,
      reasonType: newVoucher.reasonType,
      expirationDate: moment(newVoucher.expirationDate),
      comment: newVoucher.comment,
    });

    setIsVoucherModalVisible(false);

    await reloadBookingDetails();
  };

  const onDeleteVoucher = async () => {
    if (voucher?.code) {
      await deleteBookingVoucher();
    }

    handleFormValues({ ...defaultFormValues });
    setIsVoucherModalVisible(false);
    await reloadBookingDetails();
  };

  const onCancel = () => {
    handleFormValues({ ...getInitialValue() });
    setIsVoucherModalVisible(false);
  };

  const isButtonDisabled =
    !formValues.reasonType || !formValues.value || !formValues.expirationDate;

  // In order to reset the form because resetFields doesn't seems to work
  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      width={560}
      visible={isVisible}
      title={doesVoucherExist ? 'Edit voucher' : 'Create voucher'}
      onCancel={onCancel}
      okText="Test"
      className="voucher-modal"
      wrapClassName="admin-modal"
      centered
      footer={
        <>
          {doesVoucherExist && (
            <Popconfirm
              title="Are you sure you want to delete this voucher ? (This action is irrevocable)"
              placement="bottomRight"
              onConfirm={onDeleteVoucher}
              okText="Yes"
              cancelText="No"
            >
              <Button>Delete</Button>
            </Popconfirm>
          )}
          <Button
            type="primary"
            disabled={isButtonDisabled}
            onClick={onSaveVoucher}
          >
            {doesVoucherExist ? 'Save' : 'Create'}
          </Button>
        </>
      }
    >
      <Form
        initialValues={formValues}
        onValuesChange={(v, h) => handleFormValues({ ...h })}
        colon={false}
      >
        <div className="input-row">
          <Form.Item
            name="value"
            label={`Value (${currencySymbol})`}
            rules={RULES}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="expirationDate" label="Expiry date" rules={RULES}>
            <DatePicker disabled={!doesVoucherExist} format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item name="reasonType" label="Reason type" rules={RULES}>
            <Select placeholder="Choose a reason">
              {(
                Object.keys(VoucherReasonType) as Array<
                  keyof typeof VoucherReasonType
                >
              ).map((reason) => (
                <Option key={reason} value={reason}>
                  {VoucherReasonType[reason]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="comment" label="Comment">
            <Input.TextArea placeholder="Optional" rows={4} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default VoucherModal;
