import { Form } from 'antd';
import { FC } from 'react';

import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import Card from 'app/components/layout/Card';
import { Hotel } from 'app/redux/models/Hotel/Hotel';

import HeaderWrapper from '../HeaderWrapper/HeaderWrapper';

import Communication from './Communication/Communication';

const ProfileForm: FC<Props> = ({
  handleSubmit,
  initialValue,
  originalBillingEmails,
}) => {
  const [form] = Form.useForm();

  return (
    <HeaderWrapper nextDisabled={false} handleNext={() => form.submit()}>
      <Card formOnly title="Profile">
        <FormLegacyRenderProp
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValue}
        >
          {(values, { setFieldsValue }) => (
            <Communication
              values={values}
              setFieldsValue={setFieldsValue}
              originalBillingEmails={originalBillingEmails}
            />
          )}
        </FormLegacyRenderProp>
      </Card>
    </HeaderWrapper>
  );
};

type Props = {
  handleSubmit: (payload: Hotel) => void;
  initialValue: Hotel;
  originalBillingEmails: string[] | undefined;
};

export default ProfileForm;
