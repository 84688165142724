import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, Radio } from 'antd';
import { useContext, useEffect, useState } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { ExtraBed } from 'app/components/pages/Extranet/commons/ExtraBed/ExtraBed';
import { FormValues } from 'app/components/pages/Rooms/RoomDetail/RoomDetail';
import { useBedCategories } from 'app/hooks/data/rooms/useBedCategories';
import {
  BABY_COT_BED_CATEGORY_ID,
  BED_TASK_CATEGORIES,
  Bed,
} from 'app/typings';

import './Beds.scss';
import { ApplicationFeesTooltip } from './_components/ApplicationFeesTooltip';
import { RoomCapacity } from './_components/RoomCapacity/RoomCapacity';
import { SelectBedByPriority } from './_components/SelectBedByPriority';

const { Panel } = Collapse;

const RULES = [{ required: true, message: 'Required' }];

type FieldData = {
  name: number;
  key: number;
};

type BedsProps = {
  fields: Array<FieldData>;
  add: (
    bed: Omit<Bed, 'included'> & { included: boolean },
    index?: number
  ) => void;
  remove: (index: number) => void;
  mode?: 'view' | 'edit';
  values: FormValues;
};

export const Beds = ({
  fields,
  add,
  remove,
  mode = 'edit',
  values,
}: BedsProps) => {
  const [activeKeys, setActiveKeys] = useState<Array<string>>(
    fields.map((field) => `${field.key}`)
  );
  const [form] = Form.useForm();
  const { currencyFormatter, currencyInput } = useContext(CurrencyContext);

  const { data: bedCategories } = useBedCategories();

  useEffect(() => {
    setActiveKeys((prevActiveKeys) => {
      const newKey = `${fields[fields.length - 1]?.key}`;
      const index = prevActiveKeys.indexOf(newKey);

      if (index < 0) {
        return [...prevActiveKeys, newKey];
      }

      return prevActiveKeys;
    });
  }, [fields]);

  const toggleActiveKey = (newActiveKeys: string | string[]) => {
    setActiveKeys(
      typeof newActiveKeys === 'string' ? [newActiveKeys] : newActiveKeys
    );
  };

  const renderHeader = (index: number, name: number) => {
    if (index === 0) {
      return 'Bed #01';
    }

    return (
      <div className="bed-header">
        {`Bed #${(index + 1).toString().padStart(2, '0')}`}
        {mode === 'edit' && <DeleteOutlined onClick={() => remove(name)} />}
      </div>
    );
  };

  return (
    <div className="beds">
      <div className="bedTitleSection">Default beds</div>

      <Collapse
        activeKey={activeKeys}
        onChange={toggleActiveKey}
        className="bedCollapse"
      >
        {fields.map(
          ({ key, name, ...restField }, index) =>
            !BED_TASK_CATEGORIES.some(
              (category) => category === values.beds?.[index].categoryId
            ) && (
              <Panel header={renderHeader(index, name)} key={key}>
                <Form.Item shouldUpdate noStyle>
                  {() =>
                    mode === 'edit' ? (
                      <>
                        <div className="input-row bedType">
                          <SelectBedByPriority
                            bedCategories={bedCategories ?? []}
                            index={index}
                            getFieldValue={form.getFieldValue}
                            name={[name, 'categoryId']}
                            label="Type"
                            rules={RULES}
                            {...restField}
                          />
                        </div>
                        {
                          <RoomCapacity
                            capacity={
                              bedCategories?.find(
                                (cat) =>
                                  cat.id ===
                                  form.getFieldValue([
                                    'beds',
                                    name,
                                    'categoryId',
                                  ])
                              )?.capacity
                            }
                          />
                        }
                      </>
                    ) : (
                      <>
                        <div className="input-row bedType">
                          <p className="bedTypeTitle">Type</p>

                          <Form.Item
                            {...restField}
                            name={[name, 'categoryId']}
                            rules={RULES}
                            noStyle
                          >
                            <div className="view-text bedTypeForm">
                              {
                                bedCategories?.find(
                                  (cat) =>
                                    cat.id === values.beds?.[name].categoryId
                                )?.name
                              }
                            </div>
                          </Form.Item>
                        </div>
                        {
                          <RoomCapacity
                            capacity={
                              bedCategories?.find(
                                (cat) =>
                                  cat.id ===
                                  form.getFieldValue([
                                    'beds',
                                    name,
                                    'categoryId',
                                  ])
                              )?.capacity
                            }
                          />
                        }
                      </>
                    )
                  }
                </Form.Item>
                {index > 0 && (
                  <>
                    <div className="input-row included bedCost">
                      <p className="bedCostTitle">Cost of bed</p>
                      <Form.Item
                        {...restField}
                        name={[name, 'included']}
                        noStyle
                      >
                        <Radio.Group
                          disabled={mode === 'view'}
                          className="bedCostForm"
                        >
                          <Radio value={true}>Free of charge</Radio>
                          <Radio value={false}>
                            Applicable fees <ApplicationFeesTooltip />
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const display =
                          getFieldValue(['beds', name, 'included']) === false;

                        if (!display) {
                          return null;
                        }

                        return mode === 'edit' ? (
                          <div className="input-row bedPrice">
                            <p className="bedPriceTitle">Fees amount</p>
                            <div className="bedPriceForm">
                              <Form.Item
                                {...restField}
                                name={[name, 'price']}
                                rules={RULES}
                              >
                                {currencyInput({ min: 0 })}
                              </Form.Item>
                            </div>
                          </div>
                        ) : (
                          <div className="input-row bedPrice">
                            <p className="bedPriceTitle">Fees amount</p>
                            <div className="bedPriceForm">
                              <Form.Item
                                {...restField}
                                name={[name, 'price']}
                                rules={RULES}
                              >
                                <div className="view-text bedPriceForm">{`${currencyFormatter(
                                  getFieldValue(['beds', name, 'price']) || 0
                                )}`}</div>
                              </Form.Item>
                            </div>
                          </div>
                        );
                      }}
                    </Form.Item>
                  </>
                )}
              </Panel>
            )
        )}
      </Collapse>
      {mode === 'edit' && (
        <div className="footer">
          <Button
            icon={<PlusOutlined />}
            onClick={() => add({ included: true })}
          >
            Add bed
          </Button>
        </div>
      )}
      <div>
        <div className="bedTitleSection">Additionnal beds</div>
        {BED_TASK_CATEGORIES.map(
          (bedTypeId, index) =>
            values.beds && (
              <ExtraBed
                key={'key' + index}
                listBed={values.beds}
                bed={values.beds.find((bed) => bed.categoryId === bedTypeId)}
                categoryName={
                  bedTypeId === BABY_COT_BED_CATEGORY_ID
                    ? 'Baby cot'
                    : 'Folding guest bed'
                }
                categoryId={bedTypeId}
                add={add}
                remove={remove}
                previousPrefixes={['beds']}
                prefix={[
                  values.beds.findIndex((bed) => bed.categoryId === bedTypeId),
                ]}
                bedCategories={bedCategories}
                mode={mode}
              />
            )
        )}
      </div>
    </div>
  );
};

export default Beds;
