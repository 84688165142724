import { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { Experience } from 'app/typings/adminBookings/bookings';

type Props = {
  exp: Experience[];
};

const SummaryExtra = ({ exp }: Props) => {
  const { currencyFormatter } = useContext(CurrencyContext);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
        textAlign: 'end',
      }}
    >
      {exp.map((extra) => {
        const total = (extra.price || 0) * extra.quantity;

        return (
          <span style={{ marginLeft: 'auto', textAlign: 'end' }}>
            {`${extra.quantity}x ${extra.category.name} • ${currencyFormatter(
              total
            )}`}
          </span>
        );
      })}
    </div>
  );
};

export default SummaryExtra;
