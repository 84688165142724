import { useQuery } from '@tanstack/react-query';

import { GuideCategory } from 'app/typings/guides';
import { apiRequest } from 'app/utils/request/api';

const path = '/guides/categories' as const;

export function useGuideCategories() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<GuideCategory[]>,
    staleTime: Infinity,
  });
}
