import { MILLISECONDS_IN_DAY } from '@stdlib/constants-time';
import * as cookie from 'cookie';

import { JSON_HEADERS, tokenToHeader } from 'app/redux/requests';
import { config } from 'app/redux/store';

import { ForgeUrlArgs, forgeUrl } from './forgeUrl';

const COOKIE_TOKEN_KEY = 'user';
const COOKIE_PATH = '/';

export async function apiRequest<Path extends string = string>(
  args: ForgeUrlArgs<Path>
) {
  const response = await apiRequestWithResponse(args);

  return response.json();
}

export async function apiRequestWithResponse<Path extends string = string>(
  args: ForgeUrlArgs<Path>
) {
  const token = getCookie(COOKIE_TOKEN_KEY);

  const response = await fetch(
    forgeUrl(config.apiUrl, args),
    token
      ? {
          headers: tokenToHeader(token),
        }
      : undefined
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch ${args.path}`);
  }

  return response;
}

type Method = 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export async function apiMutation<Path extends string = string>(
  method: Method,
  args: ForgeUrlArgs<Path>,
  payload?: unknown
) {
  const token = getCookie(COOKIE_TOKEN_KEY);
  const url = forgeUrl(config.apiUrl, args);

  const response = await fetch(url, {
    method,
    headers: {
      ...(payload ? JSON_HEADERS : undefined),
      ...(token ? tokenToHeader(token) : undefined),
    },
    body: payload ? JSON.stringify(payload) : undefined,
  });

  if (!response.ok) {
    throw new Error(`Failed to mutate ${url}`);
  }

  //Allows you to manage the case where the API returns a string
  const contentType = response.headers.get('content-type');

  if (response.status === 204) {
    return response.text();
  }

  if (response.status === 200 || response.status === 201) {
    const text = await response.text();

    try {
      return JSON.parse(text);
    } catch (error) {
      return text;
    }
  }

  if (contentType && contentType.includes('text/plain')) {
    return await response.text();
  }

  return await response.json();
}

function getCookie(
  key: string,
  options?: cookie.CookieParseOptions
): string | undefined {
  if (typeof window !== 'undefined') {
    const cookies = cookie.parse(document.cookie, options);

    const cookieValue = cookies[key];

    return typeof cookieValue === 'string' && cookieValue.length > 0
      ? cookieValue
      : undefined;
  }

  return;
}

export function setUserCookie(token: any) {
  if (typeof window !== 'undefined') {
    document.cookie = cookie.serialize(COOKIE_TOKEN_KEY, token, {
      path: COOKIE_PATH,
      maxAge: 60 * MILLISECONDS_IN_DAY,
    });
  }
}

export function removeUserCookie() {
  if (typeof window !== 'undefined') {
    document.cookie = cookie.serialize(COOKIE_TOKEN_KEY, '', {
      path: COOKIE_PATH,
      expires: new Date(1970, 1, 1, 0, 0, 1),
      maxAge: 0,
    });
  }
}
