import Line from 'app/components/pages/GiftCards/details/Line/Line';
import { type Customer } from 'app/typings/adminBookings/bookings';

type Props = {
  customer: Customer;
};

const Contact = ({ customer }: Props) => {
  const { email, phoneNumber } = customer;

  if (!email && !phoneNumber) {
    return null;
  }

  return (
    <div className="bloc">
      <div className="title">Contact</div>
      <div className="generic-content">
        {email && <Line title="E-mail" value={email} className="e-mail" />}
        {phoneNumber && <Line title="Tel" value={phoneNumber} />}
      </div>
    </div>
  );
};

export default Contact;
