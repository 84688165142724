import { Layout, PageHeader, Tabs } from 'antd';
import type { TabsProps } from 'antd/lib/tabs';
import { useHistory, useLocation } from 'react-router-dom';

import { useClubs } from 'app/hooks/data/useClubs';

import GuideList from './GuideList';
import './GuideList.scss';

const { Content } = Layout;

const GuidesListPage = () => {
  const history = useHistory();
  const location = useLocation();

  const { data: clubs } = useClubs();

  const query = new URLSearchParams(location.search);
  const initialType = query.get('club');

  const onSwitchTab = (key: string) => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set('club', key);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const tabs: TabsProps['items'] = clubs
    ?.sort((clubA, clubB) => clubA.id - clubB.id)
    .map((club) => ({
      key: club.slug,
      label: club.name,
      children: <GuideList club={club} />,
    }));

  return (
    <Layout className="page-list">
      <PageHeader title="Guides" />
      <Content>
        <Tabs
          onChange={onSwitchTab}
          items={tabs}
          defaultActiveKey={initialType ?? 'paris'}
          className="guideListTabs"
        />
      </Content>
    </Layout>
  );
};

export default GuidesListPage;
