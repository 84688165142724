import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import { Dropdown, Input, Layout, Menu, PageHeader, Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { AlignType } from 'rc-table/lib/interface';
import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  CoverCellEmoji,
  PublishedCell,
  SwitchCell,
  SwitchCellWithDisabled,
  dateTimeCell,
} from 'app/components/lists/Cells/Cells';
import Pagination from 'app/components/lists/Pagination';
import { ConfigContext } from 'app/context/ConfigContext/ConfigContext';
import { UserContext } from 'app/context/UserContext/UserContext';
import { useCollections } from 'app/hooks/data/collections/useCollections';
import {
  usePatchDisplayCollection,
  usePatchPublishedCollecton,
} from 'app/hooks/data/collections/useSaveCollections';
import { CollectionItem } from 'app/redux/models/CollectionItem/CollectionItem';
import {
  CollectionDisplayForm,
  CollectionList,
  CollectionsFormQueryParam,
} from 'app/typings/collection';
import { scrollToTop } from 'app/utils/scroll';
import {
  SortOrder,
  convertFromAntdSortOrder,
  getSortOrder,
} from 'app/utils/sort';

import ModalCheckBox from '../Extranet/ExtranetBookings/ModalCheckBox';

import HeaderExtra from './_components/HeaderExtra';
import HeaderFooter from './_components/HeaderFooter';
import { formatPublished, formatSorting } from './utils';

const { Content } = Layout;

const LIMIT = 50;

const ALIGN_CENTER = 'center' as AlignType;

enum DisplayItem {
  TrendDisplay = 'trendDisplay',
  CardDisplay = 'cardDisplay',
  SliceDisplay = 'sliceDisplay',
  SearchDisplay = 'searchDisplay',
}

export type CollectionsListQueryParam = {
  search: string;
  menuKey: string;
  pageNumber: number;
  nameSorting: SortOrder;
  updatedAtSorting: SortOrder;
  publishedFilter: boolean[];
};

const initialQueryParam: CollectionsListQueryParam = {
  menuKey: 'country-1',
  pageNumber: 1,
  nameSorting: 'none',
  updatedAtSorting: 'descend',
  publishedFilter: [true, false],
  search: '',
};

const Collections = () => {
  const location = useLocation();
  const history = useHistory();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const { mutateAsync: updateDisplay } = usePatchDisplayCollection();
  const { mutateAsync: updatePublish } = usePatchPublishedCollecton();

  const { user } = useContext(UserContext);
  const config = useContext(ConfigContext);

  const search = query.get('search') || initialQueryParam.search;
  const menuKey = query.get('menuKey') || initialQueryParam.menuKey;
  const nameSorting =
    getSortOrder(query.get('nameSorting') ?? '') ||
    initialQueryParam.nameSorting;
  const pageNumber = parseInt(
    query.get('pageNumber') || initialQueryParam.pageNumber.toString(),
    10
  );
  const updatedAtSorting =
    getSortOrder(query.get('updatedAtSorting') ?? '') ||
    initialQueryParam.updatedAtSorting;

  const publishedFilter = useMemo(() => {
    const filter = query.get('publishedFilter');

    return filter
      ? filter.split(',').map((val) => val === 'true')
      : [true, false];
  }, [query]);

  const createPayload = useCallback((): CollectionsFormQueryParam => {
    const parsed = menuKey?.split('-');
    const selectedType = parsed[0];
    const selectedId = parsed ? parseInt(parsed[1], 10) : undefined;

    return {
      search: search,
      ...(formatSorting(nameSorting, updatedAtSorting) || undefined),
      published: formatPublished(publishedFilter),
      offset: ((pageNumber || 1) - 1) * LIMIT,
      limit: LIMIT,
      countryId:
        selectedType && selectedType === 'country' ? selectedId : undefined,
      clubId: selectedType && selectedType === 'club' ? selectedId : undefined,
    };
  }, [
    menuKey,
    nameSorting,
    pageNumber,
    publishedFilter,
    search,
    updatedAtSorting,
  ]);

  const { data: collections, isFetching } = useCollections(createPayload());

  const handleOffset = async (x: number) => {
    scrollToTop();
    updateQueryParams({ pageNumber: (pageNumber + x).toString() });
  };

  const handleSearch = (nextSearch: string) => {
    updateQueryParams({ search: nextSearch, pageNumber: '1' });
  };

  const handleRowActions = (collection: CollectionItem) => {
    return {
      onClick: () => navigateToEditCollection(collection.id),
    };
  };

  const stopPropagation = () => {
    return {
      onClick: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
        e.stopPropagation();
      },
    };
  };

  const handleOnChange = (
    _: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CollectionList> | SorterResult<CollectionList>[]
  ) => {
    // @ts-ignore
    const { field, order } = sorter;

    const adaptedOrder = convertFromAntdSortOrder(order);

    updateQueryParams({
      publishedFilter: filters.published ? filters.published.join(',') : '',
      nameSorting:
        field === 'name' ? adaptedOrder : initialQueryParam.nameSorting,
      updatedAtSorting:
        field === 'updatedAt'
          ? adaptedOrder
          : initialQueryParam.updatedAtSorting,
      pageNumber: '1',
    });
  };

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      {
        <Pagination
          className="button-wrapper"
          pageNumber={pageNumber}
          nbResults={collections?.nbCollections}
          handleOffset={handleOffset}
        />
      }
    </div>
  );

  const updateCollectionDisplay = async (
    collectionId: number,
    value: boolean,
    displayPropertySet: DisplayItem
  ) => {
    const collection = collections?.collections.find(
      (c) => c.id === collectionId
    );

    if (collection) {
      const formatPayload: CollectionDisplayForm = {
        id: collectionId,
        trendDisplay: collection.trendDisplay,
        cardDisplay: collection.cardDisplay,
        sliceDisplay: collection.sliceDisplay,
        searchDisplay: collection.searchDisplay,
        [displayPropertySet]: value,
      };

      await updateDisplay(formatPayload);
    }
  };

  const updateQueryParams = (params: Record<string, string>) => {
    const oldParams = new URLSearchParams(location.search);
    const newParams = { ...Object.fromEntries(oldParams.entries()), ...params };

    const updatedParams = new URLSearchParams(newParams);

    history.push(`${location.pathname}?${updatedParams.toString()}`);
  };

  const handleIsPublishedFilter = (value: boolean[]) => {
    if (value.length === 0) {
      value.push(!publishedFilter?.[publishedFilter.length - 1]);
    }

    updateQueryParams({ publishedFilter: value.join(',') });
  };

  const publishedColumnTitle = (
    <ModalCheckBox
      title="Status"
      defaultValue={[true, false]}
      onChange={handleIsPublishedFilter}
      items={[
        {
          value: true,
          label: 'Published',
        },
        {
          value: false,
          label: 'Unpublished',
        },
      ]}
      value={publishedFilter}
    />
  );

  const handlePreviewClick = (collection: CollectionItem) =>
    window.open(`${config?.appUrl}/collections/${collection.slug}`);

  const navigateToEditCollection = (collectionId: number) =>
    history.push(`collections/${collectionId}/edit`);

  const updateCollectionPublished = async (
    collectionId: number,
    value: boolean
  ) => await updatePublish({ published: value, id: collectionId });

  const actionMenu = (collection: CollectionItem) => (
    <div className="actions-menu">
      <Dropdown
        dropdownRender={() => (
          <Menu>
            <Menu.Item
              key="preview"
              onClick={() => handlePreviewClick(collection)}
            >
              Preview
            </Menu.Item>
            <Menu.Item
              key="edit"
              onClick={() => navigateToEditCollection(collection.id)}
            >
              Edit
            </Menu.Item>
            {collection.published ? (
              <Menu.Item
                key="publish"
                onClick={() => updateCollectionPublished(collection.id, false)}
              >
                Unpublish
              </Menu.Item>
            ) : (
              <Menu.Item
                key="publish"
                onClick={() => updateCollectionPublished(collection.id, true)}
              >
                Publish
              </Menu.Item>
            )}
          </Menu>
        )}
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: CoverCellEmoji,
      sorter: (a: CollectionItem, b: CollectionItem) =>
        a.name.localeCompare(b.name),
    },
    {
      title: 'Section count',
      dataIndex: 'sectionCount',
      align: ALIGN_CENTER,
    },
    {
      title: 'Trend',
      dataIndex: 'trendDisplay',
      render: SwitchCell((id: number, value: boolean) =>
        updateCollectionDisplay(id, value, DisplayItem.TrendDisplay)
      ),
      align: ALIGN_CENTER,
      onCell: stopPropagation,
    },
    {
      title: 'Card',
      dataIndex: 'cardDisplay',
      render: SwitchCellWithDisabled(
        (id: number, value: boolean) =>
          updateCollectionDisplay(id, value, DisplayItem.CardDisplay),
        (collection: CollectionItem) =>
          !collection.cover
            ? 'You need to add a cover to enable card display.'
            : undefined
      ),
      align: ALIGN_CENTER,
      onCell: stopPropagation,
    },
    {
      title: 'Slice',
      dataIndex: 'sliceDisplay',
      render: SwitchCellWithDisabled(
        (id: number, value: boolean) =>
          updateCollectionDisplay(id, value, DisplayItem.SliceDisplay),
        (collection: CollectionItem) =>
          collection.sectionCount !== 1
            ? 'You need to have one section to enable slice display.'
            : undefined
      ),
      align: ALIGN_CENTER,
      onCell: stopPropagation,
    },
    {
      title: 'Search',
      dataIndex: 'searchDisplay',
      render: SwitchCellWithDisabled(
        (id: number, value: boolean) =>
          updateCollectionDisplay(id, value, DisplayItem.SearchDisplay),
        (collection: CollectionItem) => {
          if (user?.role !== 'superadmin') {
            return 'You need to be a superadmin to enable search display';
          }

          if (!collection.searchName) {
            return 'You need to add a search title to enable search display.';
          }

          return undefined;
        }
      ),
      align: ALIGN_CENTER,
      onCell: stopPropagation,
    },
    {
      title: 'Last modified',
      dataIndex: 'updatedAt',
      render: dateTimeCell('yyyy-MM-dd HH:mm:ss'),
      align: ALIGN_CENTER,
      sorter: (a: CollectionItem, b: CollectionItem) =>
        a.updatedAt.localeCompare(b.updatedAt),
    },
    {
      title: publishedColumnTitle,
      dataIndex: 'published',
      render: PublishedCell,
      filter: true,
      align: ALIGN_CENTER,
    },
    {
      dataIndex: 'action',
      render: (_: undefined, collection: CollectionItem) =>
        actionMenu(collection),
      onCell: () => ({
        onClick: (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation(),
      }),
      align: ALIGN_CENTER,
    },
  ];

  return (
    <Layout className="page-list">
      <PageHeader
        className="header"
        title="Collections"
        extra={<HeaderExtra />}
        footer={
          <HeaderFooter
            menuKey={menuKey}
            updateQueryParams={updateQueryParams}
          />
        }
      />
      <Content className="body">
        <Table
          dataSource={collections?.collections}
          pagination={false}
          title={header}
          loading={isFetching}
          rowKey="id"
          onRow={handleRowActions}
          onChange={handleOnChange}
          sortDirections={['ascend', 'descend', 'ascend']}
          columns={columns}
        />
        <Pagination
          className="footer"
          pageNumber={pageNumber}
          handleOffset={handleOffset}
          nbResults={collections?.nbCollections}
        />
      </Content>
    </Layout>
  );
};

export default Collections;
