import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { Guide } from 'app/typings/guides';
import { apiMutation } from 'app/utils/request/api';

import { guidePath, listPath } from './useGuides';

export const saveGuidePath = '/guides' as const;

export const useSaveGuide = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: Guide) =>
      apiMutation(
        'POST',
        {
          path: saveGuidePath,
        },
        payload
      ),
    onSuccess: (_, payload) => {
      message.success(
        payload.id
          ? `Successly saved guide #${payload.id}!`
          : `Successly created guide!`
      );

      queryClient.invalidateQueries([listPath]);
      queryClient.invalidateQueries([guidePath, { guideId: payload?.id }]);
    },
    onError: (_, payload) => {
      message.error(
        payload.id
          ? `Failed to save guide #${payload.id}`
          : 'Failed to create guide.'
      );
    },
  });
};
