import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { PAGE_LIMIT } from './constants';

type PaginationProps = {
  className: string;
  pageNumber: number;
  pageResultsLength?: number;
  handleOffset: (offset: number) => void;
  nbResults?: number;
};

const Pagination: React.FC<PaginationProps> = ({
  className,
  pageNumber,
  pageResultsLength,
  handleOffset,
  nbResults,
}: PaginationProps) => {
  const totalPages = nbResults && Math.ceil(nbResults / PAGE_LIMIT);

  return (
    <div className={className}>
      <Button disabled={pageNumber === 1} onClick={() => handleOffset(-1)}>
        <LeftOutlined />
      </Button>
      <Button>{pageNumber}</Button>
      <Button
        disabled={
          (pageResultsLength && pageResultsLength < PAGE_LIMIT) ||
          pageNumber === totalPages
        }
        onClick={() => handleOffset(1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );
};

export default Pagination;
