import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { RoomFeature } from 'app/typings';
import { apiRequest } from 'app/utils/request/api';

const path = '/rooms/features' as const;

export function useRoomFeatures() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<RoomFeature[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
