import { Badge, Tooltip } from 'antd';

import { Bill } from 'app/redux/models/Bill/Bill';
import { PayoutStatus } from 'app/typings/bills';
import { upperCaseFirstLetter } from 'app/utils/strings';
import { assertNever } from 'app/utils/typing';

export const renderBillsColumnStatus = (
  status: PayoutStatus,
  bill: Bill,
  isExtranet: boolean
) => {
  const formatStatus = upperCaseFirstLetter(
    status.toLowerCase().replace('_', ' ')
  );

  const tooltipMesssage = isExtranet
    ? 'Please contact your Account Manager for more information'
    : bill.errorMessage;

  switch (status) {
    case 'BLOCKED':
      return (
        <Tooltip title={tooltipMesssage}>
          <Badge status="warning" text={formatStatus} />
        </Tooltip>
      );
    case 'TO_SEND':
      return <Badge status="default" text={formatStatus} />;
    case 'PENDING':
      return <Badge status="processing" text={formatStatus} />;
    case 'ERROR':
      return (
        <Tooltip title={tooltipMesssage}>
          <Badge status="error" text={formatStatus} />
        </Tooltip>
      );
    case 'PAID':
      return <Badge status="success" text={formatStatus} />;

    default:
      assertNever(status);
  }
};
