import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Input, Row, Tooltip } from 'antd';
import classNames from 'classnames';
import { Interval, eachDayOfInterval, format } from 'date-fns';
import { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { useAppSelector } from 'app/redux/hooks';
import type {
  OpeningForm,
  RoomOpening,
} from 'app/redux/models/RoomOpening/RoomOpening';
import {
  selectHotelAdminDirtOpenings,
  selectHotelAdminInventory,
  selectHotelAdminIsEditing,
} from 'app/redux/selectors/hotelAdmin';
import { isPassed } from 'app/utils/dates';

import { BarRowTooltip } from '../../Tooltip/BarRowTooltip';
import { DuplicationModeData, OpeningErrors } from '../../Types';
import {
  computeCrossedPriceDescription,
  computePackageValue,
  computeRowClassNames,
} from '../../Utils';
import AutoInventoryIcon from '../AutoInventoryIcon/AutoInventoryIcon';
import { DuplicationMenu } from '../DuplicationMenu/DuplicationMenu';

import './BarRow.scss';

type Props = {
  room: RoomOpening;
  currentRange: Interval;
  stockScrapperEnabled: boolean;
  handlePropertyChange: (
    dirtyOpening: OpeningForm | undefined,
    property: string,
    value: number
  ) => void;
  handleDuplicate: (
    property: 'bar' | 'stock' | 'discountPrice',
    value: number,
    day: Date,
    mode: DuplicationModeData,
    customDay?: Date
  ) => void;
  handleDuplicateHover: (
    day: Date,
    mode: DuplicationModeData,
    customDay?: Date
  ) => void;
  dropDuplicationHover: () => void;
};

export const BarRow = ({
  room,
  currentRange,
  stockScrapperEnabled,
  handlePropertyChange,
  handleDuplicate,
  handleDuplicateHover,
  dropDuplicationHover,
}: Props) => {
  const { currencyFormatter } = useContext(CurrencyContext);
  const dirtyOpenings = useAppSelector(selectHotelAdminDirtOpenings);
  const isEditing = useAppSelector(selectHotelAdminIsEditing);
  const inventory = useAppSelector(selectHotelAdminInventory);

  const bookableDays = inventory?.saleDate.bookableDays ?? [];

  const dayPackage = inventory?.pkg.dayPackage;

  const renderBar = (date: Date) => {
    const opening = room.openings.find(
      (o) => o.date === format(date, 'yyyy-MM-dd')
    );
    const dirtyOpening =
      dirtyOpenings.find(
        (o) =>
          o.date === format(date, 'yyyy-MM-dd') && o.roomId === room.room.id
      ) || opening;

    const packageValue = computePackageValue(dirtyOpening, inventory, date);

    const tryDuplicate = (modeData: DuplicationModeData) => {
      handleDuplicate('bar', dirtyOpening?.bar || 0, date, modeData);
    };

    const hasChannelManager = !!inventory?.channelManager;

    if (!isEditing) {
      return (
        <Col
          className={classNames(computeRowClassNames(date, bookableDays))}
          flex="1"
          key={`${format(date, 'yyyy-MM-dd')}-${opening?.roomId ?? ''}-stock`}
        >
          {!!opening && (
            <div className="property-cell--with-detail">
              <div className="property-cell--with-detail__content">
                {opening.bar || 0}
              </div>
              {!dayPackage && (
                <div className="property-cell--with-detail__detail extranet-inventory__crossed-price">
                  {currencyFormatter(packageValue ?? 0)}
                </div>
              )}
            </div>
          )}
          {opening && stockScrapperEnabled && (
            <AutoInventoryIcon
              autoRow={!!opening.autoBar}
              showIcon={
                stockScrapperEnabled &&
                !!room.room.bookingcomId &&
                opening.autoBar !== undefined
              }
            />
          )}
        </Col>
      );
    }

    const isRoomError = dirtyOpening?.errorStatus?.includes(
      OpeningErrors.MISSING_BAR
    );

    const renderInput = () => {
      const input = (
        <Input
          type="number"
          min={0}
          defaultValue={dirtyOpening?.bar ?? 0}
          disabled={isPassed(date) || hasChannelManager}
          onChange={(e) =>
            handlePropertyChange(
              dirtyOpening,
              'bar',
              parseFloat(e.target.value)
            )
          }
          value={dirtyOpening?.bar}
          className="property-cell--with-detail__input"
          // Prevent the value to change when user scrolls.
          onWheel={(e) => e.currentTarget.blur()}
        />
      );

      if (hasChannelManager) {
        return input;
      }

      return (
        <div className="extranet-inventory__property-cell--input">
          {input}
          <DuplicationMenu
            date={date}
            currentRange={currentRange}
            tryDuplicate={tryDuplicate}
            handleDuplicateHover={handleDuplicateHover}
            dropDuplicationHover={dropDuplicationHover}
          />
        </div>
      );
    };

    return (
      <Col
        className={classNames([
          ...computeRowClassNames(date, bookableDays),
          {
            'extranet-inventory__property-cell--error': isRoomError,
          },
        ])}
        flex="1"
        key={`${format(date, 'yyyy-MM-dd')}-${opening?.roomId ?? ''}-stock`}
      >
        <div
          className={`property-cell--with-detail${
            stockScrapperEnabled ? '_with-space' : ''
          }`}
        >
          {renderInput()}
          {!dayPackage && (
            <div className="property-cell--with-detail__detail extranet-inventory__crossed-price">
              {currencyFormatter(packageValue ?? 0)}
            </div>
          )}
          {opening && (
            <AutoInventoryIcon
              autoRow={!!opening.autoBar}
              showIcon={
                stockScrapperEnabled &&
                !!room.room.bookingcomId &&
                opening.autoBar !== undefined
              }
            />
          )}
        </div>
      </Col>
    );
  };

  const tooltipDetail = computeCrossedPriceDescription(
    inventory,
    currencyFormatter
  );

  return (
    <Row>
      <Col
        className={'extranet-inventory__property-cell extranet-inventory__head'}
      >
        <div className="extranet-inventory__head--align-content">
          {dayPackage ? 'Public price /2 pers.' : 'BAR RO (NA/NR)'}
          {inventory && (
            <Tooltip
              title={
                <BarRowTooltip
                  detail={tooltipDetail}
                  inventory={inventory}
                  dayPackage={dayPackage}
                />
              }
              overlayClassName="extranet-inventory-tooltip"
              placement="topLeft"
            >
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </div>
      </Col>
      {eachDayOfInterval(currentRange).map(renderBar)}
    </Row>
  );
};

export default BarRow;
