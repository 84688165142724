import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, FormListFieldData, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { HeaderWrapper } from 'app/components/pages/Edito/commons/Detail/HeaderWrapper/HeaderWrapper';
import { useAppSelector } from 'app/redux/hooks';
import { selectTagList } from 'app/redux/selectors/tags';
import {
  IEditoEventForm,
  SearchCriteria,
  SearchCriteriaOption,
} from 'app/typings/edito';
import {
  searchCriteriaDefaultValue,
  searchCriteriaOptions,
} from 'app/utils/edito';

import { FormLink } from '../FormLink/FormLink';

import './SearchCriteriaForm.scss';
import { AccessibilityForm } from './SearchCriteriaValueForm/AccessibilityForm/AccessibilityForm';
import { BudgetForm } from './SearchCriteriaValueForm/BudgetForm/BudgetForm';
import { DatesForm } from './SearchCriteriaValueForm/DatesForm/DatesForm';
import { DistanceForm } from './SearchCriteriaValueForm/DistanceForm/DistanceForm';
import { LocationForm } from './SearchCriteriaValueForm/LocationForm/LocationForm';
import { PaxConfigurationForm } from './SearchCriteriaValueForm/PaxConfigurationForm/PaxConfigurationForm';
import { SortForm } from './SearchCriteriaValueForm/SortForm/SortForm';
import { TagForm } from './SearchCriteriaValueForm/TagForm/TagForm';

type Props = {
  field: FormListFieldData;
  form: FormInstance<IEditoEventForm>;
};

type SearchCriteriaComponent = {
  [criteria in SearchCriteria]: React.ReactElement;
};

export const SearchCriteriaForm = ({ field, form }: Props) => {
  const [selectedCriterias, setSelectedCriterias] = useState<
    (SearchCriteriaOption | undefined)[]
  >([undefined]);

  const nightCount = Form.useWatch(
    ['sections', field.name, 'searchFilters', 'nightCount'],
    form
  );

  const tags = useAppSelector(selectTagList);

  useEffect(() => {
    const initialSelectedCriterias = searchCriteriaOptions.filter(
      (option) =>
        !!form.getFieldValue([
          'sections',
          field.name,
          'searchFilters',
          option.value,
        ])
    );

    if (initialSelectedCriterias.length) {
      setSelectedCriterias(initialSelectedCriterias);
    }
  }, [field.name, form]);

  const searchCriteriaComponents: SearchCriteriaComponent = {
    dates: <DatesForm field={field} />,
    pax: <PaxConfigurationForm field={field} />,
    sort: <SortForm field={field} />,
    accessibility: <AccessibilityForm field={field} />,
    prices: <BudgetForm field={field} />,
    distances: <DistanceForm field={field} />,
    experience: <TagForm field={field} criteria="experience" tags={tags} />,
    room: <TagForm field={field} criteria="room" tags={tags} />,
    situation: <TagForm field={field} criteria="situation" tags={tags} />,
    style: <TagForm field={field} criteria="style" tags={tags} />,
    coords: <LocationForm field={field} form={form} />,
  };

  const availableCriterias = useMemo(
    () =>
      searchCriteriaOptions.filter((criteria) => {
        if (criteria.value === 'dates') {
          return nightCount !== 2;
        }

        return !selectedCriterias
          .map((criteria) => criteria?.value)
          .includes(criteria.value);
      }),
    [nightCount, selectedCriterias]
  );

  const onAddSearchCriteria = () => {
    setSelectedCriterias([...selectedCriterias, undefined]);
  };

  const removeLocationFilter = () => {
    form.setFieldValue(
      ['sections', field.name, 'searchFilters', 'placeId'],
      undefined
    );
    form.setFieldValue(
      ['sections', field.name, 'searchFilters', 'location'],
      undefined
    );
  };

  const onSelectCriteria = (criteria: SearchCriteriaOption, index: number) => {
    const tmpCriterias = [...selectedCriterias];

    tmpCriterias[index] = criteria;

    const oldCriteria = selectedCriterias[index]?.value;

    if (oldCriteria) {
      form.setFieldValue(
        ['sections', field.name, 'searchFilters', oldCriteria],
        undefined
      );

      if (oldCriteria === 'coords') {
        removeLocationFilter();
      }
    }

    searchCriteriaDefaultValue[criteria.value].forEach((defaultValue) => {
      form.setFieldValue(
        ['sections', field.name, 'searchFilters', ...defaultValue.name],
        defaultValue.value
      );
    });

    setSelectedCriterias(tmpCriterias);
  };

  const onDeleteCriteria = (criteria: SearchCriteria, index: number) => {
    const tmpCriterias = [...selectedCriterias];

    tmpCriterias.splice(index, 1);

    if (!tmpCriterias.length) {
      tmpCriterias.push(undefined);
    }

    form.setFieldValue(
      ['sections', field.name, 'searchFilters', criteria],
      undefined
    );

    if (criteria === 'coords') {
      removeLocationFilter();
    }

    setSelectedCriterias(tmpCriterias);
  };

  useEffect(() => {
    if (nightCount === 2) {
      const datesIndex = selectedCriterias
        .map((criteria) => criteria?.value)
        .indexOf('dates');

      if (datesIndex !== -1) {
        const tmpCriterias = [...selectedCriterias];

        tmpCriterias.splice(datesIndex, 1);

        if (!tmpCriterias.length) {
          tmpCriterias.push(undefined);
        }

        setSelectedCriterias(tmpCriterias);
      }
    }
  }, [nightCount, selectedCriterias]);

  return (
    <HeaderWrapper gapTitleChildren={16} title="Search criteria">
      {selectedCriterias.map((criteria, index) => {
        return (
          <div
            key={`${criteria?.value}-${index}`}
            className="searchCriteriasContainer"
          >
            <div className="searchCriteriasSubContainer">
              <HeaderWrapper
                key={`${criteria}-${index}`}
                isBold={false}
                title={`Criteria #${index + 1}`}
              >
                <Select
                  labelInValue
                  onSelect={(value) => onSelectCriteria(value, index)}
                  options={availableCriterias}
                  value={criteria}
                  placeholder="Select a search criteria"
                />
              </HeaderWrapper>
            </div>
            <div className="searchCriteriasSubContainer">
              {criteria ? (
                <div className="criteriaValueContainer">
                  <FormLink />
                  {searchCriteriaComponents[criteria.value]}
                  <Button
                    className="deleteCriteriaButton"
                    onClick={() => onDeleteCriteria(criteria.value, index)}
                  >
                    <DeleteOutlined />
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        );
      })}

      {selectedCriterias.length < searchCriteriaOptions.length ? (
        <div className="addCriteriaButtonContainer">
          <Button onClick={onAddSearchCriteria} icon={<PlusOutlined />}>
            Add criteria
          </Button>
        </div>
      ) : null}
    </HeaderWrapper>
  );
};
