import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  CollapsePanelProps,
  Form,
  Input,
  Row,
  Select,
} from 'antd';
import React from 'react';

import { CheckboxMandatory } from 'app/components/commons/CheckboxMandatory/CheckboxMandatory';
import PanelHeader from 'app/components/layout/PanelHeader/PanelHeader';
import type { Hotel } from 'app/redux/models/Hotel/Hotel';

import styles from './BookingContact.module.scss';
import { HOTEL_POSITION_ENUM } from './Utils';

const { Panel } = Collapse;

type Props = Partial<CollapsePanelProps> & {
  mode: 'view' | 'edit';
  channelManager?: React.ReactNode;
  notifEmails: Hotel['notifEmails'];
  billingEmails: Hotel['billingEmails'];
  setNotifEmails: (values: Hotel['notifEmails']) => void;
  trackAdd?: () => void;
  trackDelete?: () => void;
};

export const BookingContact = ({
  mode,
  channelManager,
  notifEmails,
  setNotifEmails,
  billingEmails,
  trackAdd,
  trackDelete,
  ...panelProps
}: Props) => {
  const getIfNotifEmailCheckboxIsDisabled = (
    property: 'bookingConfirmation' | 'stock' | 'billing',
    index: number
  ) => {
    if (mode !== 'edit') {
      return true;
    }

    const nbEmailNotifEmails = notifEmails.reduce(
      (acc, value) => (value[property] ? acc + 1 : acc),
      0
    );

    // If there is only one email which has the booking confirmation set to true, we disable the checkbox
    if (nbEmailNotifEmails <= 1 && notifEmails[index][property] === true) {
      return true;
    }

    return false;
  };

  return (
    <Panel
      key="notif-contact"
      className="panel"
      header={
        <PanelHeader
          name="Contact having access to extranet and managing inventory"
          subTitle="Persons having access to the extranet and receiving communications linked to bookings (1 pers min)"
        />
      }
      {...panelProps}
    >
      {channelManager}
      <Row>
        <Col span={13}></Col>
        <Col span={3} className={styles.column}>
          Booking Confirmation
        </Col>
        <Col span={2} className={styles.column}>
          Stock / Inventory
        </Col>
        <Col span={2} className={styles.column}>
          Billing
        </Col>
      </Row>
      <Form.List name="notifEmails">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <div className={styles.contactSection} key={field.name}>
                <p className={styles.headerContact}>Contact #{index + 1}</p>
                <Row style={{ alignItems: 'baseline' }}>
                  <Col span={12}>
                    <Form.Item
                      {...field}
                      key={'email'}
                      name={[index, 'email']}
                      label="Email"
                      rules={[
                        { required: true, message: 'Required' },
                        {
                          type: 'email',
                          message: 'Please enter a valid email',
                        },
                      ]}
                    >
                      <Input disabled={mode === 'view'} />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      key={'role'}
                      name={[index, 'role']}
                      label="Position"
                    >
                      <Select
                        disabled={mode === 'view'}
                        placeholder="Select position"
                        options={[
                          ...HOTEL_POSITION_ENUM.map((position) => ({
                            label: position,
                            value: position.toLowerCase(),
                          })),
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    {mode !== 'view' && fields.length > 1 && (
                      <DeleteOutlined
                        style={{ marginLeft: 12 }}
                        onClick={() => {
                          remove(field.name);

                          if (trackDelete) {
                            trackDelete();
                          }

                          const newEmails = notifEmails.filter(
                            (_, i) => i !== index
                          );

                          const noBookingConfirmationEmail = newEmails.every(
                            ({ bookingConfirmation }) => !bookingConfirmation
                          );
                          const noStockEmail = newEmails.every(
                            ({ stock }) => !stock
                          );

                          let emails = [...newEmails];

                          if (noBookingConfirmationEmail) {
                            const [firstNotifEmail, ...otherNotifEmails] =
                              emails;

                            emails = [
                              { ...firstNotifEmail, bookingConfirmation: true },
                              ...otherNotifEmails,
                            ];
                          }

                          if (noStockEmail) {
                            const [firstNotifEmail, ...otherNotifEmails] =
                              emails;

                            emails = [
                              { ...firstNotifEmail, stock: true },
                              ...otherNotifEmails,
                            ];
                          }

                          setNotifEmails(emails);
                        }}
                      />
                    )}
                  </Col>
                  <Col span={3} className={styles.column}>
                    <CheckboxMandatory
                      mode={mode}
                      index={index}
                      property="bookingConfirmation"
                      disabled={getIfNotifEmailCheckboxIsDisabled(
                        'bookingConfirmation',
                        index
                      )}
                    />
                  </Col>
                  <Col span={2} className={styles.column}>
                    <CheckboxMandatory
                      mode={mode}
                      index={index}
                      property="stock"
                      disabled={getIfNotifEmailCheckboxIsDisabled(
                        'stock',
                        index
                      )}
                    />
                  </Col>
                  <Col span={2} className={styles.column}>
                    <CheckboxMandatory
                      mode={mode}
                      index={index}
                      property="billing"
                      disabled={
                        mode !== 'edit' ||
                        (getIfNotifEmailCheckboxIsDisabled('billing', index) &&
                          billingEmails.length === 0)
                      }
                    />
                  </Col>
                </Row>
              </div>
            ))}
            {mode !== 'view' && (
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  if (trackAdd) {
                    trackAdd();
                  }

                  add({
                    email: '',
                    bookingConfirmation: true,
                    stock: true,
                    billing: false,
                  });
                }}
              >
                Add email
              </Button>
            )}
          </>
        )}
      </Form.List>
    </Panel>
  );
};
