import { Button, Descriptions, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useState } from 'react';

import { formatCurrency } from 'app/utils/strings';

type Props = {
  account: { name: string; currency: string };
  form: FormInstance;
  onSubmit: () => void;
};

const ConfirmModal = ({ account, form, onSubmit }: Props) => {
  const [isModalVisible, toggleModal] = useState(false);

  const handleSubmit = () => {
    onSubmit();
    toggleModal(false);
  };

  return (
    <div>
      <Button size="large" type="primary" onClick={() => toggleModal(true)}>
        Save Commercial Gesture
      </Button>
      <Modal
        title="Confirm gesture?"
        visible={isModalVisible}
        onOk={() => toggleModal(false)}
        onCancel={() => toggleModal(false)}
        footer={
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
            Confirm Commercial gesture
          </Button>
        }
      >
        <Descriptions column={1}>
          <Descriptions.Item label="Recipient Hotel">
            {account.name}
          </Descriptions.Item>
          <Descriptions.Item label="Amount">
            {form.getFieldValue('amount') &&
              formatCurrency(form.getFieldValue('amount'), account.currency)}
          </Descriptions.Item>
          <Descriptions.Item label="Comment">
            {form.getFieldValue('comment')}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
