import { Form, Layout } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';

import { SDetailLayout } from 'app/components/StaycationUI';
import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import Service from 'app/components/commons/Hotel/Services/Service/Service';
import 'app/components/commons/Hotel/Services/Services.scss';
import {
  addService,
  formatServicesForAPI,
  isServicesValid,
  removeService,
  updateService,
} from 'app/components/commons/Hotel/Services/utils';
import { HotelServiceForm, Mode } from 'app/components/commons/Hotel/Types';
import { useSaveHotelServices } from 'app/hooks/data/hotels/useHotelServices';
import { useHotelServicesTypes } from 'app/hooks/data/useHotelServicesTypes';
import { Hotel } from 'app/typings/hotels/Hotel/Hotel';

const { Content } = Layout;

export const Services: FC<Props> = ({ headerMenu, hotel, reloadHotel }) => {
  const { data: hotelsServicesTypes } = useHotelServicesTypes();
  const hotelServices = hotel?.services.services;
  const hotelName = hotel?.name;
  const [form] = Form.useForm();
  const [services, setServices] = useState<HotelServiceForm[]>(
    hotelServices || []
  );

  const { mutateAsync: saveServices } = useSaveHotelServices(`${hotel?.id}`);

  const add = (service: HotelServiceForm) => addService(service, setServices);
  const update = (service: HotelServiceForm) =>
    updateService(service, setServices);
  const remove = (service: HotelServiceForm) =>
    removeService(service, setServices);

  useEffect(() => {
    if (hotelServices) {
      setServices(hotelServices);
    }
  }, [hotelServices]);

  const [mode, setMode] = useState<Mode>('view');

  const formServices = form.getFieldsValue()?.services;

  const onFinish = useCallback(async () => {
    const formattedServices = formatServicesForAPI(
      services,
      hotelsServicesTypes || [],
      formServices
    );

    await saveServices(formattedServices as HotelServiceForm[]);
    await reloadHotel();
    setMode('view');
  }, [services, hotelsServicesTypes, formServices, saveServices, reloadHotel]);

  const onCancel = useCallback(() => {
    setMode('view');
  }, []);

  const saveDisabled =
    mode !== 'view' &&
    !isServicesValid(hotelsServicesTypes || [], services, formServices);

  return (
    <div className="services-container">
      <SDetailLayout
        title={hotelName || 'Services'}
        backRoute="/hotels"
        mode={mode}
        onEdit={() => setMode('edit')}
        onSave={onFinish}
        onCancel={onCancel}
        reset={() => setServices(hotelServices || [])}
        headerMenu={headerMenu}
        isDirty={!saveDisabled}
        isValid={form.getFieldsError().every((item) => item.errors.length > 0)}
      >
        <Content className="hotel-detail__content">
          <CurrencyWrapper hotel={hotel}>
            {hotelsServicesTypes?.map((hotelsServicesType) => (
              <Service
                key={hotelsServicesType.id}
                services={services}
                hotelsServicesType={hotelsServicesType}
                mode={mode}
                add={add}
                update={update}
                remove={remove}
                form={form}
              />
            ))}
          </CurrencyWrapper>
        </Content>
      </SDetailLayout>
    </div>
  );
};

type Props = {
  headerMenu: any;
  hotel?: Hotel;
  reloadHotel: () => void;
};

export default Services;
