import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import {
  Collection,
  CollectionDisplay,
  CollectionDisplayForm,
  CollectionForm,
  CollectionPublished,
} from 'app/typings/collection';
import { apiMutation } from 'app/utils/request/api';

import { pathCollection, pathCollections } from './useCollections';

const createPath = 'collections' as const;
const updatePath = 'collections/:id' as const;
const patchDisplayPath = 'collections/:id/display' as const;
const patchPublishPath = 'collections/:id/publish' as const;

export function useCreateCollection() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: CollectionForm) =>
      apiMutation(
        'POST',
        {
          path: createPath,
        },
        payload
      ) as Promise<Collection>,
    onSuccess: () => {
      queryClient.invalidateQueries([pathCollections]);
      message.success(`Create collection successfully`);
    },
    onError: (err) => {
      message.error('Error creating collection');
    },
  });
}

export function useUpdateCollection() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: Collection) =>
      apiMutation(
        'PUT',
        {
          path: updatePath,
          params: { id: payload.id.toString() },
        },
        payload
      ) as Promise<Collection>,
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries([pathCollections]);
      queryClient.invalidateQueries([pathCollection, { id: payload.id }]);
      message.success(`Update collection #${payload.id} successfully`);
    },
    onError: (err, payload) => {
      message.error(`Error updating collection #${payload.id}`);
    },
  });
}

export function usePatchDisplayCollection() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: CollectionDisplayForm) =>
      apiMutation(
        'PATCH',
        {
          path: patchDisplayPath,
          params: { id: payload.id.toString() },
        },
        payload
      ) as Promise<CollectionDisplay>,
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries([pathCollections]);
      message.success(`Featured collection #${payload.id}`);
    },
    onError: (err, payload) => {
      message.error(
        `Failed to update display settings for collection #${payload.id}`
      );
    },
  });
}

export function usePatchPublishedCollecton() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: CollectionPublished) =>
      apiMutation(
        'PATCH',
        {
          path: patchPublishPath,
          params: { id: payload.id.toString() },
        },
        payload
      ) as Promise<{ published: boolean }>,
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries([pathCollections]);
      message.success(`Published collection #${payload.id}`);
    },
    onError: (err, payload) => {
      message.error(`Failed to publish collection #${payload.id}`);
    },
  });
}
